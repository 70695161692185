import React, { Component } from "react";
import { Select, Card, DatePicker, Button, Switch, message } from "antd";
import { HorizontalBar } from "react-chartjs-2";

import "chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes";
import { Tableau20 } from "chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau";

import { withRouter } from "react-router";
import moment from "moment";

import BluveLayout from "../../../components/layout/BluveLayout";

import { getEmpresas, getData, getMotivos, exportToExcel } from "./ProdutoMotivoF.jsx";
import { getGestorId } from "../../../components/auth/auth-provider";
import bluveStorage from "../../../components/localstorage/localstorage";
import locale from 'antd/es/date-picker/locale/pt_BR';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

class ProdutoMotivo extends Component {
  state = {
    empresaId: "",
    conversoes: [],
    produtosRelatorio: [],
    chartData: {},
    loading: false,
    periodo: {},
    exibirSku: false,
  };

  options = [];

  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onChangeExibirSku = this.onChangeExibirSku.bind(this);
  }

  async componentDidMount() {
    const exibirSku = bluveStorage.get('exibirSku') !== 'false';
    this.setState({ exibirSku, loading: true });
    const gestorId = await getGestorId();

    const storage = bluveStorage.getEmpresasGestor('@gestorEmpresas');
    let empresas = [];

    if (storage && storage.gestorId === gestorId) {
      empresas = storage.empresas;
    } else {
      empresas = await getEmpresas();

      const data = {
        gestorId: gestorId,
        empresas: empresas
      };

      bluveStorage.setEmpresasGestor(data);
    }

    for (const item of empresas) {
      this.options.push(
        <Option key={item.key} value={item.key}>
          {item.login.login}
        </Option>
      );
    }

    if (!empresas.length) {
      empresas.push({
        id: "Nenhuma empresa cadastrada",
        razaoSocial: "Nenhuma empresa cadastrada",
      });
    }

    let empresaId = bluveStorage.getReportEmpresaId();
    if (!empresaId) {
      empresaId = empresas[0].key
    }

    let periodo = bluveStorage.getReportPeriodo();
    if (!periodo) {
      periodo = {
        inicial: moment().subtract(7, "days"),
        final: moment(),
      };
    }
    else {
      periodo.inicial = moment(periodo.inicial);
      periodo.final = moment(periodo.final);
    }

    let conversoes = [];
    let produtosRelatorio = [];
    let chartData = {};

    const report = bluveStorage.getReportProdutosMotivos();
    if (report.conversoes) {
      conversoes = report.conversoes;
    }

    if (report.produtosRelatorio) {
      produtosRelatorio = report.produtosRelatorio;
    }

    if (report.chartData) {
      chartData = report.chartData;
    }
    this.setState({
      empresaId,
      empresas: empresas,
      periodo,
      conversoes,
      produtosRelatorio,
      chartData,
      loading: false,
    });

  }

  onChangeExibirSku(checked) {
    this.setState({ exibirSku: checked });
    bluveStorage.set('exibirSku', checked);
  }

  onExportClick() {
    const empresaFiltrada = this.state.empresas.filter(item => item.key === this.state.empresaId)[0];
    exportToExcel(
      this.state.produtosRelatorio,
      empresaFiltrada.razaoSocial,
      this.state.periodo
    );
  }

  async showInfo() {
    if (!this.state.empresaId) {
      message.warning('Selecione uma empresa.');
      return;
    }

    this.setState({ loading: true });
    const todosMotivos = await getMotivos();
    let conversoes = await getData(
      this.state.empresaId,
      this.state.periodo.inicial,
      this.state.periodo.final
    );
    let produtosRelatorio = [];

    conversoes.forEach((conversao) => {
      let produtos = conversao.produtos;
      let motivos = conversao.motivos;

      if (produtos && !conversao.troca) {
        produtos.forEach((produto) => {
          let item = produtosRelatorio.find((p) => p.id === produto.id);

          if (item) {
            item.atendimentos++;

            motivos !== undefined && motivos.forEach((motivo) => {
              let motivoExistente = item.motivos.find(
                (m) => m.id === motivo.id
              );

              if (motivoExistente) {
                motivoExistente.quantidade++;
              } else {
                item.motivos.push({ ...motivo, quantidade: 1 });
              }
            });
          } else if (produto.id !== "--") {
            // Produtos adicionados manualmente durante
            // uma "não-venda" são registrados com o id "--"

            let novoProduto = {
              id: produto.id,
              nome:  this.state.exibirSku && produto.codigo ? produto.codigo : produto.nome,
              atendimentos: 1,
              motivos: JSON.parse(JSON.stringify(todosMotivos)),
            };

            motivos.forEach((m) => {
              let motivoUpdate = novoProduto.motivos.find(
                (item) => item.id === m.id
              );

              if (motivoUpdate) {
                motivoUpdate.quantidade++;
              }
            });

            produtosRelatorio.push(novoProduto);
          }
        });
      }
    });

    produtosRelatorio = produtosRelatorio
      .sort((a, b) => b.atendimentos - a.atendimentos)
      .slice(0, 10);

    let datasets = [];
    for (let i = 0; i < todosMotivos.length; i++) {
      let data = {
        label: todosMotivos[i].nome,
        data: produtosRelatorio.map((p) => p.motivos[i].quantidade),
        borderWidth: 2,
        // borderColor: "white",
        borderSkipped: "left",
      };

      datasets.push(data);
    }

    let produtosLabel = produtosRelatorio.map((item) => item.nome);

    let chart = {
      labels: produtosLabel,
      datasets: datasets,
    };

    if (conversoes.length === 0 && produtosRelatorio.length === 0) {
      message.info("Sem movimentos nesse período");
    }

    bluveStorage.setReportEmpresaId(this.state.empresaId);
    bluveStorage.setReportPeriodo(this.state.periodo);
    bluveStorage.setReportProdutosMotivos(conversoes, produtosRelatorio, chart);
    this.setState({
      loading: false,
      conversoes: conversoes,
      produtosRelatorio: produtosRelatorio,
      chartData: chart,
    });
  }

  onDateChange(value) {
    this.setState({
      periodo: {
        inicial: value[0],
        final: value[1],
      },
    });
  }

  onSelectChange(value) {
    this.setState({ empresaId: value });
  }

  onFilterClick() {
    this.showInfo();
  }

  render() {
    return (
      <div>
        <BluveLayout selectItem={"relatorio/produtos-motivos"}>
          <div className="containerExtratoAtendimentos">
            <div className="child">
              <label>Empresa:</label>
              <br></br>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "200px" }}
                placeholder="Selecione uma empresa"
                onChange={this.onSelectChange}
                value={this.state.empresaId}
              >
                {this.options}
              </Select>
            </div>

            <div className="child">
              <label>Período:</label>
              <br></br>
              <div className="rangePickerDuplo">
                <RangePicker
                  locale={locale}
                  format={dateFormat}
                  placeholder={["Data Inicial", "Data Final"]}
                  onChange={this.onDateChange}
                  value={[
                    this.state.periodo.inicial,
                    this.state.periodo.final,
                  ]}
                />
              </div>
            </div>
            <div className="child">
              <Button
                type="primary"
                loading={this.state.loading}
                onClick={this.onFilterClick}
              >
                Filtrar
              </Button>
              <br></br>
              <br></br>
              <Button
                className='excel-button-v2'
                disabled={this.state.loading || this.state.produtosRelatorio.length === 0}
                onClick={this.onExportClick}
                style={{ marginLeft: '1rem' }}
              >
                Exportar para Excel
              </Button>

              <br></br>
              <Switch
                checkedChildren="Sku do produto"
                unCheckedChildren="Nome do produto"
                checked={this.state.exibirSku}
                onChange={this.onChangeExibirSku}
              />


            </div>
          </div>
          <div>
            <Card>
              <HorizontalBar
                data={this.state.chartData}
                width={100}
                height={30}
                options={{
                  maintainAspectRatio: true,
                  showLines: false,
                  scales: {
                    xAxes: [
                      {
                        // display: false,
                        stacked: true,
                        gridLines: {
                          // display: false
                        },
                        ticks: {
                          min: 0,
                          stepSize: 10,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        stacked: true,
                        gridLines: {
                          display: false,
                        },
                        ticks: {
                          // Limitar a quantidade de caracteres exibidos
                          // nos labels do gráfico (ao lado esquerdo)
                          callback: function (value, index, values) {
                            const LIMITE = 40;

                            if (value && value.length >= LIMITE) {
                              return value.substring(0, LIMITE) + "...";
                            }

                            return value;
                          },
                        },
                      },
                    ],
                  },
                  plugins: {
                    colorschemes: {
                      scheme: Tableau20,
                      fillAlpha: 0.2,
                    },
                  },
                  title: {
                    display: true,
                    text: "10 produtos com mais perdas",
                  },
                }}
              />
            </Card>
          </div>
        </BluveLayout>
      </div>
    );
  }
}

export default withRouter(ProdutoMotivo);
