import DataManager from "./dtm";
import { fbDatabase, fbFirestore } from "../firebase/firebase";


class EmpresaDB extends DataManager {

    state = {
        collection: 'empresas',
        orderBy: ['razaoSocial', 'nomeFantasia'], // usado na classe pai
    }

    async getByGestorIdCustom(gestorId, orderBy) {
        const snapshot = await fbDatabase.collection(this.state.collection)
            .where("gestorId", "==", gestorId)
            .where("ativo", "==", true)
            .orderBy(orderBy)
            .get();

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            razaoSocial: item.data().razaoSocial,
            login: item.data().login,
        }));
        return data;
    }

    async getByCNPJ(cnpj) {
        const data = await this.getByParamValue('cnpj', cnpj);
        return data;
    }

    async getByLogin(login) {
        const data = await this.getByParamValue('login.login', login);
        return data;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        return data;
    }

    async monitorarEmpresas(gestorId, callback) {
        fbDatabase
            .collection(this.state.collection)
            .where("gestorId", "==", gestorId)
            .where("ativo", "==", true)
            .get()
            .then((querySnapshot) => {
                if (callback) {
                    callback(querySnapshot);
                }
            });
    }
}

const empresaDB = new EmpresaDB();
export default empresaDB;