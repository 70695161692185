import DataManager from './dtm';
import { fbDatabase, fbFirestore } from "../firebase/firebase";


class UsuarioDB extends DataManager {
    state = {
        collection: 'usuarios',
        orderBy: ['']
    }

    async getByUserId(uid) {
        const snapshot = await fbDatabase.collection(this.state.collection)
        .where('uid', '==', uid)
        .get();

        if (snapshot.empty) {
            return undefined;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
        }));

        return data[0];
    }
}

const usuarioDB = new UsuarioDB();
export default usuarioDB;