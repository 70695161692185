import { Component } from 'react';
import { MITTE_URL } from '../../firebase/firebase';


class Mitte extends Component {

    async sendPaidMail(email, indicador, valor, conversoes, loja) {
        return new Promise((resolve, reject) => {
            const url = MITTE_URL;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, indicador, valor, conversoes, loja })
            };

            fetch(url, requestOptions)
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.code === 200) {
                        resolve(responseData);
                    }
                    else {
                        reject(responseData);
                    }
                });
        });
    }
}

const mitte = new Mitte();
export default mitte;
