export const REDES_SOCIAIS = `Siga-nos em nossas redes sociais`;
export const TELEFONE_CONTATO = '+55 21 4040-2454';
export const VERSAO = '1.0.14 | build 1.2021.08.24';
export const GERENTE = 'Gerente';
export const GESTOR = 'Gestor';

export const H_01_HORA = 3600000;
export const H_24_HORAS = H_01_HORA * 24;
export const H_48_HORAS = H_01_HORA * 48;

export const PASSWORD_UPDATED = '@user/passwordUpdated';
export const DEFAULT_ATATAR = 'https://storage.googleapis.com/bluve-bucket/base_img_avatar.png';

export const JWT_KEY_API_BLUVE = 'DspLKH3rIdi95BNntNg8rdV5uVFwIp7By8ob';
export const EXPIRATION_TIME = `${1000 * 60 * 60 * 4}ms`;
