
import React from 'react';
import "antd/dist/antd.css";
import './Meta.css';
import {
  Form,
  Input as InputAnt,
  Tooltip,
  Icon,
  Button,
  Divider,
  notification
} from 'antd';
import { getGestorId } from '../../auth/auth-provider';
import motivoDB from '../../../dataManager/dtmMotivo';
import Input from '../../../components/intl/IntlCurrencyInput';


const openNotificationWithIcon = (type, title, description, duration) => {
  if (!duration) {
    duration = 4; // this is the default time
  }
  notification[type]({
    message: [title],
    description: [description],
    duration,
  });
};

class MetaCRUD extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    data: {
      key: this.props.record.key,
      empresa: {
        razaoSocial: ''
      },
      ativo: true,
      ativoStr: 'Sim',
    }
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }

      const gestorId = getGestorId();

      // procuro pelo Nome do motivo para checar se está repetido
      const snapshot = await motivoDB.getByNome(values.nome);
      // Verifico se está havendo uma duplicação de nome
      // Verifico se está havendo uma duplicação de nome
      if (snapshot !== undefined && snapshot.length > 0) {
        if ((this.props.editMode && snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) || (!this.props.editMode)) {
          openNotificationWithIcon('error', 'Duplicação', 'O motivo informado já foi cadastrado.');
          return;
        }
      }

      const item = {
        nome: values.nome,
        ativo: values.ativo.toLocaleLowerCase() === 'sim' ? true : false,
        gestorId,
      }

      let novoId = '';
      if (!this.props.editMode) {
        const res = await motivoDB.add(item);
        if (res.recorded === true) {
          novoId = res.id;
        }
      }
      else {
        motivoDB.update(this.props.record.key, item);
      }

      const tipoAtualizacao = this.props.editMode ? 'atualizado' : 'cadastrado';
      openNotificationWithIcon('success', 'Perfeito!', `O motivo ${values.nome} foi ${tipoAtualizacao} com sucesso`, 3);
      item['key'] = novoId ? novoId : this.props.record.key;
      this.props.handleOk(item);
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  componentDidMount() {
    if (this.props.editMode && this.props.record) {
      this.setState({
        data: {
          key: this.props.record.key,
          empresa: this.props.record.empresa,
          faturamento: this.props.record.faturamento,
          premioFaturamento: 50 * 100,
          bonusSobreMeta: 10,
          premioACadaBonusMeta: 30 * 100,
          metaTicketMedio: 150 * 100,
          premioMetaTicketMedio: 20 * 100,
          numeroAtendimentos: 50,
          premioNumeroAtendimentos: 27 * 100,
          comissaoVendedor: 4
        }
      });
    }
  }

  cancelClick() {
    this.props.handleCancel();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const currencyConfig = {
      locale: "pt-BR",
      formats: {
        number: {
          "pt-BR": {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        },
      },
    };

    return (
      <div className="divMeta">

        <div className="btnCopiarMesAnterior" >
          <Button
            type='primary'>
            Copiar mês anterior
          </Button>
        </div>

        <Form {...formItemLayout} onSubmit={this.handleSubmit}>

          <Form.Item
            ref='txtEmpresa'
            label={
              <span>
                Empresa&nbsp;
              </span>
            }
          >
            {getFieldDecorator('empresa', {
              initialValue: this.state.data.empresa.razaoSocial,
              rules: [{ whitespace: true }],
            })(<InputAnt disabled={true} className="disabledElement" />)}
          </Form.Item>

          <Form.Item
            label={
              <span>
                Faturamento/prêmio&nbsp;
                <Tooltip title="Qual a meta de faturamento e prêmio por faturamento do mês?">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('faturamento', {
              initialValue: this.state.data.faturamento,
              rules: [{ required: true, message: 'Informe os valores' }],
            })(
              <div className="divSideBySideMeta">
                <Input
                  className="wrapValorMeta"
                  currency={currencyConfig.locale}
                  config={currencyConfig}
                  value={this.state.data.faturamento}
                // onChange={comissaoFixaChange}
                />

                <Input
                  className="wrapValorMeta"
                  currency={currencyConfig.locale}
                  config={currencyConfig}
                  value={this.state.data.premioFaturamento}
                // onChange={comissaoFixaChange}
                />
              </div>

            )}

          </Form.Item>


          <Form.Item

            label={
              <span>
                bônus meta/prêmio&nbsp;
                <Tooltip title="Qual o percentual da meta sobre bônus e o valor do prêmio a ser pago?">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
            ref="txtBonusMeta">
            {getFieldDecorator('bonusSobreMeta', {
              initialValue: this.state.data.bonusSobreMeta,
              rules: [
                {
                  required: true,
                  message: 'Por favor, informe o percentual de bônus sobre meta',
                },
              ],
            })(
              <div className="divSideBySideMeta">
                <InputAnt
                  className="wrapPercentualMeta"
                  type="number"
                  value={this.state.data.bonusSobreMeta}
                // onChange={comissaoPercChange}
                />
                <label>%</label>

                <Input
                  className="wrapValorMeta"
                  currency={currencyConfig.locale}
                  config={currencyConfig}
                  value={this.state.data.premioACadaBonusMeta}
                // onChange={comissaoFixaChange}
                />
              </div>

            )}
          </Form.Item>


          <Form.Item
            label={
              <span>
                Ticket Médio/prêmio&nbsp;
              <Tooltip title="Qual a meta de ticket médio e prêmio?">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('metaTicketMedio', {
              initialValue: this.state.data.metaTicketMedio,
              rules: [{ required: true, message: 'Informe os valores' }],
            })(
              <div className="divSideBySideMeta">
                <Input
                  className="wrapValorMeta"
                  currency={currencyConfig.locale}
                  config={currencyConfig}
                  value={this.state.data.metaTicketMedio}
                // onChange={comissaoFixaChange}
                />

                <Input
                  className="wrapValorMeta"
                  currency={currencyConfig.locale}
                  config={currencyConfig}
                  value={this.state.data.premioMetaTicketMedio}
                // onChange={comissaoFixaChange}
                />
              </div>
            )}

          </Form.Item>

          <Form.Item
            label={
              <span>
                Nr atend/prêmio&nbsp;
              <Tooltip title="Qual a meta de número de atendimentos e prêmio?">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('metaNumeroAtendimentos', {
              initialValue: this.state.data.numeroAtendimentos,
              rules: [{ required: true, message: 'Informe os valores' }],
            })(
              <div className="divSideBySideMeta">
                <InputAnt
                  className="wrapPercentualMeta"
                  type="number"
                  value={this.state.data.numeroAtendimentos}
                // onChange={comissaoPercChange}
                />

                <Input
                  className="wrapValorMeta"
                  currency={currencyConfig.locale}
                  config={currencyConfig}
                  value={this.state.data.premioNumeroAtendimentos}
                // onChange={comissaoFixaChange}
                />
              </div>
            )}

          </Form.Item>


          <Form.Item
            ref='txtComissaoVendedor'
            label={
              <span>
                Comissão Vendedor&nbsp;
              </span>
            }
          >
            {getFieldDecorator('comissaoVendedor', {
              initialValue: this.state.data.comissaoVendedor,
              rules: [{ whitespace: true }],
            })(
              <div className="wrapPercentualComissaoVendedorMeta">
                <InputAnt value={this.state.data.comissaoVendedor} />
                <label>%</label>
              </div>
            )}
          </Form.Item>



          <Form.Item
            label={
              <span>
                Peso - Semana 1&nbsp;
              <Tooltip title="Informe o percentual do peso da semana">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('pesoSemana1', {
              initialValue: this.state.data.pesoSemana1,
              rules: [{ required: true, message: 'Informe os valores' }],
            })(
              <div className="divSideBySideMeta">
                <InputAnt
                  className="wrapPercentualMeta"
                  type="number"
                  value={this.state.data.pesoSemana1}
                // onChange={comissaoPercChange}
                />
                <label>%</label>
                <Input
                  className="wrapValorMeta"
                  currency={currencyConfig.locale}
                  config={currencyConfig}
                  value={this.state.data.valorPesoSemana1}
                // onChange={comissaoFixaChange}
                />
              </div>
            )}

          </Form.Item>

          <Form.Item
            label={
              <span>
                Peso - Semana 2&nbsp;
              <Tooltip title="Informe o percentual do peso da semana">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('pesoSemana2', {
              initialValue: this.state.data.pesoSemana2,
              rules: [{ required: true, message: 'Informe os valores' }],
            })(
              <div className="divSideBySideMeta">
                <InputAnt
                  className="wrapPercentualMeta"
                  type="number"
                  value={this.state.data.pesoSemana2}
                // onChange={comissaoPercChange}
                />
                <label>%</label>
                <Input
                  className="wrapValorMeta"
                  currency={currencyConfig.locale}
                  config={currencyConfig}
                  value={this.state.data.valorPesoSemana2}
                // onChange={comissaoFixaChange}
                />
              </div>
            )}

          </Form.Item>

          <Form.Item
            label={
              <span>
                Peso - Semana 3&nbsp;
              <Tooltip title="Informe o percentual do peso da semana">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('pesoSemana3', {
              initialValue: this.state.data.pesoSemana3,
              rules: [{ required: true, message: 'Informe os valores' }],
            })(
              <div className="divSideBySideMeta">
                <InputAnt
                  className="wrapPercentualMeta"
                  type="number"
                  value={this.state.data.pesoSemana3}
                // onChange={comissaoPercChange}
                />
                <label>%</label>
                <Input
                  className="wrapValorMeta"
                  currency={currencyConfig.locale}
                  config={currencyConfig}
                  value={this.state.data.valorPesoSemana3}
                // onChange={comissaoFixaChange}
                />
              </div>
            )}

          </Form.Item>

          <Form.Item
            label={
              <span>
                Peso - Semana 4&nbsp;
              <Tooltip title="Informe o percentual do peso da semana">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('pesoSemana4', {
              initialValue: this.state.data.pesoSemana4,
              rules: [{ required: true, message: 'Informe os valores' }],
            })(
              <div className="divSideBySideMeta">
                <InputAnt
                  className="wrapPercentualMeta"
                  type="number"
                  value={this.state.data.pesoSemana4}
                // onChange={comissaoPercChange}
                />
                <label>%</label>
                <Input
                  className="wrapValorMeta"
                  currency={currencyConfig.locale}
                  config={currencyConfig}
                  value={this.state.data.valorPesoSemana4}
                // onChange={comissaoFixaChange}
                />
              </div>
            )}

          </Form.Item>





          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">Salvar</Button>
            <Divider type="vertical" />
            <Button onClick={this.cancelClick}>Cancelar</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(MetaCRUD);