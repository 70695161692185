import React from "react";
import {
  Form,
  Input,
  Tooltip,
  Icon,
  Button,
  Divider,
  Select,
  Tabs,
} from "antd";
import "antd/dist/antd.css";
import {
  fireBase,
  fbDatabase,
  fbFirestore,
} from "../../../../firebase/firebase";
import { getGestorId } from "../../../auth/auth-provider";
import { isCPF, formatCPF, clearCpfFormat } from "../../../funcs/utils";
import mensagem from "components/message/Message";
import { DEFAULT_ATATAR } from "components/funcs/constants";

const { Option } = Select;
const { TabPane } = Tabs;

function makeid() {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

async function uploadAvatar(imagePath) {
  const fileName = makeid();
  imagePath = imagePath.replace("data:image/jpeg;base64,", "");
  imagePath = imagePath.replace("data:image/png;base64,", "");

  try {
    const res = await fireBase
      .storage()
      .ref("/indicadores/avatars/")
      .child(fileName)
      .putString(imagePath, "base64", { contentType: "image/jpeeg" });

    const url = await res.task.snapshot.ref.getDownloadURL();
    return {
      success: true,
      url,
    };
  } catch (error) {
    console.clear();
    console.log(error);
    return {
      success: false,
      url: "",
    };
  }
}

class IndicadorCRUD extends React.Component {
  state = {
    activeKey: "geral",
    confirmDirty: false,
    autoCompleteResult: [],
    lojas: [],
    selecteds: [],
    loading: false,
    loadingButton: false,
    edited: false,
    fileList: [],
    photoURL: DEFAULT_ATATAR,
    data: {
      key: this.props.record.key,
      ativo: "Sim",
      nome: "",
      cpf: "",
      email: "",
      endereco: {
        logradouro: "",
        bairro: "",
        cidade: "",
        uf: "",
        telefone: "",
      },
    },
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
    this.selectHandleChange = this.selectHandleChange.bind(this);
    this.getBase64 = this.getBase64.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.btnUploadClick = this.btnUploadClick.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.changeLoadingStatus = this.changeLoadingStatus.bind(this);
  }

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  btnUploadClick() {
    this.refs.myFile.click();
  }

  tabChange(activeKey) {
    this.setState({ activeKey });
  }

  changeLoadingStatus(loading) {
    this.setState({ loadingButton: loading });
    if (this.props.onCloseHandle) {
      this.props.onCloseHandle(!loading);
    }
  }

  handleChange(e) {
    this.getBase64(e.target.files[0], (photoURL) => {
      this.setState({
        photoURL,
        loading: false,
        edited: true,
      });
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const _this = this;
        mensagem.printFormErrors(err, 3, function (requireds) {
          _this.setState({ activeKey: "geral" });
        });
        return;
      }
      this.changeLoadingStatus(true);
      // Por enquanto não vamos obrigar!
      // if (!this.state.photoURL) {
      //   mensagem.openNotificationWithIcon(
      //     "error",
      //     "Cadastro incompleto",
      //     "Faça o upload da foto do indicador"
      //   );
      //   return;
      // }

      if (this.state.selecteds.length === 0) {
        mensagem.openNotificationWithIcon(
          "error",
          "Cadastro incompleto",
          "Selecione a loja"
        );
        this.changeLoadingStatus(false);
        return;
      }

      this.setState({ loadingButton: true });

      const gravarLojas = [];

      if (!Array.isArray(this.state.selecteds)) {
        this.state.selecteds = [this.state.selecteds];
      }

      this.state.selecteds.forEach((obj) => {
        const loja = this.state.lojas.filter((item) => {
          return item.key === obj;
        })[0];

        gravarLojas.push({
          id: loja.key,
          nome: loja.props.children,
        });
      });

      if (!isCPF(values.cpf)) {
        mensagem.openNotificationWithIcon(
          "error",
          "CPF inválido",
          "O CPF informado não está correto."
        );
        this.changeLoadingStatus(false);
        return;
      }
      values.cpf = formatCPF(values.cpf);

      // procuro pelo CPF para checar se está repetido
      let snapshot = await fbDatabase
        .collection("indicadores")
        .where("cpf", "==", values.cpf)
        .get();

      /** X-TUDO */
      let xRef = fbDatabase
        .collection("x-tudo")
        .doc("Bluve-IndicadorCRUDJSX-IndicadorCRUD-handleSubmit-1");
      xRef
        .set(
          {
            collection: "indicadores",
            lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
            count: fbFirestore.FieldValue.increment(snapshot.size),
            lastIncrement: snapshot.size,
          },
          { merge: true }
        )
        .catch((error) => console.error(error));

      // Verifico se está havendo uma duplicação de CPF
      if (!snapshot.empty) {
        if (
          (this.props.editMode &&
            snapshot.docs[0].id !== this.props.record.key) ||
          (this.props.editMode && snapshot.docs.length > 1) ||
          !this.props.editMode
        ) {
          this.setState({ loadingButton: false });
          mensagem.openNotificationWithIcon(
            "error",
            "Duplicação",
            "O CPF informado já foi cadastrado."
          );
          this.changeLoadingStatus(false);
          return;
        }
      }

      // procuro pelo email para checar se está repetido
      snapshot = await fbDatabase
        .collection("indicadores")
        .where("email", "==", values.email)
        .get();

      /** X-TUDO */
      xRef = fbDatabase
        .collection("x-tudo")
        .doc("Bluve-IndicadorCRUDJSX-IndicadorCRUD-handleSubmit-2");
      xRef
        .set(
          {
            collection: "indicadores",
            lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
            count: fbFirestore.FieldValue.increment(snapshot.size),
            lastIncrement: snapshot.size,
          },
          { merge: true }
        )
        .catch((error) => console.error(error));

      if (!snapshot.empty) {
        if (
          (this.props.editMode &&
            snapshot.docs[0].id !== this.props.record.key) ||
          (this.props.editMode && snapshot.docs.length > 1) ||
          !this.props.editMode
        ) {
          this.setState({ loadingButton: false });
          mensagem.openNotificationWithIcon(
            "error",
            "Duplicação",
            "O email informado já foi cadastrado."
          );
          this.changeLoadingStatus(false);
          return;
        }
      }

      let _upload = {};
      if (this.state.edited && this.state.photoURL) {
        _upload = await uploadAvatar(this.state.photoURL);
      }

      if (this.state.edited && !_upload.success && this.state.photoURL) {
        mensagem.openNotificationWithIcon(
          "error",
          "Erro",
          "Erro ao tentar salvar a foto do indicador. Tente novamente"
        );
        this.changeLoadingStatus(false);
        return;
      }

      const item = {
        ativo: values.ativo.toLocaleLowerCase() === "sim" ? true : false,
        nome: values.nome,
        cpf: values.cpf,
        email: values.email,
        loja: {
          ...gravarLojas[0],
        },
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone,
        },
        gestorId: getGestorId(),
      };
      const avatar = _upload.url ? _upload.url : this.state.photoURL;
      if (avatar) {
        item["avatar"] = avatar;
      }

      let novoId = "";
      if (!this.props.editMode) {
        await fbDatabase
          .collection("indicadores")
          .add({
            ...item,
            criado_em: new Date(),
          })
          .then((res) => {
            novoId = res.id;
            // Crio o novo usuário gerente
            const cpf = clearCpfFormat(values.cpf);

            fireBase
              .auth()
              .createUserWithEmailAndPassword(values.email, cpf.substr(0, 6))
              .catch(function (error) {
                console.log(error);
              });
          });
      } else {
        fbDatabase
          .collection("indicadores")
          .doc(this.props.record.key)
          .update({
            ...item,
          });
      }

      const tipoAtualizacao = this.props.editMode ? "atualizado" : "cadastrado";
      this.setState({ loadingButton: true });
      mensagem.openNotificationWithIcon(
        "success",
        "Perfeito!",
        `O indicador ${values.nome} foi ${tipoAtualizacao} com sucesso`,
        3
      );
      item["key"] = novoId ? novoId : this.props.record.key;
      this.props.handleOk(item);
    });
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  componentDidMount() {
    const { Option } = Select;
    const lojas = [];
    this.props.record.lojas.forEach((item) => {
      lojas.push(<Option key={item.key}>{item.nomeFantasia}</Option>);
    });

    this.setState({ lojas });

    const selecteds = [];
    if (this.props.editMode && this.props.record) {
      selecteds.push(this.props.record.loja.id);
      this.setState({
        photoURL: this.props.record.avatar
          ? this.props.record.avatar
          : DEFAULT_ATATAR,
        selecteds,
        data: {
          key: this.props.record.key,
          ativo: this.props.record.ativoStr,
          nome: this.props.record.nome,
          cpf: this.props.record.cpf,
          email: this.props.record.email,
          endereco: this.props.record.endereco,
        },
      });
    } else {
      // Só seleciono automaticamente se o length for igual a 1
      // para evitar erros de cadastramento
      if (this.props.record.lojas.length === 1) {
        selecteds.push(this.props.record.lojas[0].id);
        this.setState({ selecteds });
      }
    }
  }

  cancelClick() {
    this.props.handleCancel();
  }

  selectHandleChange(value) {
    this.setState({ selecteds: value });
  }

  render() {
    const { photoURL } = this.state;

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text"></div>
      </div>
    );

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <div>
        <div className="clearfix avatarVendedorImgBckground">
          <input
            className="uploadComponent"
            type="file"
            name="myFile"
            ref="myFile"
            onChange={this.handleChange}
          />
          {photoURL ? (
            <div>
              <img
                src={photoURL}
                alt="avatar"
                className="avatarVendedorImg"
                onClick={this.btnUploadClick}
              />
              <Icon
                type="camera"
                className="avatarVendedorIcon"
                onClick={this.btnUploadClick}
              />
            </div>
          ) : (
            uploadButton
          )}
        </div>

        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Tabs activeKey={this.state.activeKey} onChange={this.tabChange}>
            <TabPane tab="Geral" key="geral">
              <Form.Item
                ref="selectLoja"
                label={
                  <span>
                    Loja&nbsp;
                    <Tooltip title="Escolha a loja na qual o indicador trabalha">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("lojas", {
                  initialValue: this.state.selecteds,
                  rules: [
                    {
                      required: true,
                      message: "selecione pelo menos uma Loja",
                    },
                  ],
                })(
                  <Select
                    mode="single"
                    style={{ width: "100%" }}
                    placeholder="Selecione pelo menos uma loja"
                    onChange={this.selectHandleChange}
                  >
                    {this.state.lojas}
                  </Select>
                )}
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    Ativo&nbsp;
                    <Tooltip title="Esse indicador está ativo?">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("ativo", {
                  initialValue: this.state.data.ativo,
                  rules: [
                    {
                      required: true,
                      message: "Informe se o indicador está ativo",
                    },
                  ],
                })(
                  <Select style={{ width: 120 }}>
                    <Option value="sim">Sim</Option>
                    <Option value="nao">Não</Option>
                  </Select>
                )}
              </Form.Item>

              <Form.Item
                ref="txtNome"
                label={
                  <span>
                    Nome Completo&nbsp;
                    <Tooltip title="Qual é o nome?">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("nome", {
                  initialValue: this.state.data.nome,
                  rules: [
                    {
                      required: true,
                      message: "Informe o nome",
                      whitespace: true,
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    CPF&nbsp;
                    <Tooltip title="Qual é o CPF?">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("cpf", {
                  initialValue: this.state.data.cpf,
                  rules: [
                    {
                      required: true,
                      message: "Informe o CPF",
                      whitespace: false,
                    },
                  ],
                })(<Input />)}
              </Form.Item>

              <Form.Item label="E-mail" ref="txtEmail">
                {getFieldDecorator("email", {
                  initialValue: this.state.data.email,
                  rules: [
                    {
                      type: "email",
                      message: "Email não é válido",
                    },
                    {
                      required: true,
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </TabPane>

            <TabPane tab="Endereço" key="endereco">
              <Form.Item label="Telefone">
                {getFieldDecorator("telefone", {
                  initialValue: this.state.data.endereco
                    ? this.state.data.endereco.telefone
                    : "",
                  rules: [{ required: false, message: "Informe o telefone" }],
                })(<Input style={{ width: "100%" }} />)}
              </Form.Item>

              <Form.Item label="Endereço">
                {getFieldDecorator("endereco", {
                  initialValue: this.state.data.endereco
                    ? this.state.data.endereco.logradouro
                    : "",
                  rules: [{ required: false, message: "Informe o endereço" }],
                })(<Input style={{ width: "100%" }} />)}
              </Form.Item>

              <Form.Item label="Bairro">
                {getFieldDecorator("bairro", {
                  initialValue: this.state.data.endereco
                    ? this.state.data.endereco.bairro
                    : "",
                  rules: [{ required: false, message: "Informe o bairro" }],
                })(<Input style={{ width: "50%" }} />)}
              </Form.Item>

              <Form.Item label="Cidade">
                {getFieldDecorator("cidade", {
                  initialValue: this.state.data.endereco
                    ? this.state.data.endereco.cidade
                    : "",
                  rules: [{ required: false, message: "Informe a cidade" }],
                })(<Input style={{ width: "70%" }} />)}
              </Form.Item>

              <Form.Item label="UF">
                {getFieldDecorator("uf", {
                  initialValue: this.state.data.endereco
                    ? this.state.data.endereco.uf
                    : "",
                  rules: [{ required: false, message: "Informe a UF" }],
                })(<Input style={{ width: "20%" }} />)}
              </Form.Item>
            </TabPane>
          </Tabs>

          <Form.Item {...tailFormItemLayout}>
            <Button
              type="primary"
              htmlType="submit"
              loading={this.state.loadingButton}
            >
              Salvar
            </Button>
            <Divider type="vertical" />
            <Button
              onClick={this.cancelClick}
              disabled={this.state.loadingButton}
            >
              Cancelar
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(IndicadorCRUD);
