import React from "react";
import { withRouter } from "react-router";
import { Table, Button, Modal, DatePicker } from "antd";

import "antd/dist/antd.css";
import "./Meta.css";
import MetaCRUD from "../../components/cruds/metas/MetaCRUD";
import { getGestorId, getUserType, getGerenteId } from "../../components/auth/auth-provider";
import BluveLayout from "../../components/layout/BluveLayout";
import { GESTOR } from "../../components/funcs/constants";
import empresaDB from "../../dataManager/dtmEmpresa";
import gerenteDB from "../../dataManager/dtmGerente";
import moment from "moment";
import 'moment/locale/pt-br';
import metaDB from "../../dataManager/dtmMeta";

const { MonthPicker } = DatePicker;
const dateFormat = "MMMM \\d\\e\\ YYYY"; // ha ha, consegui! 

// function isDateGreaterThanOrEqualToday(date) {
//   date._d.setHours(0, 0, 0, 0);
//   const today = new Date();
//   today.setHours(0, 0, 0, 0);
//   return date._d >= today;
// }

class Meta extends React.Component {
  state = {
    empresas: [],
    metas: [],
    canUpdate: false,
    loading: true,
    date: moment(),
  };

  constructor(props) {
    super(props);
    this.headerElement = React.createRef();
    this.updateList = this.updateList.bind(this);
    this.loadEmpresas = this.loadEmpresas.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  async componentDidMount() {
    await this.loadEmpresas();
    this.setState({ loading: false });
  }

  async loadEmpresas() {
    const gestorId = getGestorId();
    let empresas = [];
    if (getUserType() === GESTOR) {
      empresas = await empresaDB.getByGestorIdCustom(gestorId, 'login.login');
    }
    else {
      const gerenteId = getGerenteId();
      empresas = await gerenteDB.getEmpresasGerente(gerenteId, "razaoSocial");
    }
    this.setState({ empresas });
  };

  async onFilterClick() {
    this.setState({ loading: true });
    console.clear();
    const userType = getUserType();
    // const isOk = isDateGreaterThanOrEqualToday(this.state.date);

    let metas = [];
    if (userType === GESTOR) {
      const gestorId = getGestorId();
      metas = await metaDB.getByGestorId(gestorId);
      if (!metas) {
        metas = [];
      }
    }
    else {
      //alert('falta implementar');
      alert('erro');
      // indicadores = await indicadorDB.loadByLojas(this.state.lojas);
    }

    const lista = [];
    this.state.empresas.forEach(empresa => {
      const meta = metas.filter((meta) => {
        return meta.empresa.id === empresa.id;
      })[0];

      let item = {};
      if (!meta) {
        item = {
          key: empresa.key,
          new: true,
          empresa,
          faturamento: 0,
          pesoSemana1: 'R$ 0,00',
          pesoSemana2: 'R$ 0,00',
          pesoSemana3: 'R$ 0,00',
          pesoSemana4: 'R$ 0,00',
        }
      }
      else {
        item = meta
      }
      lista.push(item);
    });

    this.setState({ metas: lista, loading: false });
  }

  onDateChange(value) {
    this.setState({ date: value });
  }

  updateList(record) {
    record['ativoStr'] = record['ativo'] ? 'Sim' : 'Não';
    let lista = this.state.indicadores ? this.state.indicadores : [];
    let registroEncontrado = false;
    if (this.state.indicadores) {
      this.state.indicadores.forEach((item, index) => {
        if (item.key === record.key) {
          lista[index] = record;
          registroEncontrado = true;
        }
      });
    }

    if (!registroEncontrado) {
      lista.push(record);
    }
    this.setState({ indicadores: lista });
  }


  editarMetaElement(record) {
    record["empresas"] = this.state.empresas;
    record['date'] = this.state.date;
    this.headerElement.current.editar(record);
  }

  render() {
    const columns = [
      {
        title: "Empresa",
        dataIndex: "empresa.razaoSocial",
        key: "empresa.razaoSocial",
      },
      {
        title: "Fat Mensal (R$)",
        dataIndex: "faturamento",
        key: "faturamento"
      },
      {
        title: "Peso Semana 1",
        dataIndex: "pesoSemana1",
        key: "pesoSemana1"
      },
      {
        title: "Peso Semana 2",
        dataIndex: "pesoSemana2",
        key: "pesoSemana2"
      },
      {
        title: "Peso Semana 3",
        dataIndex: "pesoSemana3",
        key: "pesoSemana3"
      },
      {
        title: "Peso Semana 4",
        dataIndex: "pesoSemana4",
        key: "pesoSemana4"
      },
      {
        title: "Funções",
        width: 90,
        key: "action",
        render: (text, record) => (
          <span>
            <Button
              className="btnEdit"
              onClick={() => this.editarMetaElement(record)}
            >
              Editar
            </Button>
          </span>
        )
      }
    ];

    return (
      <div>
        <BluveLayout selectItem={"metas"}>
          <MonthPicker
            className='monthPickerMetas'
            format={dateFormat}
            onChange={this.onDateChange}
            allowClear={false}
            value={this.state.date}
            disabled={this.state.loading}
          />

          <Button
            type="primary"
            disabled={this.state.loading}
            onClick={this.onFilterClick}
          >
            Filtrar
            </Button>



          <Table
            pagination={{ pageSize: 10, position: "top" }}
            title={() => (
              <Header
                ref={this.headerElement}
                lojas={this.state.lojas}
                updateList={this.updateList}
                dateStr={this.state.date._d.toLocaleDateString('pt-br', { year: 'numeric', month: 'long' })}
              />
            )}
            columns={columns}
            dataSource={this.state.metas}
            bordered
            loading={this.state.loading}
          />
        </BluveLayout>
      </div>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.crudMetaElement = React.createRef();

    this.state = {
      visible: false
    };

  }

  editar(record) {
    this.crudMetaElement.current.show(true, record);
  }

  render() {
    return (
      <div>
        <NovaMeta
          ref={this.crudMetaElement}
          updateList={this.props.updateList}
          record={[]}
          dateStr={this.props.dateStr}
        ></NovaMeta>
      </div>
    );
  }
}

class NovaMeta extends React.Component {
  state = {
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
    metas: []
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record,
    });
  }

  handleOk(record) {
    this.setState({ visible: false });
    this.props.updateList(record);
  }

  handleCancel() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <Modal
        title={`Meta de ${this.props.dateStr}`}
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        footer={null}
        closable={false}
      >
        <MetaCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          editMode={this.state.editMode}
          record={this.state.record}
          lojas={this.state.lojas}
        ></MetaCRUD>
      </Modal>
    );
  }
}

export default withRouter(Meta);
