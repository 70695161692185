import firebase from "firebase";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  //messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const fireBase = firebase.initializeApp(config);

if (process.env.NODE_ENV === "production") {
  // Só chamo o Analytics em ambiente oficial! ;-)
  firebase.analytics();
}

// Habilita o cache offline
fireBase.firestore().enablePersistence();

export const fbDatabase = firebase.firestore();
export const fbFirestore = firebase.firestore;
export const Timestamp = firebase.firestore.Timestamp;
export const KBA_PROJECT = process.env.REACT_APP_FIREBASE_PROJECT;
export const FILA_URL = process.env.REACT_APP_FILA_URL;
export const API_URL = process.env.REACT_APP_PUBLIC_API_URL;
export const ROUTER_API_BLUVE = process.env.REACT_APP_API_URL;
//export const ROUTER_API_BLUVE = 'http://localhost:5001/bluve-4c-teste/us-central1/apiBluve'

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const UPDATE_EMAIL_URL = API_URL + "/cadastro/update";
export const MITTE_URL =
  "https://push-dot-montu-web.uc.r.appspot.com/communication/mitte-pro/send-paid-mail";
