import DataManager from "./dtm";
import { fbDatabase, fbFirestore } from "../firebase/firebase";
import { getGestorId } from "../components/auth/auth-provider";
import dayjs from "dayjs";

class HistoricoDB extends DataManager {

  state = {
    collection: "historico",
    orderBy: [], // usado na classe pai
  };

  historico = fbDatabase.collection(this.state.collection);

  async getHistoricoByPeriodo(dataIni, dataFim, customParamName, customParamValue, saidaOuRetorno, orderByAsc) {
    let query = this.queryBase(customParamName, customParamValue);

    if (saidaOuRetorno === 'Saida') {
      query = this.filterByPeriodoSaida(query, dataIni, dataFim);
    } else {
      query = this.filterByPeriodoRetorno(query, dataIni, dataFim);
    }

    if (orderByAsc) {
      const fieldPath = saidaOuRetorno === 'Saida' ? 'dataSaida' : 'dataRetorno';
      query = query.orderBy(fieldPath, 'asc');
    }

    let result = await query.get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmHistoricoJSX-HistoricoDB-getHistoricoByPeriodo');
    xRef.set({
      collection: 'historico',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(result.size),
      lastIncrement: result.size
    }, { merge: true }).catch(error => console.error(error));

    let data = [];
    if (result !== undefined) {
      result.docs.map((doc) => {
        data.push(doc.data())
        data[data.length - 1].id = doc.id
      });
    } else {
      data = undefined;
    }

    return data;
  }

  async getVendedorUltimoHistoricoByPeriodo(dataIni, dataFim, vendedorId) {
    let query = fbDatabase
      .collection('historico')
      .where('vendedorId', '==', vendedorId);

    query = this.filterByPeriodoSaida(query, dataIni, dataFim);

    query = query
      .orderBy('dataSaida', 'desc')
      .limit(1);

    const result = await query.get();
    let data = undefined;

    if (!result.empty) {
      const doc = result.docs[0];

      data = {
        id: doc.id,
        ...doc.data()
      }
    }

    return data;
  }

  queryBase(customParamName, customParamValue) {
    return this.historico.where(customParamName, "==", customParamValue);
  }

  filterByPeriodoSaida(query, inicio = null, fim = null) {
    query = inicio ? query.where("dataSaida", ">=", inicio) : query;
    query = fim ? query.where("dataSaida", "<=", fim) : query;

    return query;
  }

  filterByPeriodoRetorno(query, inicio = null, fim = null) {
    query = inicio ? query.where("dataRetorno", ">=", inicio) : query;
    query = fim ? query.where("dataRetorno", "<=", fim) : query;

    return query;
  }

  async get(query) {
    const snapshot = await query.get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmHistoricoJSX-HistoricoDB-get');
    xRef.set({
      collection: 'historico',
      lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
      count: fbFirestore.FieldValue.increment(snapshot.size),
      lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
      return undefined;
    }

    return snapshot;
  }
}

const historicoDB = new HistoricoDB();
export default historicoDB;
