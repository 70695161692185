import React from "react";
import {
  Modal,
  Form,
  Input,
  Tooltip,
  Icon,
  Button,
  Divider,
  Select,
  Tabs,
} from "antd";
import "antd/dist/antd.css";
import {
  fireBase,
  UPDATE_EMAIL_URL,
} from "../../../firebase/firebase";
import { getGestorId } from "../../auth/auth-provider";
import { isCPF, formatCPF } from "../../funcs/utils";
import mensagem from "components/message/Message";
import API from "services/api/api";

const { confirm } = Modal;
const { TabPane } = Tabs;
const { Option } = Select;
const api = new API();

class GerenteCRUD extends React.Component {
  state = {
    activeKey: "geral",
    confirmDirty: false,
    autoCompleteResult: [],
    empresas: [],
    selecteds: [],
    data: {
      key: this.props.record.key,
      nome: "",
      sobrenome: "",
      cpf: "",
      ativo: true,
      ativoStr: "Sim",
      email: "",
      endereco: {
        logradouro: "",
        bairro: "",
        cidade: "",
        uf: "",
        telefone: "",
      },
    },
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.changeLoadingStatus = this.changeLoadingStatus.bind(this);
  }

  tabChange(activeKey) {
    this.setState({ activeKey });
  }

  changeLoadingStatus(loading) {
    this.setState({ loading });
    if (this.props.onCloseHandle) {
      this.props.onCloseHandle(!loading)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const _this = this;
        mensagem.printFormErrors(err, 3, function () {
          _this.setState({ activeKey: "geral" });
        });
        return;
      }

      if (this.state.selecteds.length === 0) {
        mensagem.openNotificationWithIcon(
          "error",
          "Cadastro incompleto",
          "Selecione pelo menos uma empresa para a pessoa atender"
        );
        return;
      }

      this.changeLoadingStatus(true);

      const empresas = [];
      this.state.selecteds.forEach((obj) => {
        const empresa = this.state.empresas.filter((item) => {
          const id = obj.id ?? obj;
          return item.key === id;
        })[0];

        empresas.push({
          id: empresa.key,
          nome: empresa.props.children,
        });
      });

      if (!isCPF(values.cpf)) {
        mensagem.openNotificationWithIcon(
          "error",
          "CPF inválido",
          "O CPF informado não está correto."
        );
        this.changeLoadingStatus(false);
        return;
      }
      values.cpf = formatCPF(values.cpf);

      const item = {
        nome: values.nome,
        sobrenome: values.sobrenome,
        cpf: values.cpf,
        ativo: values.ativo.toLocaleLowerCase() === "sim" ? true : false,
        email: values.email,
        empresas,
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone,
        },
        gestorId: getGestorId(),
      };

      let res;
      if (!this.props.editMode) {
        // Cadastrar gerente
        res = await api.post({ ...item }, '/gerente');
      } else {
        // Atualizar gerente
        const isChanged = this.props.record.email !== values.email;

        const confirmou =
          !isChanged || (await this.showConfirmationModal(values.email));

        if (!confirmou) {
          mensagem.openNotificationWithIcon(
            "warning",
            "Verifique o email do gestor"
          );
          this.changeLoadingStatus(false);
          return;
        }

        let atualizarAtivo = false;
        if (this.props.record.ativo !== item.ativo) atualizarAtivo = true;

        res = await api.put({
          key: this.props.record.key,
          updateList: item,
          atualizarAtivo
        }, '/gerente');

        if (isChanged) {
          mensagem.openNotificationWithIcon(
            "warning",
            "Aguarde, estamos alterando o email..."
          );
          await this.updateEmail(this.props.record.email, values.email);
        }
      }

      if (!res.recorded) {
        const title = res.duplicated ? 'Duplicação' : 'Erro';
        mensagem.openNotificationWithIcon(
          'error',
          title,
          res.message
        );

        this.changeLoadingStatus(false);
        return;
      } else {
        mensagem.openNotificationWithIcon(
          'success',
          'Perfeito!',
          res.message,
          3
        );

        item["key"] = res.id ?? this.props.record.key;
        item.empresas = empresas
          .map((empresa) => {
            return empresa.nome;
          })
          .join(", ");

        this.props.handleOk(item);
      }
    });
  };

  showConfirmationModal(email) {
    return new Promise((resolve) => {
      confirm({
        title: "Alteração de email",
        icon: (
          <Icon
            type="exclamation"
            style={{ fontSize: "16px", color: "#08c" }}
            theme="outlined"
          />
        ),
        content: "Confirma alteração do email para: " + email + "?",
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  componentDidMount() {
    const empresas = [];

    this.props.empresas.sort((a, b) =>
      a.login.login > b.login.login ? 1 : -1
    );

    this.props.empresas.forEach((item) => {
      empresas.push(
        <Option key={item.key} value={item.login.login}>
          {item.login.login}
        </Option>
      );
    });

    this.setState({ empresas: empresas });
    if (this.props.editMode && this.props.record) {
      const selecteds = [];
      this.props.record.empresas.forEach((item) => {
        selecteds.push({
          id: item.key,
          nome: item.razaoSocial,
        });
      });
      this.setState({
        data: {
          key: this.props.record.key,
          nome: this.props.record.nome,
          sobrenome: this.props.record.sobrenome,
          cpf: this.props.record.cpf,
          ativo: this.props.record.ativo,
          ativoStr: this.props.record.ativo ? "Sim" : "Não",
          email: this.props.record.email,
          endereco: this.props.record.endereco,
          empresas: this.props.record.empresas,
        },
        selecteds,
      });
    }
    this.selectEmpresaInput.focus();
  }

  async getToken() {
    const token = await fireBase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        return "bearer " + idToken;
      })
      .catch(function (error) {
        console.log(error.message);
        return;
      });

    return token;
  }

  async updateEmail(oldEmail, newEmail) {
    return new Promise(async (resolve, reject) => {
      const URL = UPDATE_EMAIL_URL;

      const token = await this.getToken();

      const item = {
        headers: {
          "content-type": "application/json",
          oldemail: oldEmail,
          newemail: newEmail,
          Authorization: token,
          authUserToken: true
        },
        method: "POST",
      };
      fetch(URL, item)
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData.message);
          }
        });
    });
  }

  cancelClick() {
    this.props.handleCancel();
  }

  handleSelectChange(value, obj) {
    const selecteds = obj.map((item) => {
      if (item.key === item.props.children) {
        const itemSelected = this.state.selecteds.filter(empresa => empresa.nome === item.props.children);

        return {
          id: itemSelected[0].id,
          nome: itemSelected[0].nome
        };
      }

      return {
        id: item.key,
        nome: item.props.children,
      };
    });

    this.setState({ selecteds: selecteds });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const selectedNomes = this.state.selecteds.map((item) => item.nome);

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Tabs activeKey={this.state.activeKey} onChange={this.tabChange}>
          <TabPane tab="Geral" key="geral">
            <Form.Item
              ref="selectEmpresa"
              label={
                <span>
                  Empresa&nbsp;
                  <Tooltip title="Escolha as empresas que a pessoa atende">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("empresas", {
                valuePropName: "value",
                initialValue: selectedNomes,
                rules: [
                  {
                    required: true,
                    message: "selecione pelo menos uma Empresa",
                    type: "array",
                  },
                ],
              })(
                <Select
                  ref={(input) => {
                    this.selectEmpresaInput = input;
                  }}
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Selecione pelo menos uma empresa"
                  onChange={this.handleSelectChange}
                  autocomplete="new-password"
                  onFocus={() => {
                    if (!this.state.autocompleteDisabled) {
                      let i;
                      const el = document.getElementsByClassName(
                        "ant-select-search__field"
                      );
                      for (i = 0; i < el.length; i++) {
                        el[i].setAttribute(
                          "autocomplete",
                          "registration-select"
                        );
                      }
                      this.setState({ autocompleteDisabled: true });
                    }
                  }}
                >
                  {this.state.empresas}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              ref="txtNome"
              label={
                <span>
                  Nome&nbsp;
                  <Tooltip title="Qual é o nome?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("nome", {
                initialValue: this.state.data.nome,
                rules: [
                  {
                    required: true,
                    message: "Informe o nome",
                    whitespace: true,
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Sobrenome&nbsp;
                  <Tooltip title="Qual é o sobrenome?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("sobrenome", {
                initialValue: this.state.data.sobrenome,
                rules: [
                  {
                    required: true,
                    message: "Informe o sobrenome",
                    whitespace: true,
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  CPF&nbsp;
                  <Tooltip title="Qual é o CPF?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("cpf", {
                initialValue: this.state.data.cpf,
                rules: [
                  {
                    required: true,
                    message: "Informe o CPF",
                    whitespace: false,
                  },
                ],
              })(<Input />)}
            </Form.Item>

            {
              <Form.Item
                label={
                  <span>
                    Ativo&nbsp;
                    <Tooltip title="Esse vendedor está ativo?">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("ativo", {
                  initialValue: this.state.data.ativoStr,
                  rules: [
                    {
                      required: true,
                      message: "Informe se o Vendedor está ativo",
                    },
                  ],
                })(
                  <Select style={{ width: 120 }}>
                    <Option value="sim">Sim</Option>
                    <Option value="nao">Não</Option>
                  </Select>
                )}
              </Form.Item>
            }

            <Form.Item label="E-mail" ref="txtEmail">
              {getFieldDecorator("email", {
                initialValue: this.state.data.email,
                rules: [
                  {
                    type: "email",
                    message: "Email não é válido",
                  },
                  {
                    required: true,
                    message: "Por favor, informe o email",
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </TabPane>

          <TabPane tab="Endereço" key="endereco">
            <Form.Item label="Telefone">
              {getFieldDecorator("telefone", {
                initialValue: this.state.data.endereco.telefone,
                rules: [{ required: false, message: "Informe o telefone" }],
              })(<Input style={{ width: "100%" }} />)}
            </Form.Item>

            <Form.Item label="Endereço">
              {getFieldDecorator("endereco", {
                initialValue: this.state.data.endereco.logradouro,
                rules: [{ required: false, message: "Informe o endereço" }],
              })(<Input style={{ width: "100%" }} />)}
            </Form.Item>

            <Form.Item label="Bairro">
              {getFieldDecorator("bairro", {
                initialValue: this.state.data.endereco.bairro,
                rules: [{ required: false, message: "Informe o bairro" }],
              })(<Input style={{ width: "50%" }} />)}
            </Form.Item>

            <Form.Item label="Cidade">
              {getFieldDecorator("cidade", {
                initialValue: this.state.data.endereco.cidade,
                rules: [{ required: false, message: "Informe a cidade" }],
              })(<Input style={{ width: "70%" }} />)}
            </Form.Item>

            <Form.Item label="UF">
              {getFieldDecorator("uf", {
                initialValue: this.state.data.endereco.uf,
                rules: [{ required: false, message: "Informe a UF" }],
              })(<Input style={{ width: "20%" }} />)}
            </Form.Item>
          </TabPane>
        </Tabs>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Salvar
          </Button>
          <Divider type="vertical" />
          <Button onClick={this.cancelClick} disabled={this.state.loading}>Cancelar</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(GerenteCRUD);
