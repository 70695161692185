import React, { useState, useEffect } from "react";
import { Modal, message } from 'antd';
import { sleep } from "./helperFunctions";

import Wizard from "./Wizard";
import LojaStep from "./steps/LojaStep";
import PesoVendedorPorDia from "./steps/PesoVendedorPorDia";
import PremiacaoStep from "./steps/PremiacaoStep"
import EscalaStep from "./steps/EscalaStep";
import metaDB from "dataManager/dtmMeta";
import moment from "moment";

import "./meta.css";
import { getGestorId } from "components/auth/auth-provider";

const WizardStep = ({ children }) => children;

const Meta = (props) => {
  const { empresas, empresaSelecionada, title, visible, onOk, onCancel, mesPadrao } = props;

  const [empresa, setEmpresa] = useState(empresaSelecionada.key);
  const [modalVisible, setModalVisible] = useState(visible);

  useEffect(() => {
    setModalVisible(visible);
  }, [visible]);

  const periodos = 4;

  const dataMeta = (mesPadrao - 1) < moment().month() ? moment({ month: mesPadrao - 1, year: moment().year() + 1 }) : moment({ month: mesPadrao - 1 });

  const initialValues = {
    dataMeta: dataMeta,
    periodoMeta: [moment(dataMeta).startOf('month'), moment(dataMeta).endOf('month')],
    loja: "",
    principal: 0.0,
    metaAdicional: false,
    secundaria: 0.0,
    secundariaProporcional: 10,
    metaSecundariaProporcional: false,
    ticketMedio: 0.0,
    numeroAtendimentos: 0,
    pesoTotal: 0,
    periodos: new Array(periodos).fill({
      inicio: {},
      fim: {},
      dias: 0,
      peso: 0,
      meta: 0.0,
      metaPorDia: [],
      vendedorPorDia: []
    }),
    premiacaoMetaPrincipal: 0.0,
    premiacaoPorPeriodo: [0.0, 0.0, 0.0, 0.0],
    premiacaoMetaAdicional: 0.0,
    premiacaoTicketMedio: 0.0,
    premiacaoNumeroAtendimentos: 0.0,
    vendedores: [],
    percentualDeFuga: 0
  };


  if (empresaSelecionada.values) {
    const { values } = empresaSelecionada;

    const inicio = moment(empresaSelecionada.values.inicio.seconds * 1000);
    const fim = moment(empresaSelecionada.values.fim.seconds * 1000);

    initialValues.periodoMeta = [inicio, fim];

    initialValues.dataMeta = moment({ month: values.mes - 1, year: values.ano });
    initialValues.loja = values.empresaId;
    initialValues.principal = values.objetivo.principal;
    initialValues.percentualDeFuga = values.objetivo.percentualDeFuga;

    initialValues.metaAdicional = values.objetivo.adicional !== undefined;

    if (initialValues.metaAdicional) {
      initialValues.metaSecundariaProporcional = values.objetivo.adicional.proporcional;

      if (values.objetivo.adicional.proporcional) {
        initialValues.secundariaProporcional = values.objetivo.adicional.porcentagem;
      } else {
        initialValues.secundaria = values.objetivo.adicional.valores[0];
      }
    }

    initialValues.ticketMedio = values.objetivo.ticketMedio | initialValues.ticketMedio;

    initialValues.numeroAtendimentos = values.objetivo.numeroAtendimentos || initialValues.numeroAtendimentos;

    if (values.premios) {
      initialValues.premiacaoMetaPrincipal = values.premios.metaPrincipal || initialValues.premiacaoMetaPrincipal;
      initialValues.premiacaoPorPeriodo = values.premios.porPeriodo || initialValues.premiacaoPorPeriodo;
      initialValues.premiacaoMetaAdicional = values.premios.metasAdicionais || initialValues.premiacaoMetaAdicional;
      initialValues.premiacaoTicketMedio = values.premios.ticketMedio || initialValues.premiacaoTicketMedio;
      initialValues.premiacaoNumeroAtendimentos = values.premios.numeroAtendimentos || initialValues.premiacaoNumeroAtendimentos;
    }

    initialValues.pesoTotal = 100;

    initialValues.periodos = values.periodos.map((item, index) => {
      return {
        inicio: moment(item.inicio.toDate()),
        fim: moment(item.fim.toDate()),
        dias: item.dias,
        peso: item.peso,
        meta: item.valorTotal,
        metaVendedorDia: item.metaVendedorDia,
        metaPorDia: item.metaPorDia.length ? item.metaPorDia : [],
        vendedorPorDia: item.vendedorPorDia.length ? item.vendedorPorDia : []
      };
    });

    initialValues.vendedores = values.vendedores.map((item, index) => {
      return {
        id: item.id,
        avatar: item.avatar,
        nome: item.nome,
        apelido: item.apelido,
        dias: item.diasExpediente,
        metasPeriodos: item.meta.periodos,
        metaTotal: item.meta.total
      };
    });
  }

  const submitHandle = async (values) => {
    const adicionalProporcional = values.metaSecundariaProporcional;

    let metaAdicional = {
      proporcional: adicionalProporcional
    };

    metaAdicional = adicionalProporcional ?
      { ...metaAdicional, porcentagem: values.secundariaProporcional } :
      { ...metaAdicional, valores: [values.secundaria] };

    const dataMeta = moment(new Date(values.dataMeta));

    const ano = dataMeta.year();
    const mes = dataMeta.month() + 1;
    
    const [inicio, fim] = values.periodoMeta;

    const gestorId = getGestorId();

    const meta = {
      mes,
      ano,
      inicio: inicio.toDate(),
      fim: fim.toDate(),
      gestorId,
      empresaId: values.loja,
      objetivo: {
        principal: values.principal,
        percentualDeFuga: values.percentualDeFuga
      },
      periodos: values.periodos.map((item) => ({
        inicio: item.inicio.toDate(),
        fim: item.fim.toDate(),
        dias: item.dias,
        peso: item.peso,
        valorTotal: item.meta,
        metaPorDia: item.metaPorDia,
        vendedorPorDia: item.vendedorPorDia,
        metaVendedorDia: item.metaVendedorDia
      })),
      vendedores: values.vendedores.map((item) => ({
        id: item.id,
        diasExpediente: item.dias,
        avatar: item.avatar,
        nome: item.nome,
        apelido: item.apelido,
        meta: {
          total: item.metaTotal,
          periodos: item.metasPeriodos
        }
      })),
      premios: {
        metaPrincipal: values.premiacaoMetaPrincipal,
        porPeriodo: values.premiacaoPorPeriodo,
        metasAdicionais: values.premiacaoMetaAdicional,
        ticketMedio: values.premiacaoTicketMedio,
        numeroAtendimentos: values.premiacaoNumeroAtendimentos
      }
    };

    if (values.ticketMedio > 0) {
      meta.objetivo = { ...meta.objetivo, ticketMedio: values.ticketMedio };
    }

    if (values.numeroAtendimentos) {
      meta.objetivo = { ...meta.objetivo, numeroAtendimentos: values.numeroAtendimentos };
    }

    if (values.metaAdicional) {
      meta.objetivo = { ...meta.objetivo, adicional: metaAdicional };
    }

    if (empresaSelecionada.values) {
      try {
        await metaDB.update(empresaSelecionada.values.key, meta);
        message.success("Alterações na meta foram salvas!");
        onOk("Ok");
      } catch (error) {
        message.error(error);
      }
    } else {
      try {
        await metaDB.add(meta);
        message.success("Nova meta adicionada!");
        onOk("Ok");
      } catch (error) {
        message.error(error);
      }
    }
  };

  return (
    <Modal title="Meta"
      visible={modalVisible}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      destroyOnClose={true}
      centered={true}
      width={1020}
      maskClosable={false}
    >
      <Wizard initialValues={initialValues} onSubmit={submitHandle}>
        <WizardStep title="Loja">
          <LojaStep empresas={empresas} selecionada={empresaSelecionada} onChange={setEmpresa} periodos={periodos} />
        </WizardStep>

        <WizardStep title="Peso e Vendedores por Dia">
          <PesoVendedorPorDia />
        </WizardStep>

        <WizardStep title="Premiações">
          <PremiacaoStep selecionada={empresaSelecionada} />
        </WizardStep>

        <WizardStep title="Escala">
          <EscalaStep />
        </WizardStep>

      </Wizard>
    </Modal>
  );
};

export default Meta;
