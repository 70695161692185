import DataManager from './dtm';
import { fbDatabase, fbFirestore } from '../firebase/firebase';
import { DEFAULT_ATATAR } from 'components/funcs/constants';


async function queryBy10(ids, ativoFiltro) {
    const snapshot = await fbDatabase.collection("indicadores")
        .where("loja.id", "in", ids)
        .where("ativo", "==", !ativoFiltro)
        .orderBy("nome")
        .get();

    /** X-TUDO */
    let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmIndicadorJSX-queryBy10');
    xRef.set({
        collection: 'indicadores',
        lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
        count: fbFirestore.FieldValue.increment(snapshot.size),
        lastIncrement: snapshot.size
    }, { merge: true }).catch(error => console.error(error));

    if (snapshot.empty) {
        return [];
    }

    const data = snapshot.docs.map((item) => ({
        key: item.id,
        ...item.data(),
        ativoStr: item.data().ativo ? 'Sim' : 'Não',
    }));

    return data;
}


class IndicadorDB extends DataManager {

    state = {
        collection: 'indicadores',
        orderBy: ['nome'] // usado na classe pai
    }

    async getByGestorIdCustom(gestorId, ativoFiltro) {
        let query = fbDatabase.collection(this.state.collection)
            .where("gestorId", "==", gestorId)
            .where("ativo", "==", !ativoFiltro);

        if (this.state.orderBy.length > 0) {
            for (let index = 0; index < this.state.orderBy.length; index++) {
                const order = this.state.orderBy[index];
                query = query.orderBy(order);
            }
        }

        const snapshot = await query.get();

        /** X-TUDO */
        let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmIndicadorJSX-IndicadorDB-getByGestorIdCustom');
        xRef.set({
            collection: 'indicadores',
            lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
            count: fbFirestore.FieldValue.increment(snapshot.size),
            lastIncrement: snapshot.size
        }, { merge: true }).catch(error => console.error(error));

        if (snapshot.empty) {
            return undefined;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            avatar: item.data().avatar ? item.data().avatar : DEFAULT_ATATAR,
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByLojaId(id) {
        const snapshot = await fbDatabase.collection("indicadores")
            .where("loja.id", "==", id)
            .orderBy("nome")
            .get();

        /** X-TUDO */
        let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmIndicadorJSX-IndicadorDB-getByLojaId');
        xRef.set({
            collection: 'indicadores',
            lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
            count: fbFirestore.FieldValue.increment(snapshot.size),
            lastIncrement: snapshot.size
        }, { merge: true }).catch(error => console.error(error));

        if (snapshot.empty) {
            return undefined;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data()
        }));

        return data;
    }

    async loadByLojas(lojas, ativoFiltro) {
        let ids = []
        let data = [];
        for (let index = 0; index < lojas.length; index++) {
            ids.push(lojas[index].key);
            if (ids.length >= 10) {
                const ljs = await queryBy10(ids)
                data.push.apply(data, ljs);
                ids = [];
            }
        }

        if (ids.length > 0) {
            const ljs = await queryBy10(ids, ativoFiltro);
            data.push.apply(data, ljs);
        }
        return data;
    }
}

const indicadorDB = new IndicadorDB();
export default indicadorDB;