import { getGestorId, getUserType, getGerenteId } from "../../../components/auth/auth-provider";
import empresaDB from "../../../dataManager/dtmEmpresa";
import gerenteDB from "../../../dataManager/dtmGerente";
import { GERENTE } from "../../../components/funcs/constants";
import conversaoDB from "../../../dataManager/dtmConversao";
import motivoDB from "../../../dataManager/dtmMotivo";
import { salvarXlsx } from "../metas/Funcs-Metas";


export async function getEmpresas() {
  const gestorId = getGestorId();
  const empresas = await empresaDB.getByGestorIdCustom(gestorId, 'login.login');
  const newEmpresas = [];
  const userType = getUserType();

  if (userType === GERENTE) {
    const gerenteId = getGerenteId();
    const empresasGerente = await gerenteDB.getEmpresasGerente(gerenteId, 'razaoSocial');

    for (let i = 0; i < empresasGerente.length; i++) {
      const empresa = empresas.filter((item) => {
        return item.key === empresasGerente[i].id;
      });

      if (empresa && empresa.length > 0) {
        newEmpresas.push(empresa[0]);
      }
    }
  }
  return newEmpresas.length > 0 ? newEmpresas : empresas;
}

export async function getMotivos() {

  const gestorId = getGestorId();
  const motivos = await motivoDB.getByGestorId(gestorId);
  return motivos;
}

export function getNextColor(color) {
  if (color === "#fff") {
    color = "#eee";
  } else {
    color = "#fff";
  }
  return color;
}

export async function buildReport(props) {
  const { dataInicial, dataFinal, motivoId, empresas, exibirSku } = props;
  const inicio = dataInicial.startOf("day").toDate();
  const fim = dataFinal.endOf("day").toDate();
  const perdas = await conversaoDB.getPerdasByPeriodoMotivo(inicio, fim, motivoId, empresas);


  let produtos = [];
  perdas.forEach(perda => {
    perda.produtos.forEach(produto => {
      let prod = produtos.filter((item) => {
        return item.key === produto.id
      })[0];

      if (!prod) {
        prod = {
          key: produto.id,
          nome: produto.id !== '--' ? exibirSku && produto.codigo ? produto.codigo : produto.nome : 'Fora do Mix',
          total: 1
        }
      }
      else {
        prod['total'] += 1;
      }

      const newList = produtos.filter(item => {
        return item.key !== produto.id
      })

      newList.push(prod);
      produtos = newList;

    })
  })

  produtos = produtos.sort((a, b) => (a.total < b.total ? 1 : -1));

  const reportData = [];
  let color = "#fff";
  produtos.forEach(produto => {
    produto['color'] = color;
    color = getNextColor(color);
    if (reportData.length < 10) {
      reportData.push(produto);
    }
  });
  return reportData;
}

export async function exportToExcel(data, empresaNome, periodo, motivoNome) {
  const dataInicial = periodo.inicial.toDate().toLocaleDateString();
  const dataFinal = periodo.final.toDate().toLocaleDateString();

  const infoExcel = [];
  infoExcel.push([`Empresa: ${empresaNome}`]);
  infoExcel.push([`Motivo: ${motivoNome}`]);
  infoExcel.push(['']);

  infoExcel.push([
    'Produto',
    'Quantidade',
  ]);

  data.forEach(item => {
    infoExcel.push([
      item.nome,
      item.total,
    ]);
  });

  const fileName = `Relatório de Motivos x Produtos - Período de ${dataInicial} até ${dataFinal}`;
  await salvarXlsx(infoExcel, fileName);
}
