import React from 'react';

import BluveLayout from '../../../components/layout/BluveLayout';
import { DatePicker, Button, Table, message } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { withRouter } from 'react-router';
import { getUserType } from '../../../components/auth/auth-provider';
import { getEmpresas, buildReport } from './Funcs-IndicacaoLoja';
import bluveStorage from "../../../components/localstorage/localstorage";
import moment from 'moment';
import './IndicacaoLoja.css'


const dateFormat = 'DD/MM/YYYY'
const { RangePicker } = DatePicker;

class IndicacaoLoja extends React.Component{
    state = {
        empresas: [],
        reportData: [],
        periodo: {},
        alterouConfiguracaoRelatorio: false,
        columns: [],
        loading: false
    }

    constructor(props){
        super(props);
        this.onDateChange = this.onDateChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
    }

    async componentDidMount(){
        const empresas = await getEmpresas();
        const userType = await getUserType();

        let periodo = bluveStorage.getReportPeriodo();

        if(!periodo){
            periodo = {
                inicial: moment().subtract(7, 'days'),
                final: moment()
            };
        } else {
            periodo.inicial = moment(periodo.inicial);
            periodo.final = moment(periodo.final)
        }

        const report = bluveStorage.getReportIndicacaoLoja();
        let reportData = report.data ? report.data : [];
        let columns = report.columns ? report.columns : [];

        this.setState({
            empresas: empresas,
            periodo,
            columns,
            reportData
        })
    }

    async onFilterClick(){
        if (!this.state.periodo.inicial || !this.state.periodo.final) {
            message.warning('Selecione o período para gerar a tabela.', 2);
            return;
        }

        this.setState({ loading: true });
        
        setTimeout(async () => {
            const columns = this.buildColumns();
            const data = await buildReport(
                this.state.empresas,
                this.state.periodo.inicial,
                this.state.periodo.final
                );

            if (this.state.alterouConfiguracaoRelatorio) {
                bluveStorage.clearAllReportsData();
            }

            bluveStorage.setReportPeriodo(this.state.periodo);
            bluveStorage.setReportIndicacaoLoja(data, columns);

            this.setState({ loading: false, reportData: data, columns });
            
            if (!data) {
                message.info('Sem movimentos nesse período');
                this.setState({ loading: false, reportData: [], columns });
                return;
            }

        }, 500)
    }


    onDateChange(value) {
        const periodo = {
            inicial: value[0],
            final: value[1]
        }
        this.setState({ periodo, alterouConfiguracaoRelatorio: true });
    }
    
    buildColumns(){
        let columns = [];

        columns = [
            {
                title:'Empresa / Loja',
                dataIndex:'nomeEmpresa',
                key:'empresa'
            },
            {
                title:'Total de Venda',
                dataIndex:'valorTotal',
                key:'valorTotal'
            }
        ]

        return columns;
    }

    render(){

        return(
            <div>
                <BluveLayout selectItem={"parceiros/indicacao-loja"}>
                    <div className="containerIndicacao">
                        <label>Período:</label>
                            <div className="rangePickerDuplo">
                                <RangePicker
                                    locale={locale}
                                    disabled={this.state.loading}
                                    format={dateFormat}
                                    placeholder={['Data Inicial', 'Data Final']}
                                    onChange={this.onDateChange}
                                    value={[this.state.periodo.inicial, this.state.periodo.final]}
                                    style={{ marginTop: '50px', marginBottom: '15px' }}
                                />
                            </div>
                        <Button type='primary' loading={this.state.loading} onClick={this.onFilterClick}>Filtrar</Button>

                    </div>

                    <div className="divTableIndicacaoLoja">
                        <Table
                            columns={this.state.columns}
                            rowClassName={(record) => record.color.replace('#', '')}
                            pagination={{ hideOnSinglePage: true }}
                            dataSource={this.state.reportData}
                            bordered
                            rowKey={record => record.key}
                        />
                    </div>
                </BluveLayout>
            </div>
        
        );
    }

}

export default withRouter(IndicacaoLoja);