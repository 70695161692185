import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Icon, Modal, message, Tabs } from "antd";
import { getGerenteId, getGestorId, getUserType } from 'components/auth/auth-provider';
import conversaoDB from 'dataManager/dtmConversao';
import { Bar, Doughnut, HorizontalBar, Pie } from 'react-chartjs-2';
import vendedorDB from 'dataManager/dtmVendedor';
import ColComponent from './ColComponent';
import _ from 'lodash';
import './Styles.css';
import motivoDB from 'dataManager/dtmMotivo';
import { GERENTE, GESTOR } from 'components/funcs/constants';
import gerenteDB from 'dataManager/dtmGerente';
import empresaDB from 'dataManager/dtmEmpresa';


const moment = require('moment');
const { TabPane } = Tabs;


const RealTime = (props) => {

    const TODAS_AS_EMPRESAS = 'x-todas-as-empresas-x';

    const [tabAtiva, setTabAtiva] = useState('todas');
    const [atendimentos, setAtendimentos] = useState(0);
    const [emAtendimento, setEmAtendimento] = useState(0);
    const [atendimentosLoaded, setAtendimentosLoaded] = useState(false);
    const [vendasPerdidas, setVendasPerdidas] = useState(0);
    const [taxaConversao, setTaxaConversao] = useState(0);
    const [mediaAtendimento, setMediaAtendimento] = useState('');
    const [rankingVendedores, setRankingVendedores] = useState([]);
    const [empresas, setEmpresas] = useState([]);

    const SPAN = 6;
    const SPAN_GRAFICOS = 8;
    const MAXIMO_COLUNAS_GRAFICO = 5;

    const [motivos, setMotivos] = useState([]);

    const [dataMotivos, setDataMotivos] = useState({});
    const [optMotivos, setOptMotivos] = useState({});

    const [dataLojasMaisPerdas, setLojasMaisPerdas] = useState({});
    const [optLojasMaisPerdas, setOptLojasMaisPerdas] = useState({});

    const [dataLojasComMaisVendas, setLojasComMaisVendas] = useState({});
    const [optLojasComMaisVendas, setOptLojasComMaisVendas] = useState({});

    const [dificuldadeSolucao, setDificuldadeSolucao] = useState({});
    const [optDificuldadeSolucao, setOptDificuldadeSolucao] = useState({});

    const [impactoNegativo, setImpactoNegativo] = useState('0%');

    const [dataProdutosPerdidos, setProdutosPerdidos] = useState({});
    const [optProdutosPerdidos, setOptProdutosPerdidos] = useState({});

    const [dataProdutosVendidos, setProdutosVendidos] = useState({});
    const [optProdutosVendidos, setOptProdutosVendidos] = useState({});

    const [dataVendedores, setVendedores] = useState({});
    const [optVendedores, setOptVendedores] = useState({});

    const [dataRanking, setRanking] = useState({});
    const [optRanking, setOptRanking] = useState({});

    const [empresasDoUsuario, setEmpresasDoUsuario] = useState([]);

    const tabsChange = (key) => {
        setTabAtiva(key);
    }

    const usuarioAutorizadoAVerEmpresa = (empresaId) => {
        return empresasDoUsuario.some((empresa) => empresa.key === TODAS_AS_EMPRESAS) ||
            empresasDoUsuario.some((empresa) => empresa.key === empresaId);
    }

    useEffect(() => {
        const userType = getUserType();
        const empresas = [];
        if (userType === GESTOR) {
            empresas.push({ key: TODAS_AS_EMPRESAS });
            setEmpresasDoUsuario(empresas);
        } else if (userType === GERENTE) {
            const gerenteId = getGerenteId();
            gerenteDB.getEmpresas(gerenteId, "razaoSocial")
                .then((response) => {
                    const dados = response.map((empresa) => {
                        return { key: empresa.key, value: empresa.razaoSocial };
                    });
                    empresas.push(...dados);
                    setEmpresasDoUsuario(empresas);
                })
                .catch((err) => {
                    message.error('Erro ao carregar empresas do usuário na dashboard');
                    console.log(err);
                });
        }
    }, []);

    const exibirAjuda = (tipo) => {
        let ajuda = '';
        if (tipo === 'Maiores Motivos de Perdas') ajuda = 'Os dados apresentados neste gráfico mostram os principais motivos pelos quais você está perdendo suas vendas.';
        if (tipo === 'Dificuldade de Solução') ajuda = 'Os dados apresentados neste gráfico são calculados de acordo com o grau de dificuldade estabelecido no cadastro de Motivos.';
        if (tipo === 'Produtos mais Perdidos') ajuda = 'Os dados apresentados neste gráfico mostram os produtos mais perdidos.';
        if (tipo === 'Produtos mais Vendidos') ajuda = 'Os dados apresentados neste gráfico mostram os produtos mais vendidos.';
        if (tipo === 'Movimentação dos Vendedores') ajuda = 'Os dados apresentados neste gráfico mostram o STATUS atual dos seus vendedores.';
        if (tipo === 'Ranking de Atendimento') ajuda = 'Os dados apresentados neste gráfico mostram o ranking de atendimento dos seus vendedores.';
        if (tipo === 'Impacto Negativo nos Atendimentos') ajuda = 'Os dados apresentados neste gráfico mostram o impacto negativo nos atendimentos. Quando um cliente não compra nada, mas experimenta, o tempo de atendimento é afetado negativamente. Essa informação é dada pelo vendedor no Controle da Fila da Vez.';
        if (tipo === 'Lojas com mais Perdas') ajuda = 'Os dados apresentados neste gráfico mostram as lojas que mais estão perdendo vendas. Se você for gerente, verá apenas as lojas que gerencia.';
        if (tipo === 'Lojas com mais Vendas') ajuda = 'Os dados apresentados neste gráfico mostram as lojas que mais estão vendendo. Se você for gerente, verá apenas as lojas que gerencia.';

        Modal.info({
            title: `Ajuda para ${tipo}`,
            content: (
                <div>
                    <p>{ajuda}</p>
                </div>
            ),
            onOk() { },
        });
    }

    const configurarGrafico = (data, setData, setOptions, limit, exibirLegenda, omiyitOutros) => {
        if (exibirLegenda === undefined) {
            exibirLegenda = true;
        }
        if (!limit) {
            limit = 9;
        } else {
            limit = limit - 1;
        }

        const sorted = Object.entries(data).sort((a, b) => b[1] - a[1]);
        let slicedData = sorted.slice(0, limit);
        if (sorted.length > limit && omiyitOutros === true) {
            const outrosSum = sorted.slice(limit).reduce((sum, item) => sum + item[1], 0);
            slicedData.push(["Outros", outrosSum]);
        }

        const cores = [
            "#1890FF", "#ffe2bf", "#bfeeff", "#e47d00", "#a04a00"
        ];

        const dataGrafico = {
            labels: slicedData.map(item => item[0]),
            datasets: [
                {
                    data: slicedData.map(item => item[1]),
                    backgroundColor: cores,
                },
            ],
        };

        setData(dataGrafico);

        const optionsGrafico = {
            responsive: true,
            maintainAspectRatio: true,
            scales: {
                y: {
                    beginAtZero: true,
                },
            },
            legend: {
                display: exibirLegenda,
                position: 'bottom',
                labels: {
                    fontSize: 12,
                },
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        const dataset = data.datasets[tooltipItem.datasetIndex];
                        const currentValue = dataset.data[tooltipItem.index];
                        return `${data.labels[tooltipItem.index]}: ${currentValue}`;
                    },
                },
            },
        };

        setOptions(optionsGrafico);
    }

    const callBackAtendimentos = (data) => {
        setAtendimentosLoaded(false);
        if (data.empty) {
            setAtendimentos(0);
            setVendasPerdidas(0);
            setTaxaConversao(0);
            return;
        }

        let totalAtendimentos = 0;
        let perdas = 0;
        let impactosNegativos = 0;
        const lojasComMaisPerdas = {};
        const lojasComMaisVendas = {};
        data.docs.forEach((doc) => {
            if (usuarioAutorizadoAVerEmpresa(doc.data().empresaId)) {
                totalAtendimentos++;

                const empresaEncontrada = empresas.filter(empresa => empresa.id === doc.data().empresaId)[0];
                if (doc.data().vendeu === false) {
                    perdas++;
                    if (doc.data().experimentou === true) {
                        impactosNegativos++;
                    }

                    if (lojasComMaisPerdas[empresaEncontrada.nomeFantasia] === undefined) {
                        lojasComMaisPerdas[empresaEncontrada.nomeFantasia] = 1;
                    } else {
                        lojasComMaisPerdas[empresaEncontrada.nomeFantasia] = lojasComMaisPerdas[empresaEncontrada.nomeFantasia] + 1;
                    }
                } else {
                    if (lojasComMaisVendas[empresaEncontrada.nomeFantasia] === undefined) {
                        lojasComMaisVendas[empresaEncontrada.nomeFantasia] = 1;
                    } else {
                        lojasComMaisVendas[empresaEncontrada.nomeFantasia] = lojasComMaisVendas[empresaEncontrada.nomeFantasia] + 1;
                    }
                }
            }
        });

        configurarGrafico(lojasComMaisPerdas, setLojasMaisPerdas, setOptLojasMaisPerdas, 5, true);
        configurarGrafico(lojasComMaisVendas, setLojasComMaisVendas, setOptLojasComMaisVendas, 5, true);


        const percentualImpactosNegativos = (impactosNegativos / perdas) * 100;
        const percentualString = percentualImpactosNegativos.toFixed(0) + "%";
        setImpactoNegativo(percentualString);

        const taxaConversao = totalAtendimentos !== 0 || perdas !== 0 ?
            (((totalAtendimentos - perdas) / totalAtendimentos) * 100).toFixed(2) : 0;

        const temposAtendimento = data.docs.map((doc) => {
            if (usuarioAutorizadoAVerEmpresa(doc.data().empresaId)) {
                const dataFinal = doc.data().dataFinal.toDate();
                const dataInicial = doc.data().dataInicial.toDate();
                if (dataInicial > dataFinal) return moment(dataInicial).diff(moment(dataFinal), 'minutes', true);
                return moment(dataFinal).diff(moment(dataInicial), 'minutes', true);
            } else {
                return 0;
            }
        });

        const mediaAtendimento = _.mean(temposAtendimento);
        const minutos = Math.floor(mediaAtendimento);
        const segundos = Math.round((mediaAtendimento % 1) * 60);

        let mediaFormatada = '';
        if (minutos >= 1) {
            mediaFormatada = `${minutos}`;
        } else {
            mediaFormatada = `0,${segundos}`;
        }

        const nomeVendedores = {};
        data.docs.forEach(doc => {
            if (usuarioAutorizadoAVerEmpresa(doc.data().empresaId)) {
                const vendedor = doc.data().vendedor;
                const nome = `${vendedor.apelido ? vendedor.apelido : vendedor.nome}`;
                nomeVendedores[nome] ? nomeVendedores[nome] += 1 : nomeVendedores[nome] = 1;
            }
        });

        const sorted = Object.entries(nomeVendedores).sort((a, b) => b[1] - a[1]);
        setRankingVendedores(sorted);
        configurarGrafico(nomeVendedores, setRanking, setOptRanking, 5, true);

        setMediaAtendimento(mediaFormatada);
        setAtendimentos(totalAtendimentos);
        setVendasPerdidas(perdas);
        setTaxaConversao(taxaConversao);
        contarMotivos(data);
        contarProdutos(data);
        setAtendimentosLoaded(true);
    }

    const callBackVendedores = (data) => {
        if (data.empty) {
            return;
        }

        const vendedores = data.docs.map(doc => doc.data());
        const contarVendedores = {
            "Dinsponíveis": 0,
            "Em Atendimento": 0,
            "Ausentes": 0,
        };

        vendedores.forEach(vendedor => {
            if (usuarioAutorizadoAVerEmpresa(vendedor.empresa.id)) {
                const NA_FILA = 1;
                const AUSENTE = 0;
                const EM_ATENDIMENTO = 'Em atendimento';

                if (vendedor.status.posicao === NA_FILA && vendedor.status.descricao !== EM_ATENDIMENTO) {
                    contarVendedores["Dinsponíveis"] += 1;
                } else if (vendedor.status.posicao === NA_FILA && vendedor.status.descricao === EM_ATENDIMENTO) {
                    contarVendedores["Em Atendimento"] += 1;
                } else if (vendedor.status.posicao !== NA_FILA || vendedor.status.descricao === AUSENTE) {
                    contarVendedores["Ausentes"] += 1;
                }
            }
        });

        setEmAtendimento(contarVendedores["Em Atendimento"]);
        configurarGrafico(contarVendedores, setVendedores, setOptVendedores);
    }

    const callBackEmpresas = (data) => {
        const empresas = [];
        data.docs.forEach(doc => {
            empresas.push({ id: doc.id, nomeFantasia: doc.data().nomeFantasia });
        });
        setEmpresas(empresas);
    }

    const callBackMotivos = (data) => {
        const itensDificuldade = {};
        motivos.forEach(motivo => {
            const item = data.docs.find(item => item.id === motivo.id);
            const dificuldade = item && item.data().dificuldade ? item.data().dificuldade : 'Não mapeada (veja ajuda)';
            itensDificuldade[dificuldade] = itensDificuldade[dificuldade] ? itensDificuldade[dificuldade] += motivo.quantidade : motivo.quantidade;

        });
        configurarGrafico(itensDificuldade, setDificuldadeSolucao, setOptDificuldadeSolucao);
    }

    const contarMotivos = (data) => {
        const contadorMotivos = {};
        const motivos = [];
        data.docs.forEach(doc => {
            if (doc.data().motivos) {

                if (usuarioAutorizadoAVerEmpresa(doc.data().empresaId)) {
                    doc.data().motivos.forEach(motivo => {
                        let itemMotivo = motivos.find(item => item.id === motivo.id);
                        if (!itemMotivo) {
                            itemMotivo = {
                                id: motivo.id,
                                nome: motivo.nome,
                                quantidade: 0,
                            };
                            motivos.push(itemMotivo);
                        }
                        itemMotivo.quantidade += 1;

                        if (contadorMotivos[motivo.nome]) {
                            contadorMotivos[motivo.nome] += 1;
                        } else {
                            contadorMotivos[motivo.nome] = 1;
                        }
                    });
                }
            }

        });

        setMotivos(motivos);
        configurarGrafico(contadorMotivos, setDataMotivos, setOptMotivos, MAXIMO_COLUNAS_GRAFICO);
    }

    const contarProdutos = (data) => {
        const contadorProdutosPerdidos = {};
        const contadorProdutosVendidos = {};
        data.docs.forEach(doc => {
            const item = doc.data();
            if (usuarioAutorizadoAVerEmpresa(item.empresaId)) {
                if (item.produtos) {
                    item.produtos.forEach(produto => {
                        if (!doc.data().vendeu) {

                            if (contadorProdutosPerdidos[produto.nome]) {
                                contadorProdutosPerdidos[produto.nome] += 1;
                            } else {
                                contadorProdutosPerdidos[produto.nome] = 1;
                            }
                        } else {
                            if (contadorProdutosVendidos[produto.nome]) {
                                contadorProdutosVendidos[produto.nome] += 1;
                            } else {
                                contadorProdutosVendidos[produto.nome] = 1;
                            }
                        }
                    });
                }
            }
        });

        configurarGrafico(contadorProdutosPerdidos, setProdutosPerdidos, setOptProdutosPerdidos, MAXIMO_COLUNAS_GRAFICO, false);
        configurarGrafico(contadorProdutosVendidos, setProdutosVendidos, setOptProdutosVendidos, MAXIMO_COLUNAS_GRAFICO, false);
    }


    useEffect(() => {

        if (empresasDoUsuario.length === 0 || empresas.length === 0) {
            return;
        }

        const gestorId = getGestorId();
        let unsubscribeAtendimentos;
        let unsubscribeVendedores;

        async function monitorarAtendimentos() {
            try {
                const hoje = moment().startOf('day');
                const dataInicial = hoje.clone();
                const dataFinal = hoje.clone().endOf('day');

                unsubscribeAtendimentos = await conversaoDB.monitorarAtendimentos(
                    dataInicial._d,
                    dataFinal._d,
                    // "VDH6URz3ocPfBy5Svql8hfRRp9k1",
                    gestorId,
                    callBackAtendimentos);

            } catch (error) {
                console.error(error);
            }
        }

        async function monitorarVendedores() {
            try {
                unsubscribeVendedores = await vendedorDB.monitorarVendedores(
                    // "VDH6URz3ocPfBy5Svql8hfRRp9k1",
                    gestorId,
                    callBackVendedores
                );
            }
            catch (error) {
                console.error(error);
            }
        }

        monitorarAtendimentos();
        monitorarVendedores();

        return () => {
            if (unsubscribeAtendimentos) {
                unsubscribeAtendimentos();
            }

            if (unsubscribeVendedores) {
                unsubscribeVendedores();
            }
        };
    }, [empresasDoUsuario, empresas]);

    useEffect(() => {

        if (!atendimentosLoaded) return;

        let unsubscribeMotivos;
        const gestorId = getGestorId();
        async function monitorarMotivos() {
            try {
                unsubscribeMotivos = await motivoDB.monitorarMotivos(
                    // "VDH6URz3ocPfBy5Svql8hfRRp9k1",
                    gestorId,
                    callBackMotivos
                );
            }
            catch (error) {
                console.error(error);
            }
        }
        monitorarMotivos();

        return () => {
            if (unsubscribeMotivos) {
                unsubscribeMotivos();
            }
        };

    }, [atendimentosLoaded]);

    useEffect(() => {
        const gestorId = getGestorId();
        async function monitorarEmpresas() {
            try {
                await empresaDB.monitorarEmpresas(
                    // "VDH6URz3ocPfBy5Svql8hfRRp9k1",
                    gestorId,
                    callBackEmpresas
                );
            }
            catch (error) {
                console.error(error);
            }
        }
        monitorarEmpresas();
    }, []);

    return (
        <div>
            <Tabs onChange={tabsChange} type="card">
                <TabPane tab="Todos" key="todas" />
                <TabPane tab="Atendimentos" key="atendimentos" />
                <TabPane tab="Produtos" key="produtos" />
                <TabPane tab="Vendedores" key="vendedores" />                
            </Tabs>
            <Row gutter={16}>
                <ColComponent
                    title={'Atendimentos'}
                    className='atendimentos-card-realizados'
                    value={atendimentos}
                    suffix={
                        <span>
                            realizados{' '}
                            <span style={{ fontSize: '0.6em' }}>
                                {emAtendimento > 0 ? `/ ${emAtendimento} em andamento` : ''}
                            </span>
                        </span>
                    }
                    span={SPAN}
                />

                <ColComponent
                    title={'Vendas Perdidas'}
                    className='atendimentos-card-vendas-perdidas'
                    value={vendasPerdidas}
                    suffix={

                        <span>
                            perdas{' '}
                            <span style={{ fontSize: '0.6em' }}>
                                {`/ Impacto Negativo: ${impactoNegativo}`}
                            </span>
                        </span>


                    }
                    span={SPAN}
                />

                <ColComponent
                    title={'Taxa de Conversão'}
                    className='atendimentos-card-taxa-convesao'
                    value={taxaConversao}
                    suffix="%"
                    span={SPAN}
                />

                <ColComponent
                    title={'Média de Tempo de Atendimento'}
                    className='atendimentos-card-media-atendimento'
                    value={mediaAtendimento}
                    suffix={'min'}
                    span={SPAN}
                />

                {tabAtiva === 'todas' || tabAtiva === 'atendimentos' ? (

                    <Col span={SPAN_GRAFICOS}>
                        <Card className='atendimentos-card'>
                            <div className="chart-container">

                                <div className='help-button-container'>
                                    <button className='help-button' onClick={() => exibirAjuda('Maiores Motivos de Perdas')}>
                                        <Icon type="question-circle" />
                                    </button>
                                </div>

                                {dataMotivos && dataMotivos.labels && dataMotivos.labels.length > 0 ? (
                                    <div>
                                        <span className='title-container'>Maiores Motivos de <strong>Perdas</strong></span>
                                        <Doughnut data={dataMotivos} options={optMotivos} />
                                    </div>

                                ) : (
                                    <div>
                                        <span className='title-container'>Maiores Motivos de <strong>Perdas</strong></span>
                                        <br></br>
                                        <br></br>
                                        <span>Não há dados para informar </span>
                                    </div>)
                                }
                            </div>
                        </Card>
                    </Col>
                ) : null}

                {tabAtiva === 'todas' || tabAtiva === 'atendimentos' ? (
                    <Col span={SPAN_GRAFICOS}>
                        <Card className='atendimentos-card'>
                            <div className="chart-container">

                                <div className='help-button-container'>
                                    <button className='help-button' onClick={() => exibirAjuda('Dificuldade de Solução')}>
                                        <Icon type="question-circle" />
                                    </button>
                                </div>


                                {dificuldadeSolucao && dificuldadeSolucao.labels && dificuldadeSolucao.labels.length > 0 ? (
                                    <div>
                                        <span className='title-container'>Dificuldade de <strong>Solução</strong></span>
                                        <Doughnut data={dificuldadeSolucao} options={optDificuldadeSolucao} />
                                    </div>

                                ) : (
                                    <div>
                                        <span className='title-container'>Dificuldade de <strong>Solução</strong></span>
                                        <br></br>
                                        <br></br>
                                        <span>Não há dados para informar </span>
                                    </div>)
                                }
                            </div>
                        </Card>
                    </Col>
                ) : null}

                {tabAtiva === 'todas' || tabAtiva === 'produtos' ? (
                    <Col span={SPAN_GRAFICOS}>
                        <Card className='atendimentos-card'>
                            <div className="chart-container">

                                <div className='help-button-container'>
                                    <button className='help-button' onClick={() => exibirAjuda('Produtos mais Perdidos')}>
                                        <Icon type="question-circle" />
                                    </button>
                                </div>


                                {dataProdutosPerdidos && dataProdutosPerdidos.labels && dataProdutosPerdidos.labels.length > 0 ? (
                                    <div>
                                        <span className='title-container'>Produtos <strong>mais perdidos</strong> </span>
                                        <Bar data={dataProdutosPerdidos} options={optProdutosPerdidos} />
                                    </div>

                                ) : (
                                    <div>
                                        <span className='title-container'>Produtos <strong>mais perdidos</strong> </span>
                                        <br></br>
                                        <br></br>
                                        <span>Não há dados para informar </span>
                                    </div>)

                                }
                            </div>
                        </Card>
                    </Col>
                ) : null}

                {tabAtiva === 'todas' || tabAtiva === 'produtos' ? (

                    <Col span={SPAN_GRAFICOS}>
                        <Card className='atendimentos-card'>
                            <div className="chart-container">

                                <div className='help-button-container'>
                                    <button className='help-button' onClick={() => exibirAjuda('Produtos mais Vendidos')}>
                                        <Icon type="question-circle" />
                                    </button>
                                </div>


                                {dataProdutosVendidos && dataProdutosVendidos.labels && dataProdutosVendidos.labels.length > 0 ? (
                                    <div>
                                        <span className='title-container'>Produtos <strong>mais vendidos</strong> </span>
                                        <HorizontalBar data={dataProdutosVendidos} options={optProdutosVendidos} />
                                    </div>

                                ) : (
                                    <div>
                                        <span className='title-container'>Produtos <strong>mais vendidos</strong> </span>
                                        <br></br>
                                        <br></br>
                                        <span>Não há dados para informar </span>
                                    </div>)

                                }
                            </div>
                        </Card>
                    </Col>
                ) : null}

                {tabAtiva === 'todas' || tabAtiva === 'atendimentos' ? (
                    <Col span={SPAN_GRAFICOS}>
                        <Card className='atendimentos-card'>
                            <div className="chart-container">

                                <div className='help-button-container'>
                                    <button className='help-button' onClick={() => exibirAjuda('Lojas com mais Perdas')}>
                                        <Icon type="question-circle" />
                                    </button>
                                </div>


                                {dataLojasMaisPerdas && dataLojasMaisPerdas.labels && dataLojasMaisPerdas.labels.length > 0 ? (
                                    <div>
                                        <span className='title-container'>Lojas com mais <strong>Perdas</strong></span>
                                        <Doughnut data={dataLojasMaisPerdas} options={optLojasMaisPerdas} />
                                    </div>

                                ) : (
                                    <div>
                                        <span className='title-container'>Lojas com mais <strong>Perdas</strong></span>
                                        <br></br>
                                        <br></br>
                                        <span>Não há dados para informar </span>
                                    </div>)

                                }
                            </div>
                        </Card>
                    </Col>
                ) : null}

                {tabAtiva === 'todas' || tabAtiva === 'atendimentos' ? (

                    <Col span={SPAN_GRAFICOS}>
                        <Card className='atendimentos-card'>
                            <div className="chart-container">

                                <div className='help-button-container'>
                                    <button className='help-button' onClick={() => exibirAjuda('Lojas com mais Vendas')}>
                                        <Icon type="question-circle" />
                                    </button>
                                </div>


                                {dataLojasMaisPerdas && dataLojasMaisPerdas.labels && dataLojasMaisPerdas.labels.length > 0 ? (
                                    <div>
                                        <span className='title-container'>Lojas com mais <strong>Vendas</strong></span>
                                        <Doughnut data={dataLojasComMaisVendas} options={optLojasComMaisVendas} />
                                    </div>

                                ) : (
                                    <div>
                                        <span className='title-container'>Lojas com mais <strong>Vendas</strong></span>
                                        <br></br>
                                        <br></br>
                                        <span>Não há dados para informar </span>
                                    </div>)

                                }
                            </div>
                        </Card>
                    </Col>
                ) : null}


                {tabAtiva === 'todas' || tabAtiva === 'vendedores' ? (

                    <Col span={SPAN_GRAFICOS}>
                        <Card className='atendimentos-card'>
                            <div className="chart-container">

                                <div className='help-button-container'>
                                    <button className='help-button' onClick={() => exibirAjuda('Movimentação dos Vendedores')}>
                                        <Icon type="question-circle" />
                                    </button>
                                </div>


                                {dataVendedores && dataVendedores.labels && dataVendedores.labels.length > 0 ? (
                                    <div>
                                        <span className='title-container'>Movimentação dos <strong>Vendedores</strong></span>
                                        <Pie data={dataVendedores} options={optVendedores} />
                                    </div>

                                ) : (
                                    <div>
                                        <span className='title-container'>Movimentação dos <strong>Vendedores</strong></span>
                                        <br></br>
                                        <br></br>
                                        <span>Não há dados para informar </span>
                                    </div>)

                                }
                            </div>
                        </Card>
                    </Col>
                ) : null}

                {tabAtiva === 'todas' || tabAtiva === 'vendedores' ? (

                    <Col span={SPAN_GRAFICOS}>
                        <Card className='atendimentos-card'>
                            <div className="chart-container">

                                <div className='help-button-container'>
                                    <button className='help-button' onClick={() => exibirAjuda('Ranking de Atendimento')}>
                                        <Icon type="question-circle" />
                                    </button>
                                </div>


                                {dataRanking && dataRanking.labels && dataRanking.labels.length > 0 ? (
                                    <div>
                                        <span className='title-container'>Ranking de atendimentos de <strong>Vendedores</strong></span>
                                        <Pie data={dataRanking} options={optRanking} />
                                    </div>

                                ) : (
                                    <div>
                                        <span className='title-container'>Ranking de atendimentos de <strong>Vendedores</strong></span>
                                        <br></br>
                                        <br></br>
                                        <span>Não há dados para informar </span>
                                    </div>)

                                }
                            </div>
                        </Card>
                    </Col>
                ) : null}
            </Row>
        </div>
    );
}

export default RealTime;