import ls from 'local-storage'
import bluveStorage from '../localstorage/localstorage';


export function authenticate(user, userType, gestorId, gerenteId, isAdmin) {
  ls.set('user', user);
  ls.set('bluve-user-admin', isAdmin);

  if (userType) {
    ls.set('bluve-user-type', userType);
  }

  if (gestorId) {
    ls.set('bluve-user-gestorId', gestorId);
  }

  if (gerenteId) {
    ls.set('bluve-user-gerenteId', gerenteId);
  }
}

export function logout() {
  ls.set('user', null);
  ls.set('bluve-user-type', null);
  ls.set('bluve-user-gestorId', null);
  ls.set('bluve-user-gerenteId', null);
  ls.set('dashboardChart/date', null);
  ls.set('dashboardChart/result', null);
  ls.set('bluve-user-admin', null);
  bluveStorage.clearAll();
  ls.clear();
}

export function currentUser() {
  const user = ls.get('user');
  return user;
}

export function isLoggedIn() {
  const user = currentUser();
  return user !== null && user !== undefined;
}

export function getGestorId() {
  const user = currentUser();
  const userType = getUserType();
  if (userType === 'Gestor') {
    return user.uid;
  }
  const gestorId = ls.get('bluve-user-gestorId');
  return gestorId;
}

export function getUserType() {
  return ls.get('bluve-user-type');
}

export function isAdminUser() {
  const isAdmin = ls.get('bluve-user-admin');
  return isAdmin === true;
}

export function getGerenteId() {
  return ls.get('bluve-user-gerenteId');
}
