import axios from './axios.js';
import jwt from 'jsonwebtoken';
import { EXPIRATION_TIME, JWT_KEY } from '../../components/constants.js';


export default class API {
    async saveHistorico(value, app) {
        const route = '/historico';

        const params = {
            value,
            app
        };

        const response = await request(
            'post',
            route,
            params
        );

        return response;
    }

    async getHistorico(value) {
        const route = `/historico/${value.replace(/[^\d]+/g, '')}`;

        const response = await request(
            'get',
            route
        );

        return response;
    }

    async getAddresses(id) {
        const route = `/historico/addresses/${id}`;

        const response = await request(
            'get',
            route
        );

        return response;
    }

    async getCensoredEmail(id) {
        const route = `/historico/censored-email/${id}`;

        const response = await request(
            'get',
            route
        );

        return response;
    }

    async validateAddress(endereco, id) {
        const route = `/validate/address/${id}`;

        const params = {
            endereco
        };

        const response = await request(
            'post',
            route,
            params
        );

        return response;
    }

    async validateEmail(email, id) {
        const route = `/validate/email/${id}`;

        const params = {
            email
        };

        const response = await request(
            'post',
            route,
            params
        );

        return response;
    }

    async validateAccessToken(token, id) {
        const route = `/validate/token/${token}/${id}`;

        const response = await request(
            'post',
            route
        );

        return response;
    }

    async createPassword(password, id, app) {
        const route = `/createPassword/${id}`;

        const params = {
            app,
            password
        };

        const response = await request(
            'post',
            route,
            params
        );

        return response;
    }

    async sendAccessToken(email, app, produto) {
        const route = '/access-token';

        const params = {
            email,
            app,
            produto
        };

        const response = await request(
            'post',
            route,
            params
        );

        return response;
    }

    async sendEmailRegistrarion(empresa, email, produto, link) {
        const route = '/email-registration';

        const params = {
            empresa,
            email,
            produto,
            link
        };

        const response = await request(
            'post',
            route,
            params
        );

        return response;
    }
}

function request(method, route, params) {
    return new Promise(async (resolve, reject) => {
        let response;

        try {
            axios.defaults.headers['authorization'] = `bearer ${await generateToken()}`;

            response = method === 'get' ?
                await axios[method](route, { params: { ...params } }) :
                await axios[method](route, { ...params });

            resolve(response.data);
        } catch (err) {
            const {
                error,
                message
            } = err.response ? err.response.data : err;

            console.log(error ?? message);

            reject(message);
        }
    });
}

async function generateToken() {
    const token = jwt.sign({}, JWT_KEY, {
        expiresIn: EXPIRATION_TIME
    });

    return token;
}