import React, { Component } from 'react';
import InputMask from 'react-input-mask';


class CNPJInput extends Component {
  render() {
    return (
      <InputMask
        {...this.props}
        mask='99.999.999/9999-99'
      />
    )
  }
}


export default CNPJInput;
