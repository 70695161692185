import React, { Component } from "react";
import { withRouter } from "react-router";
import "./Dashboard.css";
import BluveLayout from "../../components/layout/BluveLayout";
import conversaoDB from "../../dataManager/dtmConversao";
import usuarioDB from "../../dataManager/dtmUsuario";
import bluveStorage from "../../components/localstorage/localstorage";
import {
  getUserType,
  getGerenteId,
  getGestorId,
} from "../../components/auth/auth-provider";
import { GESTOR } from "../../components/funcs/constants";
import gerenteDB from "../../dataManager/dtmGerente";
import { Row, Modal, Tabs, message } from "antd";
import { DiarioAtendimento } from "../../components";
import moment from "moment";
import AlterarSenhaCRUD from '../../components/cruds/alterar_senha/AlterarSenhaCRUD';
import { currentUser } from "components/auth/auth-provider";
import { updatePassword } from '../perfil/Funcs-Perfil';
import { PASSWORD_UPDATED } from "components/funcs/constants";
import RealTime from "components/DashboardV2/RealTime/RealTime";


class DashBoard extends Component {
  state = {
    loading: true,
    nomeComparacao: "Dia anterior",
    perdas: 0,
    atendimentos: 0,
    percConversao: 0,
    isMounted: false
  };

  constructor(props) {
    super(props);
    document.title = `Bluve`;
    this.buildReport = this.calcular.bind(this);
    const inicio = moment();
    const fim = moment().subtract('30', 'days');
  }

  async componentDidMount() {
    // await gerenteDB.getTeste();
    // const data = await this.calcular();

    // const percConversao =
    //   data.atendimentos > 0 || data.perdas > 0
    //     ? (
    //       ((data.atendimentos - data.perdas) / data.atendimentos) *
    //       100
    //     ).toFixed(2)
    //     : 0;

    const periodo = {
      inicial: moment(),
      final: moment().subtract('07', 'days')
    }

    this.setState({
      // perdas: data.perdas,
      // atendimentos: data.atendimentos,
      // percConversao,
      loading: false,
      periodo
    });
  }

  async calcular() {
    return;
    const start = new Date();
    start.setDate(start.getDate() - 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date();
    end.setDate(end.getDate() - 1);
    end.setHours(23, 59, 59, 59);

    let data = undefined;
    const cacheDate = bluveStorage.get("@dashboard/date");

    let gestorId = undefined;
    let empresasIds = undefined;

    if (
      !cacheDate ||
      start.toDateString() !== new Date(cacheDate).toDateString()
    ) {
      if (getUserType() === GESTOR) {
        gestorId = getGestorId();
      } else {
        const gerenteId = getGerenteId();
        const empresas = await gerenteDB.getEmpresasGerente(
          gerenteId,
          "razaoSocial"
        );
        empresasIds = empresas.map((empresa) => {
          return empresa.id;
        });
      }

      data = await conversaoDB.countAtendimentos(
        start,
        end,
        gestorId,
        empresasIds
      );
      bluveStorage.set("@dashboard/date", start);
      bluveStorage.set("@dashboard/data", JSON.stringify(data));
    } else {
      data = JSON.parse(bluveStorage.get("@dashboard/data"));
    }
    return data;
  }

  render() {
    const periodo = {
      inicial: moment().subtract('32', 'days'),
      final: moment().subtract('1', 'day')
    }
    const empresaId = 'rede';

    return (
      <div>
        <BluveLayout selectItem={"dashboard"}>
          <AlterarSenha></AlterarSenha>
          <RealTime />
        </BluveLayout>
      </div>
    );
  }
}

class AlterarSenha extends Component {
  state = {
    visible: false,
    confirmLoading: false,
    usuario: undefined,
    password: '',
  }

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
  }

  async componentDidMount() {
    let visible = false;
    let usuario = undefined;

    const cachePassword = bluveStorage.get(PASSWORD_UPDATED);
    if (!Boolean(cachePassword)) {
      const user = currentUser();
      usuario = await usuarioDB.getByUserId(user.uid);
      visible = !usuario || !usuario.lastPasswordUpdate;

      if (!visible) {
        bluveStorage.set(PASSWORD_UPDATED, true);
      }
    }

    this.setState({ visible, usuario });
  }

  async handleOk(password) {
    const failMessage = 'Erro ao tentar salvar a nova senha. Tente novamente';
    const isOk = await updatePassword(password);
    if (!isOk) {
      message.error(failMessage);
      return;
    }

    const usuario = this.state.usuario;
    usuario.lastPasswordUpdate = new Date();
    const res = await usuarioDB.update(usuario.key, usuario);
    if (!res.updated) {
      message.error(failMessage);
      return;
    }

    bluveStorage.set(PASSWORD_UPDATED, true);
    this.setState({ visible: false });
  }

  render() {
    return (
      <Modal
        title='Nova senha necessária para continuar'
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        footer={null}
        closable={false}>

        <AlterarSenhaCRUD handleOk={this.handleOk}></AlterarSenhaCRUD>
      </Modal>
    )
  }
}

export default withRouter(DashBoard);
