import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button } from 'antd';


class ConfirmarEndereco extends Component {
  state = {
    enderecos: [],
    enderecoSelecionado: undefined
  }

  constructor(props) {
    super(props);

    this.kba = this.props.kba;
    this.historicoId = this.props.historicoId;
    this.onClickButton = this.props.onClickButton;
    this._enderecos = this.props.enderecos;

    this.handleConfirm = this.handleConfirm.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  async componentDidMount() {
    const enderecos = this._enderecos.map((item) => ({
      endereco: item,
      selected: false
    }));

    this.setState({ enderecos });
  }

  async handleConfirm() {
    return new Promise(async (resolve, reject) => {
      const { enderecoSelecionado } = this.state;

      if (!enderecoSelecionado) {
        reject({
          type: 'error',
          title: 'Nenhum Endereço Selecionado',
          description: 'Selecione uma das opções apresentadas.'
        });

        return;
      }

      try {
        const {
          valid,
          tentativas
        } = await this.kba.validateAddress(
          enderecoSelecionado,
          this.historicoId
        );

        if (valid) {
          resolve({
            message: 'Endereço confirmado.'
          });
        } else {
          reject({
            tentativas,
            type: 'error',
            title: 'Informação incorreta',
            description: 'O endereço selecionado não corresponde ao informado no cadastro.'
          })
        }
      } catch (error) {
        reject({
          type: 'error',
          title: 'Erro',
          description: error.message
        })
      }
    })
  }

  onSelect(index) {
    const { enderecos } = this.state;
    let enderecoSelecionado;

    enderecos.forEach((item, i) => {
      if (index === i) {
        enderecos[i].selected = true;
        enderecoSelecionado = enderecos[i].endereco;
      } else {
        enderecos[i].selected = false;
      }
    });

    this.setState({ enderecos, enderecoSelecionado });
  }

  render() {
    const { enderecos } = this.state;
    const enderecosSelect = [];

    enderecos.forEach((item, index) => {
      enderecosSelect.push(
        // <div
        //   key={index}
        //   className={this.state.enderecos[index].selected ? 'selectEndereco selected' : 'selectEndereco'}
        //   onClick={() => this.onSelect(index)}
        // >
        //   {item.endereco}
        // </div>
        <Button
          key={index}
          className='botaoSelecionar'
          onClick={() => this.onSelect(index)}
          type={this.state.enderecos[index].selected ? 'primary' : 'default'}
        >
          {item.endereco}
        </Button>
      );
    })

    return (
      <div className='stepContainer'>
        <div className='textContainer'>
          <div className='title'>
            Confirme o endereço
          </div>

          <div className='description'>
            Pronto! Localizamos o seu cadastro.
          </div>

          <div className='description bold'>
            Selecione o endereço correto da sua empresa.
          </div>
        </div>

        <div className='enderecosContainer'>
          {enderecosSelect}
        </div>

        <Button
          className='buttonConfirm'
          type='primary'

          loading={this.props.loading}
          onClick={() => this.onClickButton(this.handleConfirm)}
        >
          Próximo
        </Button>
      </div>
    )
  }
}


export default withRouter(ConfirmarEndereco);
