import React from 'react';
import "antd/dist/antd.css";
import './Perfil.css';
import BluveLayout from '../../components/layout/BluveLayout';
import {
  Modal,
  Form,
  Input,
  Tooltip,
  Icon,
  Button,
  Divider,
  notification,
} from 'antd';
import history from '../../history';
import { currentUser, authenticate, getUserType } from '../../components/auth/auth-provider';
import { fireBase } from '../../firebase/firebase';
import { validateUser, uploadAvatar, updateProfile, updatePassword, updateEmail } from './Funcs-Perfil';
import gerenteDB from '../../dataManager/dtmGerente';
import PasswordStrengthBar from 'react-password-strength-bar';
import { DEFAULT_ATATAR } from 'components/funcs/constants';
import API from 'services/api/api';

const { confirm } = Modal
const api = new API();

const openNotificationWithIcon = (type, title, description, duration) => {
  if (!duration) {
    duration = 4; // this is the default time
  }
  notification[type]({
    message: [title],
    description: [description],
    duration: duration,
  });
};


class Perfil extends React.Component {

  state = {
    bloqueiaBotaoSalvar: false,
    photoURL: DEFAULT_ATATAR,
    loading: false,
    loadingButton: false,
    data: {
      nome: '',
      /* ---- ALTERAÇÃO DE EMAIL ----*/
      email: '',
      /*--------*/
      oldPassword: '',
      passwordStrength: '',
      confirmPassword: '',
      score: undefined,
    },
    edited: false,
    pictures: [],
    help: '',
    validateStatus: '',
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.getBase64 = this.getBase64.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.btnUploadClick = this.btnUploadClick.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.oldPasswordChanged = this.oldPasswordChanged.bind(this);
    this.scoreCheck = this.scoreCheck.bind(this);
    this.confirmChanged = this.confirmChanged.bind(this);
    this.estadoBotao = this.estadoBotao.bind(this);
  }

  componentDidMount() {
    const user = currentUser();
    this.setState({
      data: {
        nome: user.displayName,
        email: user.email
      },
      photoURL: user.photoURL ? user.photoURL : DEFAULT_ATATAR,
    });
  }

  cancelClick() {
    history.push('/home');
    history.go();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }

      if (!values.nome) {
        openNotificationWithIcon('error', 'Cadastro incompleto', 'Informe o seu nome');
        return;
      }

      /* ---- ALTERAÇÃO DE EMAIL ----*/
      if (!values.email) {
        openNotificationWithIcon('error', 'Cadastro incompleto', 'Informe o seu e-mail');
      }

      if (values.email !== this.state.data.email) {
        const emailGestor =
          (await fireBase.auth().fetchSignInMethodsForEmail(values.email))
            .length > 0;
        if (emailGestor) {
          openNotificationWithIcon(
            "error",
            "Email inválido",
            "O email informado já está cadastrado."
          );
          return;
        }
      }

      if (values.newPassword && values.newPassword.length < 6) {
        openNotificationWithIcon('error', 'Senha inválida', 'Sua nova senha precisa ter no mínimo 6 caracteres');
        return;
      }

      if ((!this.state.edited) &&
        (values.nome === this.state.data.nome) &&
        (!values.newPassword) /* ALTERAÇÃO DE EMAIL -- */ && (values.email === this.state.data.email)/**/) {
        openNotificationWithIcon('warning', 'Aviso', 'Nenhuma alteração a ser salva');
        return;
      }

      this.setState({ loadingButton: true });
      let isOk = await validateUser(values.password);
      if (!isOk) {
        openNotificationWithIcon('error', 'Senha incorreta', 'Para alterar o seu cadastro, você precisa informar corretamente qual é a sua senha');
        this.setState({ loadingButton: false });
        return;
      }

      let imagePath = this.state.photoURL;
      if (this.state.edited) {
        const res = await uploadAvatar(this.state.photoURL);
        if (res.success) {
          imagePath = res.url;
        }
        else {
          openNotificationWithIcon('error', 'Erro', 'Erro ao tentar salvar sua foto de perfil. Tente novamente');
          this.setState({ loadingButton: false });
          return;
        }
      }
      const user = fireBase.auth().currentUser;
      if (user.displayName !== values.nome || user.photoURL !== imagePath) {
        // Atualizo o perfil do usuário
        isOk = await updateProfile(values.nome, imagePath);
        if (!isOk) {
          openNotificationWithIcon('error', 'Erro', 'Erro ao salvar seus dados de perfil. Tente novamente');
          this.setState({ loadingButton: false });
          return;
        }
      }

      if (values.newPassword) {
        isOk = await updatePassword(values.newPassword);
        if (!isOk) {
          openNotificationWithIcon('error', 'Erro', 'Erro ao alterar sua senha. Tente novamente');
          this.setState({ loadingButton: false });
          return;
        }
      }

      const isChanged = user.email !== values.email;

      const confirmou = !isChanged || await this.showConfirmationModal(values.email);

      if (!confirmou) {
        this.setState({ loadingButton: false });
        openNotificationWithIcon('warning', 'Verifique o email do gestor');
        return;
      }
      if (isChanged) {
        openNotificationWithIcon('warning', 'Aguarde, estamos alterando o email...');
        await updateEmail(user.email, values.email);
        const userType = getUserType();
        if (getUserType() === 'Gerente') {
          const gerente = await gerenteDB.getByEmail(user.email);
          const item = gerente[0];
          item.email = values.email;
          gerenteDB.update(item.key, item);
        }
      }



      /*--------*/

      if (isOk) {
        const nuser = fireBase.auth().currentUser;

        authenticate(nuser);
        openNotificationWithIcon('success', 'Perfil salvo com sucesso');

        await api.accessControl(nuser.uid, nuser.refreshToken)
          .then(() => {
            setTimeout(() => {
              history.push('/dashboard');
              history.go();
            }, 1500);
          });
      }
    });
  }

  /* ---- ALTERAÇÃO DE EMAIL ----*/

  showConfirmationModal(email) {
    return new Promise(resolve => {
      confirm({
        title: 'Alteração de email',
        icon: <Icon type="exclamation" style={{ fontSize: '16px', color: '#08c' }} theme="outlined" />,
        content: 'Confirma alteração do email para: ' + email + '?',
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        }
      });
    });
  }

  /*--------*/

  btnUploadClick() {
    this.refs.myFile.click();
  }

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }


  handleChange(e) {
    this.getBase64(e.target.files[0], photoURL => {
      this.setState({
        photoURL,
        loading: false,
        edited: true,
      })
    }
    );
  }

  onDrop(picture) {
    this.setState({
      pictures: this.state.pictures.concat(picture),
    });
  }

  oldPasswordChanged(obj) {
    const data = this.state.data;
    data.oldPassword = obj.target.value;
    this.setState({ data });
    this.estadoBotao();
  }

  passwordChanged(obj) {
    const data = this.state.data;
    data.passwordStrength = obj.target.value;
    this.setState({ data });
    this.estadoBotao();
  }

  confirmChanged(obj) {
    const data = this.state.data;
    data.confirmPassword = obj.target.value;
    this.setState({ data });
    this.estadoBotao();
  }

  scoreCheck(score) {
    const data = this.state.data;
    data.score = score;
    this.setState({ data })
  }

  estadoBotao() {
    const password = this.state.data.oldPassword;
    const newPassword = this.state.data.passwordStrength;
    const confirmPassword = this.state.data.confirmPassword;
    const score = this.state.data.score;
    let botao = false;

    this.setState({ help: this.props.form.message, validateStatus: 'success' });
    if ((newPassword && score <= 1) || score >= 2) {
      botao = true;
      if (password && password !== newPassword && newPassword === confirmPassword) botao = false;
      else if (newPassword !== confirmPassword || (newPassword === password && password)) {
        this.setState({ help: 'Senhas não conferem', validateStatus: 'error' });
        if (newPassword === password && password) {
          this.setState({ help: 'Nova senha não pode ser igual a atual', validateStatus: 'error' });
        }
      }
    }
    this.setState({ bloqueiaBotaoSalvar: botao })
  }

  render() {

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 34,
          offset: 0,
        },
        sm: {
          span: 34,
          offset: 12,
        },
      },
    };

    const { photoURL } = this.state;

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Aguardando Foto</div>
      </div>
    );

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <BluveLayout selectItem={'perfil'}>
          <div>

            <div className="clearfix avatarPerfilImgBckground">
              <input
                className="uploadComponent"
                type="file"
                name="myFile"
                ref="myFile"
                onChange={this.handleChange}
              />
              {photoURL ? (
                <div>
                  <img
                    src={photoURL}
                    alt="avatar"
                    className="avatarPerfilImg"
                    onClick={this.btnUploadClick}
                  />
                  <Icon
                    type="camera"
                    className="avatarPerfilIcon"
                    onClick={this.btnUploadClick}
                  />
                </div>
              ) : (
                uploadButton
              )}
            </div>



            <div className="div-form-profile">
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>

                <Form.Item
                  label={
                    <span>
                      Nome&nbsp;
                      <Tooltip title="Qual é o seu nome?">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('nome', {
                    initialValue: this.state.data.nome,
                    rules: [{ required: true, message: 'Informe o seu nome', whitespace: true }],
                  })(<Input maxLength={50} />)}
                </Form.Item>

                {/* ---- ALTERAÇÃO DE EMAIL ----*/}

                <Form.Item
                  label={
                    <span>
                      Email&nbsp;
                      <Tooltip title="Qual é o seu e-mail?">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('email', {
                    initialValue: this.state.data.email,
                    rules: [{ required: true, message: 'Informe o seu e-mail', whitespace: true }],
                  })(<Input maxLength={50} />)}
                </Form.Item>

                {/*--------*/}

                <Form.Item
                  label={
                    <span>
                      Senha&nbsp;
                      <Tooltip title="Qual é a sua senha atual?">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Informe a sua senha atual', whitespace: true }],
                  })(<Input.Password onChange={this.oldPasswordChanged} style={{ width: '95%' }} />)}
                </Form.Item>

                <Form.Item label="Nova Senha">
                  {getFieldDecorator('newPassword', {
                    rules: [{ required: false, message: 'Informe uma nova senha' }],
                  })(<Input.Password onChange={this.passwordChanged} style={{ width: '95%' }} />)}

                  <PasswordStrengthBar
                    className='passwordStrength'
                    password={this.state.data.passwordStrength}
                    scoreWords={['Senha muito fraca', 'Senha fraca', 'Senha razoável', 'Senha boa', 'Senha forte']}
                    shortScoreWord={this.state.data.passwordStrength ? 'Senha muito curta' : ''}
                    minLength={6}
                    onChangeScore={(score) => this.scoreCheck(score)}
                    style={{ width: '95%' }} />
                </Form.Item>


                <Form.Item label="Confirme"
                  help={this.state.help}
                  validateStatus={this.state.validateStatus}>

                  {getFieldDecorator('confirmNewPassword', {
                    rules: [{ required: false, message: 'Confirme sua nova Senha' }],
                  })(<Input.Password onChange={this.confirmChanged} style={{ width: '95%' }} />)}
                </Form.Item>

                <Divider></Divider>


                <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit" loading={this.state.loadingButton} disabled={this.state.bloqueiaBotaoSalvar}>
                    Salvar
                  </Button>
                  <Divider type="vertical" />
                  <Button onClick={this.cancelClick}>
                    Cancelar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </BluveLayout>
      </div>
    );
  }
}

export default Form.create()(Perfil);
