import {
  getGestorId,
  getUserType,
  getGerenteId,
} from "../../../components/auth/auth-provider";
import empresaDB from "../../../dataManager/dtmEmpresa";
import { GERENTE } from "../../../components/funcs/constants";
import gerenteDB from "../../../dataManager/dtmGerente";
import conversaoDB from "../../../dataManager/dtmConversao";
import motivoDB from "../../../dataManager/dtmMotivo";
import { salvarXlsx } from "../metas/Funcs-Metas";


let listaDeMotivos = undefined;

async function incrementItem(list, value) {
  list.forEach((item) => {
    if (item.id === value) {
      item.total++;
    }
  });
}

export function getNextColor(color) {
  if (color === "#fff") {
    color = "#eee";
  } else {
    color = "#fff";
  }
  return color;
}

export async function getEmpresas() {
  const gestorId = getGestorId();
  const empresas = await empresaDB.getByGestorIdCustom(gestorId, 'login.login');
  const newEmpresas = [];
  const userType = getUserType();

  if (userType === GERENTE) {
    const gerenteId = getGerenteId();
    const empresasGerente = await gerenteDB.getEmpresasGerente(gerenteId, 'razaoSocial');

    for (let i = 0; i < empresasGerente.length; i++) {
      const empresa = empresas.filter((item) => {
        return item.key === empresasGerente[i].id;
      });

      if (empresa && empresa.length > 0) {
        newEmpresas.push(empresa[0]);
      }
    }
  }
  return newEmpresas.length > 0 ? newEmpresas : empresas;
}


export async function buildReport(id, startDate, endDate, rede, gerente, empresas) {
  const gestorId = getGestorId();
  const data1 = startDate._d ? startDate._d : startDate;
  const data2 = endDate._d ? endDate._d : endDate;

  const start = new Date(data1.setHours(0, 0, 0, 0));
  const end = new Date(data2.setHours(23, 59, 59, 59));

  let conversoesSnapshot;
  if (!rede) {
    conversoesSnapshot = await conversaoDB.getVendasPerdidasByPeriodo(start, end, id);
  } else {
    if (gerente && empresas.length > 0) {
      conversoesSnapshot = await conversaoDB.getVendasPerdidasByPeriodoAndEmpresas(start, end, empresas);
    }
    else {
      conversoesSnapshot = await conversaoDB.getVendasPerdidasByPeriodo(start, end);
    }

  }

  const motivosConversoes = [];
  let totalPerdas = 0;
  let color = "#fff";

  conversoesSnapshot.forEach(async (item) => {
    if ((rede && item.empresaId !== id) || !rede) {
      totalPerdas += 1;

      if (item.motivos !== undefined && item.motivos.length > 0) {

        item.motivos.forEach(async (motivo) => {
          const motivoNaLista = motivosConversoes.some(
            (motivoItem) => motivoItem.id === motivo.id
          );

          if (motivoNaLista) {
            await incrementItem(motivosConversoes, motivo.id);
          } else {
            motivosConversoes.push({
              ...motivo,
              percentual: 0,
              total: 1,
              color,
              corMotivo: "",
            });

            color = getNextColor(color);
          }
        });
      }
    }
  });

  if (!listaDeMotivos || listaDeMotivos.length === 0) {
    listaDeMotivos = await motivoDB.getByGestorId(gestorId);
  }

  // Calculo os percentuais e alimento as cores
  motivosConversoes.forEach(async (item) => {
    const percentual = ((item.total / totalPerdas) * 100).toFixed(2) + "%";
    item.percentual = percentual;
    const motivo = listaDeMotivos.find((itemLista) => itemLista.key === item.id);
    if (motivo) {
      item.corMotivo = motivo.color;
    }
  });

  motivosConversoes.push({
    id: "total",
    nome: "Total",
    total: totalPerdas,
    percentual: "100%",
    color,
  });

  return motivosConversoes;
}

export async function exportToExcel(data, empresa, periodo, comparacao, maiorOcorrencia) {
  const dataInicial = periodo.inicial.toDate().toLocaleDateString();
  const dataFinal = periodo.final.toDate().toLocaleDateString();

  const infoExcel = [];
  infoExcel.push([`Período: ${dataInicial} - ${dataFinal}`]);
  infoExcel.push([`Empresa: ${empresa.razaoSocial}`]);
  infoExcel.push([`Comparando com: ${comparacao}`]);
  infoExcel.push([`Maior Ocorrência: ${maiorOcorrencia.percentual} - ${maiorOcorrencia.nome}`]);
  infoExcel.push(['']);

  infoExcel.push([
    'Motivo',
    'Total',
    'Percentual'
  ]);

  data.forEach((item) => {
    infoExcel.push([
      item.nome,
      item.total,
      item.percentual,
    ]);
  });

  const fileName = `Vendas Perdidas - Período de ${dataInicial} até ${dataFinal}`;
  await salvarXlsx(infoExcel, fileName);
}
