
import React from 'react';
import "antd/dist/antd.css";
import {
  Form,
  Input,
  Tooltip,
  Icon,
  Button,
  Divider,
  Select,
} from 'antd';
import { getGestorId } from '../../auth/auth-provider';
import mensagem from 'components/message/Message';
import API from 'services/api/api';

const { Option } = Select;
const api = new API();


class ForaMixCRUD extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    data: {
      key: this.props.record.key,
      nome: '',
      ativo: true,
      ativoStr: 'Sim',
    }
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
    this.changeLoadingStatus = this.changeLoadingStatus.bind(this);
  }

  changeLoadingStatus(loading) {
    this.setState({ loading });
    if (this.props.onCloseHandle) {
      this.props.onCloseHandle(!loading);
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }
      this.changeLoadingStatus(true);
      const gestorId = getGestorId();
      const item = {
        nome: values.nome,
        ativo: values.ativo.toLocaleLowerCase() === 'sim' ? true : false,
        gestorId,
      }

      let res;
      if (!this.props.editMode) {
        res = await api.post({ ...item }, '/fora-mix');
      }
      else {
        res = await api.put({
          gestorId,
          key: this.props.record.key,
          updateList: item
        }, '/fora-mix');
      }

      if (!res.recorded) {
        const title = res.duplicated ? 'Duplicação' : 'Erro';
        mensagem.openNotificationWithIcon(
          'error',
          title,
          res.message
        );

        this.changeLoadingStatus(false);
        return;
      } else {
        mensagem.openNotificationWithIcon(
          "success",
          "Perfeito!",
          res.message,
          3
        );

        item["key"] = res.id ?? this.props.record.key;
        this.props.handleOk(item);
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  componentDidMount() {
    if (this.props.editMode && this.props.record) {
      this.setState({
        data: {
          key: this.props.record.key,
          nome: this.props.record.nome,
          ativo: this.props.record.ativo,
          ativoStr: this.props.record.ativoStr,
        }
      });
    }
  }

  cancelClick() {
    this.props.handleCancel();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>

<Form.Item
          label={
            <span>
              Ativo&nbsp;
              <Tooltip title="Esse item fora de mix está ativo?">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          {getFieldDecorator('ativo', {
            initialValue: this.state.data.ativoStr,
            rules: [{ required: true, message: 'Informe se o item fora de mix está ativo' }],
          })(

            <Select style={{ width: 120 }}>
              <Option value="sim">Sim</Option>
              <Option value="nao">Não</Option>
            </Select>

          )}
        </Form.Item>

        <Form.Item
          ref='txtNome'
          label={
            <span>
              Nome&nbsp;
              <Tooltip title="Qual é o nome do item fora de mix?">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          {getFieldDecorator('nome', {
            initialValue: this.state.data.nome,
            rules: [{ required: true, message: 'Informe o nome do item fora de mix', whitespace: true }],
          })(<Input />)}
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Salvar
          </Button>
          <Divider type="vertical" />
          <Button onClick={this.cancelClick} disabled={this.state.loading}>
            Cancelar
          </Button>

        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(ForaMixCRUD);
