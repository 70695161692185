import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router";
import { fireBase } from "./firebase/firebase";
import { logout } from './components/auth/auth-provider';
import ls from 'local-storage'
import Login from "./pages/login/Login";
import Empresa from "./pages/empresa/Empresa";
import Gerente from "./pages/gerente/Gerente";
import Vendedor from "./pages/vendedor/Vendedor";
import FluxoOportunidadeVendedor from "./pages/relatorios/taxaConversaoVendedor/TaxaConversaoVendedor";
import VendasPerdidas from "./pages/relatorios/vendasPerdidas/VendasPerdidas";
import EvolucaoVendedores2 from "./pages/relatorios/evolucaoVendedores2/EvolucaoVendedores"
import Perfil from './pages/perfil/Perfil';
import Produto from "./pages/produto/Produto";
import Motivo from "./pages/motivo/Motivo";
import ForaDeMix from "./pages/foramix/ForaDeMix";
import Loja from "./pages/parceiro/loja/Loja";
import Indicador from "./pages/parceiro/indicador/Indicador";
import DashBoard from "./pages/dashboard/DashBoard";
import Home from "./pages/home/Home";
import Page404 from "./pages/404/404";
import Contato from "./pages/contato/Contato";
import Sobre from "./pages/sobre/Sobre";
import Privacidade from "./pages/privacidade/Privacidade";
import Pagamento from "./pages/parceiro/pagamento/Pagamento";
import ForaMix from "./pages/relatorios/foraMix/ForaMix";
import ProdutoMotivo from "./pages/relatorios/ProdutoMotivo/ProdutoMotivo";
import MotivoProduto from "./pages/relatorios/MotivoProduto/MotivoProduto";
import Metas from './pages/relatorios/metas/Metas'
import IndicacaoLoja from "./pages/parceiro/indicacaoLoja/IndicacaoLoja";
import Meta from "./pages/meta/Meta";
import Escala from "./pages/meta/main/Metas";
import Configuracao from "./pages/configuracao/Configuracao";
import IndicacoesTotais from "./pages/parceiro/indicacoesTotais/IndicacoesTotais";
import ImportarProdutos from "pages/importarProduto/ImportarProdutos";
import PrimeiroAcesso from "./pages/primeiroAcesso/PrimeiroAcesso";
import RotinaVendedor from "pages/relatorios/rotinaVendedor/RotinaVendedor";
import HistoricoLogin from "pages/relatorios/historicoLogin/HistoricoLogin";
import Extrato from "pages/relatorios/extratoVendas/Extrato";
import TaxaConversao from "./pages/relatorios/taxaConversao/TaxaConversao";


class Routes extends React.Component {
  UNSAFE_componentWillMount() {
    fireBase.auth().onAuthStateChanged(user => {
      if (!user && this.props.location.pathname !== "/login" &&
        this.props.location.pathname !== "/contato" &&
        this.props.location.pathname !== "/time" &&
        this.props.location.pathname !== "/sobre" &&
        this.props.location.pathname !== "/privacidade" &&
        this.props.location.pathname !== "/primeiro-acesso") {
        logout();
        this.props.history.push("/home");
      }
    });
  }

  render() {
    return (
      <Switch>
        <PrivateRoute exact path="/dashboard">
          <DashBoard />
        </PrivateRoute>

        <PrivateRoute exact path="/perfil">
          <Perfil />
        </PrivateRoute>

        <PrivateRoute exact path="/configuracao">
          <Configuracao />
        </PrivateRoute>

        <PrivateRoute exact path="/empresas" >
          <Empresa />
        </PrivateRoute>

        <PrivateRoute exact path="/gerentes">
          <Gerente />
        </PrivateRoute>

        <PrivateRoute exact path="/vendedores">
          <Vendedor />
        </PrivateRoute>

        <PrivateRoute exact path="/produtos">
          <Produto />
        </PrivateRoute>

        <PrivateRoute exact path="/importar-produtos">
          <ImportarProdutos />
        </PrivateRoute>

        <PrivateRoute exact path="/motivos">
          <Motivo />
        </PrivateRoute>

        <PrivateRoute exact path="/fora-de-mix">
          <ForaDeMix />
        </PrivateRoute>


        {/* Parceiros */}

        <PrivateRoute exact path="/parceiros/lojas">
          <Loja />
        </PrivateRoute>

        <PrivateRoute exact path="/parceiros/indicadores">
          <Indicador />
        </PrivateRoute>

        <PrivateRoute exact path="/parceiros/pagamentos">
          <Pagamento />
        </PrivateRoute>

        <PrivateRoute exact path="/parceiros/indicacoes-totais">
          <IndicacoesTotais />
        </PrivateRoute>

        <PrivateRoute exact path="/parceiros/indicacao-loja">
          <IndicacaoLoja />
        </PrivateRoute>

        {/* Metas */}

        <PrivateRoute exact path="/metas/cadastro">
          <Escala />
        </PrivateRoute>

        <PrivateRoute exact path="/metas/relatorio">
          <Metas />
        </PrivateRoute>

        <PrivateRoute exact path="/metas">
          <Meta />
        </PrivateRoute>

        <PrivateRoute exact path="/adm/historico-logins">
          <HistoricoLogin />
        </PrivateRoute>



        {/* Relatórios */}

        <PrivateRoute exact path="/relatorio/taxa-conversao">
          <TaxaConversao />
        </PrivateRoute>

        <PrivateRoute exact path="/relatorio/taxa-conversao-vendedor">
          <FluxoOportunidadeVendedor />
        </PrivateRoute>

        <PrivateRoute exact path="/relatorio/desempenho-individual">
          <EvolucaoVendedores2 />
        </PrivateRoute>

        <PrivateRoute exact path='/relatorio/rotina-vendedor'>
          <RotinaVendedor />
        </PrivateRoute>

        <PrivateRoute exact path="/relatorio/vendas-perdidas">
          <VendasPerdidas />
        </PrivateRoute>

        <PrivateRoute exact path="/relatorio/produtos-motivos">
          <ProdutoMotivo />
        </PrivateRoute>

        <PrivateRoute exact path="/relatorio/motivo-produto">
          <MotivoProduto />
        </PrivateRoute>

        <PrivateRoute exact path="/relatorio/extrato-atendimentos">
          <Extrato />
        </PrivateRoute>


        <PrivateRoute exact path="/relatorio/fora-mix">
          <ForaMix />
        </PrivateRoute>

        {/*<PrivateRoute exact path="/relatorio/evolucao-vendedores">
                    <EvolucaoVendedores />
                </PrivateRoute>*/}

        {/*<PrivateRoute exact path="/relatorio/historico-atendimento">
                    <HistoricoAtendimento />
                </PrivateRoute>*/}

        {/* Telas para as quais o usuário não precisa estar logado */}

        <Route path="/home" component={Home} />
        <Route path="/contato" component={Contato} />

        {/*
        Rota de time desligada
        <Route path="/time" component={Time} />
        */}

        <Route path="/sobre" component={Sobre} />
        <Route path="/login" component={Login} />
        <Route path="/primeiro-acesso" component={PrimeiroAcesso} />
        <Route path="/privacidade" component={Privacidade} />

        {/* <Redirect from="*" to="/dashboard" /> */}
        <Route path='/' exact={true} component={Home} />
        <Route path="/404" component={Page404} />
        <Redirect to="/404" />
      </Switch>
    );
  }
}

export default withRouter(Routes);


function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const user = ls.get('user');
        if (user) {
          return children;
        }

        return <Redirect
          to={{
            pathname: "/home",
            state: { from: location }
          }}
        />
      }}
    />
  );
}
