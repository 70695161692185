import {
  getGestorId,
  getUserType,
  getGerenteId,
} from "../../../components/auth/auth-provider";
import empresaDB from "../../../dataManager/dtmEmpresa";
import { GERENTE } from "../../../components/funcs/constants";
import gerenteDB from "../../../dataManager/dtmGerente";
import vendedorDB from "../../../dataManager/dtmVendedor";
import conversaoDB from "dataManager/dtmConversao";
import { salvarXlsx } from "../metas/Funcs-Metas";
import produtoDB from "dataManager/dtmProduto";


export async function getVendedores(empresaId) {
  const vendedores = await vendedorDB.getByEmpresaId(empresaId);
  return vendedores;
}

export async function getEmpresas() {
  const gestorId = getGestorId();
  const empresas = await empresaDB.getByGestorIdCustom(gestorId, 'login.login');
  const newEmpresas = [];
  const userType = getUserType();

  if (userType === GERENTE) {
    const gerenteId = getGerenteId();
    const empresasGerente = await gerenteDB.getEmpresasGerente(gerenteId, 'razaoSocial');

    for (let i = 0; i < empresasGerente.length; i++) {
      const empresa = empresas.filter((item) => {
        return item.key === empresasGerente[i].id;
      });

      if (empresa && empresa.length > 0) {
        newEmpresas.push(empresa[0]);
      }
    }
  }
  return newEmpresas.length > 0 ? newEmpresas : empresas;
}

export async function buildReport(props) {

  const { vendedorId, empresaId, dataInicial, dataFinal, opcaoExtrato } = props;

  const inicio = dataInicial.startOf('day').toDate();
  const fim = dataFinal.endOf('day').toDate();

  let totalPecas = 0;
  let valorTotal = 0;

  let data = undefined;

  if (vendedorId === 'todos') {
    data = await conversaoDB.getByPeriodo(inicio, fim, 'empresaId', empresaId, true);
  } else {
    data = await conversaoDB.getByVendedorAndPeriodo(inicio, fim, vendedorId);
  }

  if (!data) {
    return {
      dados: [],
      valorTotal,
      totalPecas
    };
  }

  let filtrados = undefined;
  if (opcaoExtrato === 'vendas') {
    filtrados = data.filter(dado => dado.vendeu);
  } else if (opcaoExtrato === 'perdas') {
    filtrados = data.filter(dado => !dado.vendeu);
  } else {
    filtrados = data;
  }

  for (let i = 0; i < filtrados.length; i++) {
    const item = filtrados[i];
    totalPecas += item.totalPecas ? item.totalPecas : 0;
    valorTotal += item.valorTotal ? item.valorTotal : 0;

    item.dataInicial = dataBrasil(item.dataInicial);
    item.dataFinal = dataBrasil(item.dataFinal);
    item.valorTotal = item.valorTotal ? conversaoDB.formatarMoeda(item.valorTotal) : 0;
    item.fuga = item.estavaEmAtendimento ? 'Não' : 'Sim';
    item.integracao = item.vendaId ? 'Sim' : 'Não';
    item.vendedor = item.vendedor.nome;
    item.valorTotal = item.valorTotal ? item.valorTotal : 'R$ 0,00';
    item.totalPecas = item.totalPecas ? item.totalPecas : 0;
    item.vendeu = item.vendeu === true ? 'Sim' : 'Não';
    item.experimentou = item.experimentou === true ? 'Sim' : 'Não';

    if (item.produtos && item.produtos.length > 0) {
      for (let j = 0; j < item.produtos.length; j++) {
        const produto = item.produtos[j];
        if (!produto.codigo && produto.id !== '--' && props.exibirSku) {
          const prd = await produtoDB.getById(produto.id);
          produto.codigo = prd.codigo ? prd.codigo : prd.pdvId ? prd.pdvId : undefined;
        }
      }
    }
  }

  valorTotal = conversaoDB.formatarMoeda(valorTotal);

  return {
    dados: filtrados,
    valorTotal,
    totalPecas
  };
}

function dataBrasil(data) {
  return data.toDate().toLocaleDateString() + ' ' + data.toDate().toLocaleTimeString().slice(0, 5);
}

export async function exportToExcel(props) {
  const { data, empresaNome, vendedor, periodo, totalPecas, valorTotal, opcaoExtrato, colunas } = props;

  const dataInicial = periodo.inicial.toDate().toLocaleDateString();
  const dataFinal = periodo.final.toDate().toLocaleDateString();

  const infoExcel = [];
  infoExcel.push([`Empresa: ${empresaNome}`]);
  infoExcel.push([`Vendedor: ${vendedor.nome}`]);
  infoExcel.push(['']);

  const excelHeaders = colunas.map(column => column.title);
  infoExcel.push(excelHeaders);

  const excelData = data.map(item => {
    const rowData = colunas.map(column => {
      const dataIndex = column.dataIndex;
      const dataValue = item[dataIndex];
      const mappedValue = column.mapValue ? column.mapValue(dataValue) : dataValue;
      return mappedValue;
    });
    return rowData;
  });

  infoExcel.push(...excelData);

  infoExcel.push(['']);

  infoExcel.push([
    `Totais (${data.length}) registros`,
    '',
    '',
    '',
  ]);

  if (opcaoExtrato !== 'perdas') {
    infoExcel.push(['Total de Peças', totalPecas]);
  }
  infoExcel.push(['Valor Total', valorTotal]);

  const fileName = `Extrato de vendas ${vendedor.nome} - Período de ${dataInicial} até ${dataFinal}`;
  await salvarXlsx(infoExcel, fileName);
}


