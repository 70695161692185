import { fireBase, UPDATE_EMAIL_URL } from "../../firebase/firebase";

export async function validateUser(password) {
  try {
    const user = fireBase.auth().currentUser;
    await fireBase.auth().signInWithEmailAndPassword(user.email, password);
    return true;
  } catch (error) {
    return false;
  }
}

export async function updateProfile(displayName, photoURL) {
  const user = fireBase.auth().currentUser;

  try {
    await user.updateProfile({
      displayName,
      photoURL,
    });

    return true;
  } catch (error) {
    return false;
  }
}

async function getToken() {
  const token = await fireBase.auth().currentUser.getIdToken(/* forceRefresh */ true)
    .then(function (idToken) {
      return 'bearer ' + idToken;
    }).catch(function (error) {
      console.log(error.message);
      return;
    });

  return token;
}

/* ---- ALTERAÇÃO DE EMAIL ----*/
export async function updateEmail(oldEmail, newEmail) {
  return new Promise(async (resolve, reject) => {
    const URL = UPDATE_EMAIL_URL;

    const token = await getToken();

    const item = {
      headers: {
        "content-type": "application/json",
        "oldemail": oldEmail,
        "newemail": newEmail,
        "Authorization": token,
        "authUserToken": true
      },
      method: "POST"
    }
    fetch(URL, item)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.code === 200) {
          resolve(responseData);
        }
        else {
          reject(responseData.message);
        }
      });
  });

}
/*-------- */

export async function updatePassword(newPassword) {
  const user = fireBase.auth().currentUser;

  try {
    await user.updatePassword(newPassword);
    return true;
  } catch (error) {
    return false;
  }
}

export async function uploadAvatar(imagePath) {
  const fileName = makeid();
  imagePath = imagePath.replace("data:image/jpeg;base64,", "");
  imagePath = imagePath.replace("data:image/png;base64,", "");

  try {
    const res = await fireBase
      .storage()
      .ref("/profiles/avatars/")
      .child(fileName)
      .putString(imagePath, "base64", { contentType: "image/jpeg" });

    const url = await res.task.snapshot.ref.getDownloadURL();
    return {
      success: true,
      url,
    };
  } catch (error) {
    console.log(error);

    return {
      success: false,
      url: "",
    };
  }
}

function makeid() {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
