import React from 'react';
import { Form, Icon, Input, Button, message, Row, Col } from 'antd';
import { autenticar, redirectJustIfUserIsAuthenticated, redefinePassword } from './LoginF';
import { TELEFONE_CONTATO } from '../../components/funcs/constants';
import { REDES_SOCIAIS } from '../../components/funcs/constants';
import { RECAPTCHA_SITE_KEY } from '../../firebase/firebase';

import history from '../../history';
import ReCAPTCHA from 'react-google-recaptcha';
import API from '../../services/api/api';

// CSSs
import "../../assets/template/assets/css/bootstrap.min.css";
import "../../assets/template/assets/css/font-awesome.min.css";
import "../../assets/template/assets/css/themify-icons.css";
import "../../assets/template/assets/css/magnific-popup.css";
import "../../assets/template/assets/css/owl.carousel.css";
import "../../assets/template/assets/css/owl.transitions.css";
import "../../assets/template/assets/css/plyr.css";
import "../../assets/template/assets/css/swiper.min.css";
import "../../assets/template/assets/css/slick.css";
import "../../assets/template/css/scheme/blue-green.css";
import "../../assets/template/css/style.css";
import "../../assets/template/css/responsive.css";
import '../../assets/template/assets/js/jquery.min.js';
import '../../assets/template/assets/js/bootstrap.min.js';
import '../../assets/template/assets/js/jquery.nav.js';
import '../../assets/template/assets/js/owl.carousel.js';
import '../../assets/template/assets/js/visible.js';
import '../../assets/template/assets/js/jquery.stellar.min.js';
import '../../assets/template/assets/js/jquery.countTo.js';
import '../../assets/template/assets/js/imagesloaded.pkgd.min.js';
import '../../assets/template/assets/js/isotope.pkgd.min.js';
import '../../assets/template/assets/js/jquery.magnific-popup.min.js';
import '../../assets/template/assets/js/jquery.ajaxchimp.min.js';
import '../../assets/template/assets/js/plyr.js';
import '../../assets/template/assets/js/swiper.min.js';
import '../../assets/template/assets/js/slick.min.js';
import './Login.css';

import logo from "../../assets/template/images/logo-bluve-azul.png";

const api = new API();


class Login extends React.Component {

  state = {
    passType: 'password',
    passClass: 'zmdi zmdi-eye',
    reCaptchaToken: undefined,
    disableLoginPermanently: false,
    loading: false,
    loadingFirstAccess: false,
    contato: TELEFONE_CONTATO,
  }

  constructor(props) {
    super(props);
    this.autenticar = autenticar.bind(this);
    this.redirectJustIfUserIsAuthenticated = redirectJustIfUserIsAuthenticated.bind(this);
    this.esqueciSenhaOnClick = this.esqueciSenhaOnClick.bind(this);
    this.onClickFirstAccess = this.onClickFirstAccess.bind(this);
    this.handleReCaptcha = this.handleReCaptcha.bind(this);
    this.desabilitarBotoesLogin = this.desabilitarBotoesLogin.bind(this);
    this.redirectJustIfUserIsAuthenticated();
  };

  desabilitarBotoesLogin() {
    if (window.location.hostname === 'localhost') return false;
    
    return (!this.state.reCaptchaToken || this.state.disableLoginPermanently);
  }

  esqueciSenhaOnClick() {
    const email = this.props.form.getFieldValue('username');
    if (!email) {
      message.warning('Informe seu email de login');
      return;
    }

    this.setState({ loading: true });
    setTimeout(async () => {
      const resp = await redefinePassword(email);
      if (resp.success) {
        message.info('Email de redefinição de senha enviado. Verifique sua caixa de emails');
      }
      else {
        message.error(`Ocorreu o seguinte erro ao tentar enviar seu email: ${resp.message}`);
      }
      this.setState({ loading: false });
    }, 1000);
  }

  async onClickFirstAccess() {
    this.setState({ loadingFirstAccess: true });

    setTimeout(() => {
      this.setState({ loadingFirstAccess: false });

      history.push(`/primeiro-acesso`);
      history.go();
    }, 1000 * 2);
  }

  handleReCaptcha(token) {
    this.setState({ reCaptchaToken: token });
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }

      this.setState({ loading: true });

      const { reCaptchaToken } = this.state;
      const reCaptchaIsOk = await api.validateReCaptcha(reCaptchaToken);

      if (!reCaptchaIsOk) {
        this.setState({
          loading: false,
          disableLoginPermanently: true
        });

        return;
      }


      const isOk = await autenticar(values.username, values.password);

      if (isOk) {
        history.push(`/dashboard`);
        history.go();
      } else {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>

        <nav className="navbar navbar-default" data-spy="affix" data-offset-top="60">
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="/home">
                <img
                  className="bluveLogoTopo"
                  alt="Logo Bluve Rodapé"
                  src={logo}
                // src="https://storage.googleapis.com/bluve-bucket/logo_oficial.png"
                ></img>
              </a>
            </div>

            <div className="collapse navbar-collapse" id="navbar-collapse">
              <ul className="nav navbar-nav navbar-left">
                <li><a href="/home">Home</a></li>
                {/* <li><a href="https://blog.bluve.com.br" target="_blank" rel="noopener noreferrer">Blog</a></li> */}
                <li><a href="/contato">Contato</a></li>
              </ul>
            </div>

          </div>
        </nav>
        {/* Page Header */}
        <div className="page-header">
          <div className="container">
          </div>
        </div>
        {/* Page Header End */}



        <div className="cps-main-wrap">
          <div className="cps-section cps-section-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 col-xs-12">
                  <div className="cps-section-header text-center">
                    <h3 className="cps-section-title">Entre com seus dados de Gestor</h3>
                    <p className="cps-section-text">Utilize o email e senha de gestor que você recebeu. Se esqueceu a senha, clique em "Esqueci minha senha"</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                  <Form onSubmit={this.handleSubmit} className="accountForm">
                    <Form.Item>
                      {getFieldDecorator('username', {
                        rules: [{ required: true, message: 'Informe seu email de login' }],
                      })(
                        <Input
                          prefix={<Icon type="user" className="iconLoginLock" />}
                          placeholder="Email"
                          className="loginInput"
                        />,
                      )}
                    </Form.Item>

                    <Form.Item>
                      {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Informe a sua senha de login' }],
                      })(
                        <Input.Password
                          prefix={<Icon type="lock" className="iconLoginLock" />}
                          type="password"
                          placeholder="Senha"
                          className="loginInput"
                        />,
                      )}
                    </Form.Item>

                    <Form.Item>
                      <ReCAPTCHA
                        className='recaptcha'
                        sitekey={RECAPTCHA_SITE_KEY}
                        onChange={this.handleReCaptcha}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Row gutter={8}>
                        <Col span={12}>
                          <Button
                            className="formButton"
                            type="primary"
                            htmlType="submit"
                            loading={this.state.loading}
                            disabled={this.desabilitarBotoesLogin()}
                          >
                            Login
                          </Button>
                        </Col>
                        <Col span={12}>
                          <Button
                            className='formButton'
                            type='primary'
                            onClick={this.onClickFirstAccess}
                            loading={this.state.loadingFirstAccess}
                            disabled={this.desabilitarBotoesLogin()}
                          >
                            Primeiro Acesso
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>

                    <Form.Item>
                      {/* eslint-disable-next-line */}
                      <a className="esqueciSenha" onClick={this.esqueciSenhaOnClick}>Esqueci minha senha</a>
                    </Form.Item>
                  </Form>

                </div>
              </div>
            </div>
          </div>
          {/* Login end */}

          {/* Subscription */}
          <div className="cps-section cps-section-padding cps-gradient-bg" id="subscription-area">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2">
                  <div className="cps-section-header text-center">
                    <h3 className="cps-section-title">Ainda não é nosso cliente?</h3>
                    <p className="cps-section-text">Entre em contato conosco através do número:</p>
                    <h4 className="cps-subsection-title">{this.state.contato}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Subscription End */}
        </div>

        <footer>
          <div className="cps-footer-upper">
            <div className="container">
              <div className="cps-footer-widget-area">
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="cps-widget about-widget">
                      <a className="cps-footer-logo" href="/home">
                        <img
                          className="bluveLogoTopo"
                          alt="Logo Bluve Rodapé"
                          src="https://storage.googleapis.com/bluve-bucket/b_oficial.png"
                        ></img>
                      </a>

                      <p>{REDES_SOCIAIS}</p>
                      <div className="cps-socials">
                        <a href="https://www.facebook.com/bluvebr" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
                        <a href="https://www.instagram.com/bluvebr" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>
                      </div>
                    </div>
                  </div>


                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="cps-widget custom-menu-widget">
                      <h4 className="cps-widget-title">Mais</h4>
                      <ul className="widget-menu">
                        <li><a href="/sobre">Sobre nós</a></li>
                        {/* <li><a href="/produtos">Nossos produtos</a></li> */}
                        <li><a href="/time">Nosso time</a></li>
                        {/* <li><a href="/clientes">Nossos clientes</a></li> */}
                        <li><a href="/contato">Contato</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cps-footer-lower">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-xs-12 xs-text-center">
                  <p className="copyright">Copyright 2020 - 4C Retail Solutions, <a href="/home">Bluve</a>. Todos os direitos reservados
                  </p>
                </div>
                <div className="col-sm-6 col-xs-12 text-right xs-text-center">
                  <ul className="footer-menu">
                    <li><a href="/privacidade">Política de privacidade</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>

      </div>
    );
  }
}

export default Form.create()(Login);
