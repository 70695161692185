import React from "react";
import { withRouter } from "react-router";
import { Table, Button, Icon, Modal, Avatar, Input, Tooltip, Checkbox, message } from "antd";
import "./Vendedor.css";
import "antd/dist/antd.css";
import VendedorCRUD from "../../components/cruds/vendedores/VendedorCRUD";
import {
  getGestorId,
  getUserType,
  getGerenteId,
} from "../../components/auth/auth-provider";
import BluveLayout from "../../components/layout/BluveLayout";
import { GERENTE } from "../../components/funcs/constants";
import listObj from "../../components/listSearch/listSearch";
import mensagem from "components/message/Message";
import API from "services/api/api";

const { Search } = Input;
const api = new API();

class Vendedor extends React.Component {
  state = {
    vendedores: [],
    vendedoresOriginal: [],
    vendedoresLista: [],
    empresas: [],
    canUpdate: false,
    loading: true,
    checked: undefined,
    updated: false
  };

  constructor(props) {
    super(props);
    this.headerElement = React.createRef();
    this.applyFilter = this.applyFilter.bind(this);
    this.updateList = this.updateList.bind(this);
    this.getCheckboxState = this.getCheckboxState.bind(this);
    this.checkboxUpdate = this.checkboxUpdate.bind(this);
  }

  async componentDidMount() {

    document.addEventListener('tokenAvailable', async () => {

      this.getEmpresas()
        .then(async (empresas) => {
          empresas = await this.getEmpresas();
          const vendedores = await this.getVendedores(empresas, true);

          this.setState({
            empresas,
            vendedores,
            vendedoresOriginal: vendedores,
            loading: false,
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log(error);
        });
    });
  }

  getEmpresas() {
    return new Promise((resolve, reject) => {
      const gestorId = getGestorId();
      api.get({ gestorId }, '/empresa')
        .then(async (empresas) => {
          const userType = getUserType();
          const newEmpresas = [];

          if (userType === GERENTE) {
            const gerenteId = getGerenteId();
            api.get({ gerenteId }, '/gerente/empresas')
              .then(empresasGerente => {
                for (let i = 0; i < empresasGerente.length; i++) {
                  const empresa = empresas.filter(item => {
                    return item.key === empresasGerente[i].id;
                  });

                  if (empresa && empresa.length > 0) {
                    newEmpresas.push(empresa[0]);
                  }
                }
                resolve(newEmpresas.length > 0 ? newEmpresas : empresas);
              })
              .catch(error => {
                this.setState({ loading: false });
                console.log(error);
                message.error('Erro ao buscar gerentes.');
                reject(error);
              });
          } else {
            resolve(empresas);
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log(error);
          message.error('Erro ao buscar empresas.');
          reject(error);
        });
    });
  }

  async getVendedores(empresas, ativo) {
    const gestorId = getGestorId();
    const vendedores = [];

    for (let i = 0; i < empresas.length; i++) {
      const novosVendedores = await api.get({
        gestorId,
        ativo,
        empresaId: empresas[i].key
      }, '/vendedor');

      vendedores.push(...novosVendedores);
    }

    return vendedores;
  }

  async applyFilter(text) {
    if (text === "") {
      this.setState({ vendedores: this.state.vendedoresOriginal });
      return;
    }

    const keys = ["apelido", "nome", "empresa.nome", "empresa.setor", "ativoStr"];

    let vendedores = [];
    if (!this.state.checked && this.state.vendedoresLista) {
      vendedores = listObj.search(this.state.vendedoresLista, text, keys);
    } else {
      vendedores = listObj.search(this.state.vendedoresOriginal, text, keys);
    }

    this.setState({ vendedores });
  }

  updateList(record) {
    record['ativoStr'] = record['ativo'] ? 'Sim' : 'Não';
    let registroEncontrado = false;
    let listaAtivos = [];
    let listaInativos = [];

    if (this.state.checked) {
      listaInativos = this.state.vendedores;
      listaAtivos = this.state.vendedoresLista;
    } else if (this.state.vendedoresLista.length) {
      listaAtivos = this.state.vendedores;
      listaInativos = this.state.vendedoresLista;
    } else {
      listaAtivos = this.state.vendedores;
    }

    if (listaAtivos) {
      listaAtivos.forEach((item, index) => {
        if (item.key === record.key) {
          if (!record['ativo']) {
            listaAtivos.splice(index, 1);
          } else {
            listaAtivos[index] = record;
          }
          registroEncontrado = true;
        }
      });
    }

    if (listaInativos.length) {
      listaInativos.forEach((item, index) => {
        if (item.key === record.key) {
          if (record['ativo']) {
            listaAtivos.push(record);
          }

          listaInativos[index] = record;
          registroEncontrado = true;
        }
      });
    }

    if (!registroEncontrado && record['ativo']) {
      listaAtivos.unshift(record);
      if (listaInativos.length) listaInativos.unshift(record);
    } else if (!registroEncontrado && !record['ativo'] && listaInativos.length) {
      listaInativos.push(record)
    }

    if (this.state.checked) {
      this.setState({ vendedores: listaInativos, vendedoresOriginal: listaInativos, vendedoresLista: listaAtivos });
    } else {
      this.setState({ vendedores: listaAtivos, vendedoresOriginal: listaAtivos, vendedoresLista: listaInativos });
    }
  }

  editarVendedorElement(record) {
    record["empresas"] = this.state.empresas;
    this.headerElement.current.editar(record);
  }

  getCheckboxState(checked) {
    this.setState({ checked });
    this.checkboxUpdate();
  }

  async checkboxUpdate() {
    this.setState({ loading: true });
    if (this.state.vendedoresLista.length || !this.state.checked && this.state.vendedores) {
      const vendedoresLista = this.state.vendedoresLista;
      const vendedores = this.state.vendedores;

      this.setState({
        vendedores: vendedoresLista,
        vendedoresOriginal: vendedoresLista,
        vendedoresLista: vendedores
      });
    } else if (this.state.checked) {
      let vendedores = this.state.vendedores;
      this.setState({ vendedoresLista: vendedores });

      const novaLista = await this.getVendedores(this.state.empresas, false);;
      vendedores = vendedores.concat(novaLista);
      this.setState({ vendedores: vendedores, vendedoresOriginal: vendedores });
    }
    this.setState({ loading: false });
  }

  render() {
    const columns = [
      {
        title: "Foto",
        dataIndex: "avatar",
        key: "avatar",
        width: 90,
        render: (avatar) => (
          <span>
            <Avatar size={52} src={avatar} />
          </span>
        ),
      },
      {
        title: "Apelido",
        dataIndex: "apelido",
        key: "apelido",
      },
      {
        title: "Nome Completo",
        dataIndex: "nome",
        key: "nome",
      },
      {
        title: "Empresa/Loja",
        dataIndex: "empresa.nome",
        key: "empresaLoja",
      },
      {
        title: "Setor",
        dataIndex: "empresa.setor",
        key: "empresaSetor",
      },
      {
        title: "Ativo?",
        dataIndex: "ativoStr",
        key: "ativo",
      },
      {
        title: "",
        width: "30px",
        key: "action",
        render: (text, record) => (
          <span>
            <Tooltip placement="topLeft" title="Editar Cadastro">
              <a onClick={() => this.editarVendedorElement(record)}>
                <Icon type="edit" />
              </a>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <div>
        <BluveLayout selectItem={"vendedores"}>
          <Table
            pagination={{
              defaultPageSize: 100,
              position: "both",
              pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
            }}
            title={() => (
              <Header
                ref={this.headerElement}
                empresas={this.state.empresas}
                applyFilter={this.applyFilter}
                updateList={this.updateList}
                getCheckboxState={this.getCheckboxState}
              />
            )}
            columns={columns}
            dataSource={this.state.vendedores}
            bordered
            loading={this.state.loading}
          />
        </BluveLayout>
      </div>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.novoVendedorElement = React.createRef();

    this.state = {
      visible: false,
      checked: false,
      loading: false
    };

    this.novo = this.novo.bind(this);
    this.filterTable = this.filterTable.bind(this);
    this.onCheck = this.onCheck.bind(this);

    let timer;
  }

  novo() {
    const record = [];
    record["empresas"] = this.props.empresas;
    this.novoVendedorElement.current.show(false, record);
  }

  editar(record) {
    this.novoVendedorElement.current.show(true, record);
  }

  filterTable(value) {
    this.setState({ loading: true });
    const { applyFilter } = this.props;

    const text = typeof value === "object" ? value.target.value : value;
    let time = text ? 3000 : 500;

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      applyFilter(text);
      this.setState({ loading: false });
    }, time);
  }

  async onCheck(e) {
    await this.setState({ checked: e.target.checked });
    this.props.getCheckboxState(this.state.checked);
  }

  render() {
    return (
      <div>
        <NovoVendedor
          ref={this.novoVendedorElement}
          updateList={this.props.updateList}
          record={[]}
        ></NovoVendedor>
        <Button type="primary" onClick={this.novo}>
          <Icon className="icon" type="plus" /> Novo Vendedor
        </Button>

        <Search
          placeholder="Procurar"
          onSearch={this.filterTable}
          onChange={this.filterTable}
          loading={this.state.loading}
          style={{ width: 200, marginLeft: "10px", marginRight: '1rem' }}
        />

        <Checkbox checked={this.state.checked} onChange={this.onCheck}>
          Listar Inativos
        </Checkbox>

      </div>
    );
  }
}

class NovoVendedor extends React.Component {
  state = {
    closable: true,
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
    empresas: [],
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onCloseHandle = this.onCloseHandle.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record,
    });
  }

  handleOk(record) {
    this.props.updateList(record);
    this.setState({ visible: false, closable: true });
  }

  onCloseHandle(canClose) {
    this.setState({ closable: canClose });
  }

  async handleCancel() {
    if (!this.state.closable) return;
    if (!(await mensagem.confirmar("Cancelar alterações?"))) return;
    this.setState({ visible: false, closable: true });
  }

  render() {
    return (
      <Modal
        title="Vendedor"
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        closable={this.state.closable}
      >
        <VendedorCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          editMode={this.state.editMode}
          record={this.state.record}
          empresas={this.state.empresas}
          onCloseHandle={this.onCloseHandle}
        ></VendedorCRUD>
      </Modal>
    );
  }
}

export default withRouter(Vendedor);