import React, { Component } from "react";
import ChildComponent from "./DiarioAtendimento.component";
import atendimentoDB from "../../../dataManager/dtmAtendimento";
import gerenteDB from "../../../dataManager/dtmGerente";
import moment from "moment";
import ApexCharts from "apexcharts";
import {
  getUserType,
  getGerenteId,
  getGestorId,
} from "../../auth/auth-provider";
import { GESTOR } from "../../funcs/constants";
import bluveStorage from '../../localstorage/localstorage';
import { analytics } from "firebase";
import { Timestamp } from "../../../firebase/firebase";

const ATENDIMENTOS = "ATENDIMENTOS";
const CONVERTIDOS = "CONVERTIDOS";
const TAXACONVERSAO = "TAXA DE CONVERSÃO"

const CINZA = "#9d9e9d";
const VERDE = "#9966ff";
const LARANJA = '#ebb134';

class DiarioAtendimento extends Component {
  constructor(props) {
    super(props);

    this._onChartClick = this._onChartClick.bind(this)

    this.state = {
      chartType: "bar",
      dados: {},
      isLoading: true,
      series: [],
      options: {
        chart: {
          id: "bar-stacked",
          type: "bar",
          height: 350,
          // stacked: true,
          // stackType: "100%",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: [LARANJA, CINZA, VERDE],
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -25,
          style: {
            fontSize: "12px",
            colors: ["#888"],
          },
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            if (w.config.series[seriesIndex].name === TAXACONVERSAO) {
              return value + '%'
            }
            return value;
          }
        },
        xaxis: {
          //type: "datetime",
          tickAmount: 29,
          labels: {
            formatter: function (val, timestamp) {
              return moment(val).format("DD/MM");
            }
          },
          categories: [],
          /*labels: {
            format: "dd/MM",
          },*/
        },
        legend: {
          position: "top",
          offsetX: 40,
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              if (seriesIndex === 0) {
                return value + '%'
              }
              return value;
            }
          }
        },
      },
      pageTitle: this.props.dashboard ? "Sua rede nos últimos dias" : "Atendimento x Conversão"
    };
  }

  componentDidMount() {
    if (this.props.dashboard) {
      this._fetch();
    }
  }

  _updateItem(item, infoData) {
    infoData.forEach((element) => {
      item.atendimentos += element.total;
      item.conversoes += element.convertidos;
      item.taxaConversao = ((item.conversoes * 100) / item.atendimentos).toFixed(0);
    });
  }

  _fillEmptyDays(info) {
    const { inicio, fim } = this.props;
    const days = fim.diff(inicio, "days");
    let novoInfo = [];

    for (let i = 0; i <= days; i++) {
      let data = moment(fim).startOf("day").subtract(i, "days");
      let item = info.find(
        (element) => (moment(element.data.toDate()).startOf("day")).diff(data) === 0
      );

      if (item) {
        novoInfo.push({
          data: item.data.toDate(),
          atendimentos: item.atendimentos,
          conversoes: item.conversoes,
        });
      } else {
        novoInfo.push({
          data: data.toDate(),
          atendimentos: 0,
          conversoes: 0,
          taxaConversao: 0
        });
      }
    }

    return novoInfo;
  }

  convertIntoFirebaseTimestamp(item) {
    const seconds = item.seconds
    const nanoseconds = item.nanoseconds
    item = new Timestamp(seconds, nanoseconds)
    return item;
  }

  _agruparDadosAtendimento(info) {
    let reduced = info.reduce((soma, valor) => {
      const dataItem = valor.data;
      const infoHoras = Object.values(valor.horas);
      const item = soma.find((element) => element.data.isEqual(dataItem)) || {
        data: dataItem,
        atendimentos: 0,
        conversoes: 0,
        taxaConversao: 0
      };

      if (item.atendimentos === 0 && item.conversoes === 0) {
        soma.push(item);
      }

      this._updateItem(item, infoHoras);

      return soma;
    }, []);

    reduced = this._fillEmptyDays(reduced);

    return reduced.reverse();
  }

  _fetch = async () => {

    const { empresaId, inicio, fim } = this.props;
    const { options } = this.state;
    const db = atendimentoDB;

    const storageDate = bluveStorage.get('dashboardChart/date');
    const dataAtual = moment().format('DD/MM/YYYY');

    this.setState({ isLoading: true })

    let resultado = undefined;

    const existeStorage = this.props.dashboard ? !(!storageDate || storageDate !== dataAtual) : undefined;

    if (!existeStorage) {
      if (empresaId === 'rede') {
        if (getUserType() === GESTOR) {
          const gestorId = getGestorId();
          const dataIni = inicio.toDate();
          const dataFim = fim.toDate();
          resultado = await db.getAtendimentos(gestorId, dataFim, dataIni, 0);
        } else {
          const gerenteId = getGerenteId();
          const empresas = await gerenteDB.getEmpresasGerente(gerenteId);

          let atendimentos = [];

          await Promise.all(empresas.map(async (empresa) => {
            const atendimentoEmpresa = await db.getEmpresaAtendimentos(
              empresa.id,
              inicio.toDate(),
              fim.toDate()
            )
            if (atendimentoEmpresa) {
              atendimentos = atendimentos.concat(atendimentoEmpresa)
            }
          }))

          resultado = atendimentos;
        }
      } else {
        resultado = await db.getEmpresaAtendimentos(
          empresaId,
          inicio.toDate(),
          fim.toDate()
        );
      }


      if (this.props.dashboard) {
        bluveStorage.set('dashboardChart/date', dataAtual);
        bluveStorage.set('dashboardChart/result', JSON.stringify(resultado))
      }

    } else {
      if (this.props.dashboard) {
        resultado = JSON.parse(bluveStorage.get('dashboardChart/result')); //Teste
        /*const rst = bluveStorage.get('dashboardChart/result');
        if (rst) resultado = (typeof rst === 'string') ? '' : JSON.parse(rst);*/
      }

      if (resultado) for (let i of resultado) {
        i.data = this.convertIntoFirebaseTimestamp(i.data);
      }
    }

    const dados = resultado ? this._agruparDadosAtendimento(resultado) : [];

    let labels = [];
    let seriesData = [
      {
        name: TAXACONVERSAO,
        data: []
      },
      {
        name: ATENDIMENTOS,
        data: [],
      },
      {
        name: CONVERTIDOS,
        data: [],
      },
    ];

    dados.forEach((element) => {
      element.taxaConversao = element.atendimentos === 0 ? 0 : ((element.conversoes * 100) / element.atendimentos).toFixed(0);
      seriesData[0].data.push(element.taxaConversao);
      seriesData[1].data.push(element.atendimentos);
      seriesData[2].data.push(element.conversoes);
      labels.push(parseFloat(moment(element.data).format("x")));
    });

    options.xaxis.categories = labels;

    this.setState({
      series: seriesData,
      options: options,
      dados: dados,
      isLoading: false,
    });
  };

  _onChartClick(event) {
    let chartType;
    let { series, options } = this.state;
    let newOptions = {};

    if (event.target.id === "bar") {
      chartType = "bar";
      newOptions = {
        stroke: {
          width: 2,
          colors: ["transparent"],
        },
        dataLabels: {
          enabled: true,
        },
      };
    } else if (event.target.id === "line") {
      chartType = "line";
      newOptions = {
        stroke: {
          colors: [LARANJA, CINZA, VERDE],
          width: [4, 3],
          curve: "straight",
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 4,
          hover: {
            sizeOffset: 2,
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              if (seriesIndex === 0) {
                return value + '%'
              }
              return value;
            }
          }
        },
      };
    }

    newOptions = {
      ...newOptions,
      chart: {
        type: chartType,
      },
    };

    // this.setState({ chartType: chartType, options: options });
    this.setState({ chartType: chartType });

    // ApexCharts.exec("bar-stacked", "render");
    ApexCharts.exec("bar-stacked", "updateOptions", newOptions, true, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.loading && prevProps.loading !== this.props.loading) {
      this.setState({ isLoading: true }, this._fetch);
    }
  }

  render() {
    const { isLoading, series, options, chartType, pageTitle } = this.state;

    return (
      <ChildComponent
        isLoading={isLoading}
        series={series}
        options={options}
        type={chartType}
        chartButtonCallback={this._onChartClick}
        title={pageTitle}
      />
    );
  }
}

export default DiarioAtendimento;
