import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button, Input } from 'antd';


class VerificarEmail extends Component {
  state = {
    emailCensurado: '',
    email: ''
  }

  constructor(props) {
    super(props);

    this.kba = this.props.kba;
    this.historicoId = this.props.historicoId;
    this.onClickButton = this.props.onClickButton;

    this.handleConfirm = this.handleConfirm.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  async componentDidMount() {
    const emailCensurado = await this.kba.getCensoredEmail(this.historicoId);
    this.setState({ emailCensurado });
  }

  async handleConfirm() {
    return new Promise(async (resolve, reject) => {
      const { email } = this.state;

      if (email === '') {
        reject({
          type: 'error',
          title: 'Email Não Informado',
          description: 'Digite-o no campo abaixo.'
        });

        return;
      }

      try {
        const {
          valid,
          tentativas
        } = await this.kba.validateEmail(
          email,
          this.historicoId
        );

        if (valid) {
          await this.kba.sendAccessToken(email);

          resolve({
            message: 'Email confirmado.'
          });
        } else {
          reject({
            tentativas,
            type: 'error',
            title: 'Informação incorreta',
            description: 'O email não corresponde ao informado no cadastro.'
          });
        }
      } catch (error) {
        reject({
          type: 'error',
          title: 'Erro',
          description: error.message
        });
      }
    })
  }

  async onChangeInput(e) {
    const { value } = e.target;
    this.setState({ email: value });
  }

  render() {
    return (
      <div className='stepContainer'>
        <div className='textContainer'>
          <div className='title'>
            Verifique seu e-mail
          </div>

          <div className='description'>
            Digite corretamente o e-mail da sua conta. <a className='boldInLine'>{this.state.emailCensurado}</a>.
          </div>
        </div>

        <Input
          className='stepInput'
          placeholder='Email'

          value={this.state.email}
          onChange={this.onChangeInput}
        />

        <Button
          className='buttonConfirm'
          type='primary'

          loading={this.props.loading}
          onClick={() => this.onClickButton(this.handleConfirm)}
        >
          Próximo
        </Button>
      </div>
    )
  }
}

export default withRouter(VerificarEmail);
