import React from "react";
import { withRouter } from "react-router";
import { Table, Button, DatePicker } from "antd";
import "antd/dist/antd.css";
import "./IndicacoesTotais.css";
import BluveLayout from "../../../components/layout/BluveLayout";
import bluveStorage from "../../../components/localstorage/localstorage";
import moment from "moment";
import indicacoesDB from "../../../dataManager/dtmIndicacoes";
import empresaDB from "../../../dataManager/dtmEmpresa";

const dateFormat = "DD/MM/YYYY";
const { RangePicker } = DatePicker;

function formatarMoeda(valor) {
  valor = valor / 100;
  valor = parseFloat(valor).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL'
  });
  return valor;
}


class IndicacoesTotais extends React.Component {
  state = {
    loading: false,
    indicacoes: [],
    periodo: {},
    alterouConfiguracaoRelatorio: false,
  };

  options = [];

  constructor(props) {
    super(props);
    this.headerElement = React.createRef();

    this.onFilterClick = this.onFilterClick.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  async componentDidMount() {

    let periodo = bluveStorage.getReportPeriodo();
    if (!periodo) {
      periodo = {
        inicial: moment().subtract(7, "days"),
        final: moment(),
      };
    }
    else {
      periodo.inicial = moment(periodo.inicial);
      periodo.final = moment(periodo.final);
    }

    this.setState({ periodo });
  }

  async onFilterClick() {

    this.setState({ loading: true });

    const data = await indicacoesDB.getByGestorIdAndPeriodoAndConverteu(
      this.state.periodo.inicial._d,
      this.state.periodo.final._d,
      true);

    let indicacoes = [];

    for (let i = 0; i < data.length; i++) {
      const item = data[i];

      let indicacao = indicacoes.filter((ind) => {
        return ind.key === item.empresaId;
      })[0];

      if (!indicacao) {
        let empresa = undefined;
        if (item.empresaId) {
          empresa = await empresaDB.getById(item.empresaId)
        }
        else {
          empresa = {
            id: 'naoIdentificado',
            nomeFantasia: 'Não identificada'
          }
        }
        indicacao = {
          key: item.empresaId ? item.empresaId : empresa.id,
          loja: empresa.nomeFantasia,
          valorEmCentavos: item.valorEmCentavos,
          valor: formatarMoeda(item.valorEmCentavos),
          comissaoStr: '',
        }
        indicacoes.push(indicacao);
      } else {

        const lst = indicacoes.filter((ind) => {
          return ind.key !== item.empresaId;
        });

        indicacao.valorEmCentavos += item.valorEmCentavos;
        indicacao.valor = formatarMoeda(indicacao.valorEmCentavos);

        lst.push(indicacao);
        indicacoes = lst;
      }
    }
    indicacoes.sort((a, b) => (a.loja > b.loja ? 1 : -1));
    this.setState({ indicacoes, loading: false });
  }

  onDateChange(value) {
    const periodo = {
      inicial: value[0],
      final: value[1]
    }
    this.setState({ periodo, alterouConfiguracaoRelatorio: true });
  }


  render() {
    const columns = [
      {
        title: "Loja",
        dataIndex: "loja",
        key: "loja"
      },
      {
        title: "Valor Compra",
        dataIndex: "valor",
        key: "valor"
      },
    ];

    return (
      <div>
        <BluveLayout selectItem={"parceiros/indicacoes-totais"}>

          <div className="containerFluxoOportunidades">
            <label>Período:</label>
            <div className="rangePickerDuplo">
              <RangePicker
                format={dateFormat}
                placeholder={["Data Inicial", "Data Final"]}
                onChange={this.onDateChange}
                value={[
                  this.state.periodo.inicial,
                  this.state.periodo.final,
                ]}
                style={{ marginTop: '50px', marginBottom: '15px' }}
              />
            </div>
            <Button
              className="btnFiltrarIndicacoesTotais"
              type='primary'
              disabled={this.state.disabledControlls}
              loading={this.state.loading}
              onClick={this.onFilterClick}>Filtrar
            </Button>

          </div>

          <Table
            pagination={{ pageSize: 10, position: "top" }}
            columns={columns}
            dataSource={this.state.indicacoes}
            loading={this.state.gridLoading}
            bordered
          />
        </BluveLayout>
      </div>
    );
  }
}

export default withRouter(IndicacoesTotais);
