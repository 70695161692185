import { Component } from 'react';


class ListObj extends Component {

    search(list, text, keys) {
        const ret = [];
        list.forEach(item => {
            let adicionar = false;
            keys.forEach(key => {
                let value = undefined;
                var str = key.split('.');
                for (var i = 0; i < str.length; i++) {
                    value = !value ? item[str[i]] : value[str[i]];
                    if (String(value) !== '[object Object]' && String(value).toLowerCase().includes(String(text).toLowerCase())) {
                        adicionar = true;
                    }
                }
            });

            if (adicionar) {
                ret.push(item);
            }
        });
        return ret;
    }
}

const listObj = new ListObj();
export default listObj