import React from "react";
import { withRouter } from 'react-router';
import { TELEFONE_CONTATO } from '../../components/funcs/constants';
import { REDES_SOCIAIS } from '../../components/funcs/constants';
import { FaWhatsapp } from 'react-icons/fa';



// CSSs
import "../../assets/template/assets/css/bootstrap.min.css";
import "../../assets/template/assets/css/font-awesome.min.css";
import "../../assets/template/assets/css/themify-icons.css";
import "../../assets/template/assets/css/magnific-popup.css";
import "../../assets/template/assets/css/owl.carousel.css";
import "../../assets/template/assets/css/owl.transitions.css";
import "../../assets/template/assets/css/plyr.css";
import "../../assets/template/assets/css/swiper.min.css";
import "../../assets/template/assets/css/slick.css";
import "../../assets/template/css/scheme/blue-green.css";
import "../../assets/template/css/style.css";
import "../../assets/template/css/responsive.css";
import '../../assets/template/assets/js/jquery.min.js';
import '../../assets/template/assets/js/bootstrap.min.js';
import '../../assets/template/assets/js/jquery.nav.js';
import '../../assets/template/assets/js/owl.carousel.js';
import '../../assets/template/assets/js/visible.js';
import '../../assets/template/assets/js/jquery.stellar.min.js';
import '../../assets/template/assets/js/jquery.countTo.js';
import '../../assets/template/assets/js/imagesloaded.pkgd.min.js';
import '../../assets/template/assets/js/isotope.pkgd.min.js';
import '../../assets/template/assets/js/jquery.magnific-popup.min.js';
import '../../assets/template/assets/js/jquery.ajaxchimp.min.js';
import '../../assets/template/assets/js/plyr.js';
import '../../assets/template/assets/js/swiper.min.js';
import '../../assets/template/assets/js/slick.min.js';


class Contato extends React.Component {

  state = {
    contato: TELEFONE_CONTATO,
  }

  constructor(props) {
    super(props);
    document.title = `Bluve - Contato`;
  }

  render() {
    return (
      <div>

        {/* Header */}
        <nav class="navbar navbar-default style-3" data-spy="affix" data-offset-top="60">
          <div class="container">
            <div class="navbar-header">
              <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <a class="navbar-brand" href="/home">
                <img src="https://storage.googleapis.com/bluve-bucket/logo_oficial_branca_com_azul.png"
                  className="bluveLogoTopo"
                  alt="Bluve Logo"></img>
              </a>
            </div>

            <div class="collapse navbar-collapse" id="navbar-collapse">
              <ul class="nav navbar-nav">
                <li><a href="/home">Home</a></li>
                {/* <li><a href="https://blog.bluve.com.br" target="_blank" rel="noopener noreferrer">Blog</a></li> */}
                {/* <li><a href="about-4.html">About</a></li>
                                <li><a href="service-3.html">Service</a></li>
                                <li><a href="testimonial-4.html">Testimonial</a></li>
                                <li><a href="pricing-4.html">Pricing</a></li>
                                <li><a href="contact-4.html">Contact</a></li> */}
              </ul>
              {/* <ul class="nav navbar-nav navbar-right">
                                <li><a href="login-4.html">Login</a></li>
                                <li class="login-item"><a href="registration-4.html">Sign up</a></li>
                            </ul> */}
            </div>
          </div>
        </nav>
        {/* Header End */}

        {/* Page Header */}
        <div class="page-header style-9">
          <div class="container">
          </div>
        </div>
        {/* Page Header End */}

        <div class="cps-main-wrap">

          {/* Call t- action /get started */}
          <div class="cps-cta cps-gray-bg">
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-xs-12 sm-text-center">
                  <h3 class="cps-cta-title">Contato</h3>
                  <p class="cps-cta-text">A tripulação Bluve tem uma única missão: Levar você mais longe através do seu negócio.</p>
                </div>
              </div>
            </div>
          </div>
          {/* Call t- action /get started end */}


          {/* Service Box */}
          <div class="cps-section cps-section-padding cps-bottom-0" id="service-box">
            <div class="container">
              <div class="row">
                <div class="cps-service-boxs">
                  {/* <div class="col-sm-4">
                    <div class="cps-service-box">
                      <div class="cps-service-icon">
                        <span class="ti-location-pin"></span>
                      </div>
                      <h4 class="cps-service-title">Endereço</h4>
                      <p class="cps-service-text">Rua Yeda, 595 - Tijuca, Teresópolis, RJ - CEP 25975-560<br />Teresópolis, RJ CEP 25.953-232</p>
                    </div>
                  </div> */}
                  <div className="col-sm-4">
                    <div className="cps-service-box">
                      <div className="cps-service-icon">
                        <FaWhatsapp />
                      </div>
                      <h4 className="cps-service-title">Comercial & Suporte</h4>
                      <p className="cps-service-text">+55 21 4040-2424</p>
                    </div>
                  </div>

                  <div class="col-sm-4">
                    <div class="cps-service-box">
                      <div class="cps-service-icon">
                        <span class="ti-email"></span>
                      </div>
                      <h4 class="cps-service-title">Email</h4>
                      <p class="cps-service-text">contato@4cr.com.br</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Service Box End */}

          {/* Location Map */}
          <div class="cps-section map-area" id="map-area">
            <div class="google-map office-location" id="location" data-lat="23.75043" data-lng="90.38844" data-zoom="16"></div>
          </div>
          {/* Location Map End */}
        </div>



        <footer>
          <div className="cps-footer-upper">
            <div className="container">
              <div className="cps-footer-widget-area">
                <div className="row">
                  <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="cps-widget about-widget">
                      <a className="cps-footer-logo" href="/home">
                        <img
                          className="bluveLogoTopo"
                          alt="Logo Bluve Rodapé"
                          src="https://storage.googleapis.com/bluve-bucket/b_oficial.png"
                        ></img>
                      </a>

                      <p>{REDES_SOCIAIS}</p>
                      <div className="cps-socials">
                        <a href="https://www.facebook.com/bluvebr" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
                        <a href="https://www.instagram.com/bluvebr" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>
                      </div>
                    </div>
                  </div>


                  <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="cps-widget custom-menu-widget">
                      <h4 className="cps-widget-title">Mais</h4>
                      <ul className="widget-menu">
                        <li><a href="/sobre">Sobre nós</a></li>
                        <li><a href="/produtos">Nossos produtos</a></li>
                        <li><a href="/time">Nosso time</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cps-footer-lower">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-xs-12 xs-text-center">
                  <p className="copyright">Copyright {new Date().getFullYear()} - 4C Retail Solutions,{" "}
                    <a href="/home">Bluve</a>. Todos os direitos reservados
                  </p>
                </div>
                <div className="col-sm-6 col-xs-12 text-right xs-text-center">
                  <ul className="footer-menu">
                    <li><a href="/privacidade">Política de privacidade</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }

}

export default withRouter(Contato);
