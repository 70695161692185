import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { notification } from 'antd';
import { loginPrimeiroAcesso } from 'pages/login/LoginF';

import history from '../../history';
import Kba4c from './package/src';

import SelecionarTipoConta from './steps/selecionarTipoConta/SelecionarTipoConta';
import InformarCnpjCpf from './steps/informarCnpjCpf/InformarCnpjCpf';
import ConfirmarEndereco from './steps/confirmarEndereco/ConfirmarEndereco';
import VerificarEmail from './steps/verificarEmail/VerificarEmail';
import CodigoConfirmacao from './steps/codigoConfirmacao/CodigoConfirmacao';
import CriarSenha from './steps/criarSenha/CriarSenha';

import './PrimeiroAcesso.css';

const kba = new Kba4c('bluve');

class PrimeiroAcesso extends Component {
  state = {
    tipoUsuario: '',
    historicoId: '',
    enderecos: [],
    step: 0,
    stepSum: 1,
    lastStep: 6,
    loading: false
  }

  constructor(props) {
    super(props);

    this.onClickButton = this.onClickButton.bind(this);
    this.message = this.message.bind(this);
  }

  async onClickButton(handleConfirm) {
    this.setState({ loading: true });

    await handleConfirm()
      .then(async (res) => {
        const {
          tipoUsuario,
          historicoId,
          message,
          token
        } = res;

        if (token) {
          await loginPrimeiroAcesso(token);

          history.push('/dashboard');
          history.go();

          return;
        }

        if (message) {
          this.message('success', 'Tudo Certo!', message);
        }

        if (tipoUsuario) {
          this.setState({ tipoUsuario });
        }

        if (historicoId) {
          this.setState({ historicoId });

          const enderecos = await kba.getAddresses(historicoId);

          if (enderecos) {
            this.setState({ enderecos });
          } else {
            let { step } = this.state;
            step = 3;

            this.setState({
              step,
              loading: false,
              stepSum: 0,
              lastStep: 5,
            });

            return
          }
        }

        let { step } = this.state;
        step++;

        this.setState({ step, loading: false });
      })
      .catch((error) => {
        const {
          type,
          title,
          description,
          tentativas
        } = error;

        this.message(type, title, description);

        if (tentativas) {
          this.message(
            'error',
            tentativas.title,
            tentativas.message
          );

          if (tentativas.bloqueado) {
            setTimeout(() => {
              history.push('/home');
              history.go();
            }, 5000);
          }
        }

        this.setState({ loading: false });
      });
  }

  message(type, title, description) {
    notification[type]({
      message: [title],
      description: [description],
      duration: 3
    });
  }

  render() {
    return (
      <div className='componentContainer'>
        <div className='header' >
          <div className='logo'>
            <div className='logoLayoutMasterPrimeiroAcesso'>
              <img alt='Bluve' />
            </div>
          </div>
        </div>

        <div className='body'>
          {this.state.step === 0 && (
            <SelecionarTipoConta
              onClickButton={this.onClickButton}
              loading={this.state.loading}
            />
          )}

          {this.state.step === 1 && (
            <InformarCnpjCpf
              kba={kba}

              loading={this.state.loading}
              tipoUsuario={this.state.tipoUsuario}

              onClickButton={this.onClickButton}
            />
          )}

          {this.state.step === 2 && (
            <ConfirmarEndereco
              kba={kba}

              loading={this.state.loading}
              historicoId={this.state.historicoId}

              onClickButton={this.onClickButton}
              enderecos={this.state.enderecos}
            />
          )}

          {this.state.step === 3 && (
            <VerificarEmail
              kba={kba}

              loading={this.state.loading}
              historicoId={this.state.historicoId}

              onClickButton={this.onClickButton}
            />
          )}

          {this.state.step === 4 && (
            <CodigoConfirmacao
              kba={kba}

              loading={this.state.loading}
              historicoId={this.state.historicoId}

              onClickButton={this.onClickButton}
            />
          )}


          {this.state.step === 5 && (
            <CriarSenha
              kba={kba}

              loading={this.state.loading}
              historicoId={this.state.historicoId}

              onClickButton={this.onClickButton}
            />
          )}
        </div>

        <div className='footer'>
          Etapa {this.state.step + this.state.stepSum} de {this.state.lastStep}
        </div>
      </div>
    )
  }
}


export default withRouter(PrimeiroAcesso);
