import DataManager from "./dtm";
import { fbDatabase, fbFirestore } from "../firebase/firebase";
import { getGestorId } from "../components/auth/auth-provider";


class MotivoDB extends DataManager {

    state = {
        collection: 'motivos',
        orderBy: ['nome'], // usado na classe pai
    }

    async getByGestorIdCustom(gestorId, ativoFiltro) {
        let query = fbDatabase.collection(this.state.collection)
            .where("gestorId", "==", gestorId)
            .where("ativo", "==", !ativoFiltro);

        if (this.state.orderBy.length > 0) {
            for (let index = 0; index < this.state.orderBy.length; index++) {
                const order = this.state.orderBy[index];
                query = query.orderBy(order);
            }
        }

        const snapshot = await query.get();

        if (snapshot.empty) {
            return undefined;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByGestorIdWithoutOrderBy(gestorId) {
        let query = fbDatabase.collection(this.state.collection)
            .where("gestorId", "==", gestorId);

        const snapshot = await query.get();

        if (snapshot.empty) {
            return undefined;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data()
        }));

        return data;
    }

    async getByNome(nome) {
        const gestorId = getGestorId();
        const snapshot = await fbDatabase
            .collection(this.state.collection)
            .where("gestorId", "==", gestorId)
            .where('nome', "==", nome)
            .get();

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data()
        }));

        return data;
    }

    async monitorarMotivos(gestorId, callback) {
        const monitoramentoTempoReal = fbDatabase
            .collection(this.state.collection)
            .where("gestorId", "==", gestorId)
            .where("ativo", "==", true)
            .onSnapshot((querySnapshot) => {
                if (callback) {
                    callback(querySnapshot);
                }
            });
        return monitoramentoTempoReal;
    }


};

const motivoDB = new MotivoDB();
export default motivoDB;