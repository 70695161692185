import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button } from 'antd';
import CNPJInput from 'components/input/cnpj';
import CPFInput from 'components/input/cpf';


class InformarCnpjCpf extends Component {
  state = {
    cnpj: '',
    cpf: '',
    isGestor: true
  }

  constructor(props) {
    super(props);

    this.kba = this.props.kba;
    this.tipoUsuario = this.props.tipoUsuario;
    this.onClickButton = this.props.onClickButton;

    this.handleConfirm = this.handleConfirm.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  componentDidMount() {
    const isGestor = this.tipoUsuario === 'Gestor';
    this.setState({ isGestor });
  }

  async handleConfirm() {
    return new Promise(async (resolve, reject) => {
      const { cnpj, cpf, isGestor } = this.state;

      if (cnpj === '' && cpf === '') {
        reject({
          type: 'error',
          title: `${isGestor ? 'CNPJ' : 'CPF'} Não Informado`,
          description: 'Digite-o no campo abaixo.'
        });

        return;
      }

      try {
        const value = isGestor ? cnpj : cpf;

        const {
          docId: historicoId,
          ativo,
          bloqueado
        } = await this.kba.getUser(value);

        if (bloqueado) {
          reject({
            type: 'error',
            title: 'Usuário Bloqueado',
            description: 'O número de tentativas de confirmação de informação foi excedido.'
          });

          return;
        }

        if (!ativo) {
          reject({
            type: 'error',
            title: 'Primeira Acesso já Realizado',
            description: `O usuário já fez a etapa de primeiro acesso. Caso não se lembre da senha da conta, clique em 'Esqueci minha senha' na página de login.`
          });

          return;
        }

        resolve({
          historicoId
        });
      } catch (error) {
        reject({
          type: 'error',
          title: `Erro ao buscar usuário pelo ${isGestor ? 'CNPJ' : 'CPF'}`,
          description: error
        });
      }
    })
  }

  async onChangeInput(e, campo) {
    const { value } = e.target;
    this.setState({ [campo]: value });
  }

  render() {
    return (
      <div className='stepContainer'>
        <div className='textContainer'>
          <div className='title'>
            Informe o {this.state.isGestor ? 'CNPJ da empresa' : 'CPF do usuário'}
          </div>

          <div className='description'>
            Precisamos dessa informação para localizar o seu cadastro.
          </div>

          <div className='description bold'>
            Digite-o no campo a seguir:
          </div>
        </div>

        {this.state.isGestor ?
          <CNPJInput
            className='stepInput'
            placeholder='CNPJ'

            value={this.state.cnpj}
            onChange={(e) => this.onChangeInput(e, 'cnpj')}
          /> :
          <CPFInput
            className='stepInput'
            placeholder='CPF'

            value={this.state.cpf}
            onChange={(e) => this.onChangeInput(e, 'cpf')}
          />
        }

        <Button
          className='buttonConfirm'
          type='primary'

          loading={this.props.loading}
          onClick={() => this.onClickButton(this.handleConfirm)}
        >
          Próximo
        </Button>
      </div>
    )
  }
}


export default withRouter(InformarCnpjCpf);
