import React from 'react';
import BluveLayoyt from '../../../components/layout/BluveLayout';
import bluveStorage from '../../../components/localstorage/localstorage';
import { withRouter } from 'react-router';
import { Select, DatePicker, Card, Col, Row, Table, message, Button, Avatar, Progress, Divider, Icon } from 'antd';
import { getEmpresas, getVendedores, buildReport, exportarExcel } from './Funcs-Metas';
import { getUserType, getGestorId } from '../../../components/auth/auth-provider';
import funcoes from "../../../components/funcs/funcoes";
import moment from 'moment';
import './Metas.css'

const { Option } = Select;
const { MonthPicker } = DatePicker;
const dateFormat = 'MM/YYYY';

class Metas extends React.Component {

  state = {
    loading: false,
    loadingExportacao: false,
    empresaId: '',
    vendedorId: '',
    vendedorSelecionado: {},
    empresas: [],
    mes: null,
    periodo: '',
    columns: [],
    showCheckBox: false,
    checked: false,
    alterouConfiguracaoRelatorio: false,
    dashboardVendedor: false,
    tableData: [],
    cardsData: {}
  }

  optionsEmpresas = []

  optionsVendedores = []

  optionsPeriodo = [
    <Option key="todos" value="todos">Todos</Option>,
    <Option key="1" value="1">1</Option>,
    <Option key="2" value="2">2</Option>,
    <Option key="3" value="3">3</Option>,
    <Option key="4" value="4">4</Option>
  ]

  defaultCardsData = {
    metaTotal: 'Não há dados.',
    metaAcumulada: 'Não há dados.',
    metaPorcentagem: 'Não há dados.'
  }

  constructor(props) {
    super(props);
    this.onDateChange = this.onDateChange.bind(this);
    this.onSelectPeriodoChange = this.onSelectPeriodoChange.bind(this);
    this.onSelectCompanyChange = this.onSelectCompanyChange.bind(this);
    this.onSelectVendedorChange = this.onSelectVendedorChange.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onExportClick = this.onExportClick.bind(this);

    this.state.cardsData = this.defaultCardsData;
  }

  async componentDidMount() {
    const gestorId = await getGestorId();

    const storage = bluveStorage.getEmpresasGestor('@gestorEmpresas');
    let empresas = [];
    let vendedores = [];

    if (storage && storage.gestorId === gestorId && storage.empresas) {
      empresas = storage.empresas;
    } else {
      empresas = await getEmpresas();

      const data = {
        gestorId: gestorId,
        empresas: empresas
      };

      bluveStorage.setEmpresasGestor(data);
    }

    const userType = await getUserType();
    if (userType === 'Gestor') {
      this.optionsEmpresas.push(<Option key={'todas'} value={'todas'}>Todas</Option>);
      this.optionsVendedores.push(<Option key={'todos'} value={'todos'}>Todos</Option>);
    }

    for (const item of empresas) {
      this.optionsEmpresas.push(<Option key={item.key} value={item.key}>{item.login.login}</Option>)
    }

    if (!empresas.length) {
      empresas.push({
        id: 'Nenhuma empresa cadastrada',
        razaoSocial: 'Nenhuma empresa cadastrada'
      })
    }

    let empresaId = bluveStorage.getReportEmpresaId();
    if (!empresaId) {
      empresaId = empresas[0].key
    } else {
      if (empresaId !== 'todas') {
        empresaId = bluveStorage.getReportEmpresaId();
      }
    }

    if (storage && storage.gestorId === gestorId && storage.vendedores) {
      vendedores = storage.vendedores;
    } else {
      vendedores = await getVendedores(empresaId) ?? [];

      const data = {
        gestorId: gestorId,
        vendedores: vendedores
      };

      bluveStorage.setVendedoresGestor(data);
    }

    if (!vendedores.length) {
      vendedores.push({
        key: 'Nenhum vendedor cadastrado',
        nome: 'Nenhum vendedor cadastrado'
      })
    }

    for (const item of vendedores) {
      this.optionsVendedores.push(<Option key={item.key} value={item.key}>{item.nome}</Option>)
    }
    this.optionsVendedores.push(<Option key="todos" value="todos">Todos</Option>)


    const report = bluveStorage.getReportMetas();
    let cardsData = report.cardsData ? report.cardsData : this.defaultCardsData;
    let tableData = report.tableData ? report.tableData : [];
    let columns = report.columns ? report.columns : [];

    if (columns && columns[0]) {
      columns[0].render = (text, { vendedor }) => (
        <div className='vendedorAvatar'>
          <span style={{ marginRight: '1rem' }}>
            <Avatar size={42} src={vendedor.avatarUrl} />
          </span>

          <div>
            {text}
          </div>
        </div>
      );
    }

    let showCheckBox = bluveStorage.get('@reports/metas/showCheckBox');

    let mes = bluveStorage.get('@reports/metas/mes');
    if (!mes) {
      mes = moment();
    } else {
      mes = moment(mes);
    }

    let periodo = bluveStorage.get('@reports/metas/periodo');
    if (!periodo) {
      periodo = '1';
    }

    if (!showCheckBox) {
      showCheckBox = false;
    } else {
      showCheckBox = funcoes.strToBool(showCheckBox);
    }

    let checked = bluveStorage.get('@reports/metas/checked');
    if (!checked) {
      checked = false;
    } else {
      checked = funcoes.strToBool(checked);
    }

    this.setState({
      empresaId,
      vendedorId: 'todos',
      empresas: empresas,
      mes,
      periodo,
      cardsData,
      tableData,
      columns,
      showCheckBox,
      checked,
    });

  }

  async onFilterClick() {
    if (!this.state.mes) {
      message.warning('Selecione o mês para consultar as metas.');
      return;
    }

    if (!this.state.periodo) {
      message.warning('Selecione o período que deseja consultar.');
      return;
    }

    if (!this.state.empresaId) {
      message.warning('Selecione a empresa que deseja consultar.');
      return;
    }

    this.setState({ loading: true })
    setTimeout(async () => {
      const columns = this.buildColumns();
      const data = await buildReport(
        this.state.empresaId,
        this.state.mes,
        this.state.periodo,
        this.state.checked
      );

      const cardsData = data.length !== 0 ? data[1] : this.defaultCardsData;
      const tableData = data.length !== 0 ? data[0] : [];

      if (this.state.alterouConfiguracaoRelatorio) {
        bluveStorage.clearAllReportsData();
      }

      bluveStorage.set('@reports/metas/empresaId', this.state.empresaId);
      bluveStorage.set('@reports/metas/showCheckBox', this.state.showCheckBox);
      bluveStorage.set('@reports/metas/checked', this.state.checked);
      bluveStorage.set('@reports/metas/mes', this.state.mes);
      bluveStorage.set('@reports/metas/periodo', this.state.periodo)
      if (this.state.empresaId !== 'todas') {
        bluveStorage.setReportEmpresaId(this.state.empresaId);
      }
      bluveStorage.setReportMetas(cardsData, tableData, columns);

      let vendedorSelecionado = {};

      if (this.state.vendedorId !== 'todos') {
        tableData.forEach((item) => {
          if (item.vendedor.id === this.state.vendedorId) {
            vendedorSelecionado = { ...item };
            if (vendedorSelecionado.fuga === 'NaN% / 0%') {
              vendedorSelecionado.fuga = '0% / 0%';
            }
          }
        });
      }

      this.setState({
        loading: false,
        dashboardVendedor: this.state.vendedorId !== 'todos',
        vendedorSelecionado,
        tableData,
        cardsData,
        columns
      });

      if (!data || data.length === 0) {
        message.info('A empresa selecionada não possui metas cadastradas para o mês solicitado.');
        return;
      }
    }, 500)
  }

  async onExportClick() {
    this.setState({ loadingExportacao: true });

    const {
      tableData,
      cardsData,
      empresas,
      empresaId,
      mes,
      periodo,
      vendedorSelecionado
    } = this.state;

    const data = this.state.vendedorId !== 'todos' ? [vendedorSelecionado] : tableData;
    let nomeEmpresa = '';

    empresas.forEach((item) => {
      if (item.key === empresaId) {
        nomeEmpresa = item.razaoSocial;
      }
    });

    await exportarExcel(data, nomeEmpresa, cardsData, mes, periodo);

    this.setState({ loadingExportacao: false });
  }

  onCheckBoxChange(e) {
    this.setState({ checked: e.target.checked });
  }

  async onSelectCompanyChange(empresaId) {
    if (empresaId !== 'todas') {
      this.setState({ alterouConfiguracaoRelatorio: true });

      const vendedores = await getVendedores(empresaId);

      const data = {
        gestorId: getGestorId(),
        vendedores: vendedores
      };

      bluveStorage.setVendedoresGestor(data);

      this.optionsVendedores = [];

      const userType = await getUserType();
      if (userType === 'Gestor') {
        this.optionsVendedores.push(<Option key={'todos'} value={'todos'}>Todos</Option>);
      }

      for (const item of vendedores) {
        this.optionsVendedores.push(<Option key={item.key} value={item.key}>{item.nome}</Option>)
      }
    }

    this.setState({
      empresaId,
      vendedorId: empresaId === 'todas' ? 'todos' : this.state.vendedorId,
      showCheckBox: empresaId === 'todas',
      checked: empresaId === 'todas'
    })
  }

  onSelectVendedorChange(value) {
    if (value !== 'todas') {
      this.setState({ alterouConfiguracaoRelatorio: true });
    }

    this.setState({
      vendedorId: value
    })
  }

  onSelectPeriodoChange(value) {
    this.setState({ periodo: value, alterouConfiguracaoRelatorio: true });
  }

  onDateChange(value) {
    this.setState({ mes: value, alterouConfiguracaoRelatorio: true })
  }

  buildColumns() {
    let columns = [
      {
        title: 'Vendedor',
        dataIndex: 'vendedor.apelido',
        key: 'vendedor.apelido',
        render: (text, { vendedor }) => (
          <div className='vendedorAvatar'>
            <span style={{ marginRight: '1rem' }}>
              <Avatar size={42} src={vendedor.avatarUrl} />
            </span>

            <div>
              {text}
            </div>
          </div>
        )
      },
      {
        title: 'Meta',
        dataIndex: 'metaVendedor',
        key: 'metaVendedor'
      },
      {
        title: 'Vendas Acumuladas',
        dataIndex: 'metaAcumuladaVendedor',
        key: 'metaAcumuladaVendedor'
      },
      {
        title: '% Meta',
        dataIndex: 'metaPorcentagemVendedor',
        key: 'metaPorcentagemVendedor'
      },
      {
        title: 'Ticket Médio (Vendedor / Loja / Meta)',
        dataIndex: 'ticketMedio',
        key: 'ticketMedio'
      },
      {
        title: 'Nº de Atendimentos',
        dataIndex: 'numeroAtendimentos',
        key: 'numeroAtendimentos'
      },
      {
        title: 'P.A',
        dataIndex: 'produtosPorAtendimento',
        key: 'produtosPorAtendimento'
      },
      {
        title: 'Fuga',
        dataIndex: 'fuga',
        key: 'fuga'
      },
      {
        title: 'Prêmio',
        dataIndex: 'premioAcumulado',
        key: 'premioAcumulado'
      }
    ]

    if (this.state.empresaId === 'todas' && this.state.checked) {
      columns.splice(2, 0, {
        title: 'Empresa',
        dataIndex: 'empresaId',
        key: 'empresaId'
      });
    }

    return columns;
  }


  render() {
    return (
      <div>
        <BluveLayoyt selectItem={"metas/relatorio"}>
          <div className="containerFiltros">
            <div className='containerFilterOption'>
              <label>Empresa:</label>
              <Select
                showSearch
                optionFilterProp="children"
                placeholder='Selecione uma empresa'
                disabled={this.state.loading}
                value={this.state.empresaId}
                onChange={this.onSelectCompanyChange}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
              >
                {this.optionsEmpresas}
              </Select>
            </div>

            <div className='containerFilterOption'>
              <label>Vendedor:</label>
              <Select
                showSearch
                optionFilterProp="children"
                placeholder='Selecione um vendedor'
                disabled={this.state.loading || this.state.empresaId === '' || this.state.empresaId === 'todas'}
                value={this.state.vendedorId}
                onChange={this.onSelectVendedorChange}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
              >
                {this.optionsVendedores}
              </Select>
            </div>

            <div className='containerFilterOption'>
              <label>Mês:</label>
              <MonthPicker
                placeholder="Selecione o mês"
                format={dateFormat}
                disabled={this.state.loading}
                value={this.state.mes}
                onChange={this.onDateChange}
                style={{ width: '100%' }}
              />
            </div>

            <div className='containerFilterOption'>
              <label>Período:</label>
              <Select
                placeholder="Selecione o período"
                optionFilterProp="children"
                disabled={this.state.loading}
                value={this.state.periodo}
                onChange={this.onSelectPeriodoChange}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: '100%' }}
              >
                {this.optionsPeriodo}
              </Select>
            </div>

            <Button
              type='primary'
              loading={this.state.loading}
              onClick={this.onFilterClick}
            >
              Filtrar
            </Button>

            <Button
              className='excel-button'
              disabled={this.state.loading}
              loading={this.state.loadingExportacao}
              onClick={this.onExportClick}
              style={{ marginLeft: '1rem' }}
            >
              Exportar para Excel
            </Button>
          </div>

          {!this.state.dashboardVendedor && (
            <div>
              <div className="cardsMetas">
                <Row gutter={16}>
                  <Col span={8}>
                    <Card
                      disabled={this.state.loading}
                      title="Meta"
                    >
                      {this.state.cardsData.metaTotal}
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card
                      disabled={this.state.loading}
                      title="Meta Acumulada"
                    >
                      {this.state.cardsData.metaAcumulada}
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card
                      disabled={this.state.loading}
                      title="% Meta"
                    >
                      {this.state.cardsData.metaPorcentagem}
                    </Card>
                  </Col>
                </Row>
              </div>

              <div className="tableMetas">
                <Table
                  columns={this.state.columns}
                  rowClassName={(record) => record.color.replace('#', '')}
                  pagination={{ hideOnSinglePage: true }}
                  dataSource={this.state.tableData}
                  bordered
                  rowKey={record => record.key}
                />
              </div>
            </div>
          )}

          {this.state.dashboardVendedor && (
            <div>
              <VendedorMeta
                vendedor={this.state.vendedorSelecionado.vendedor}
                meta={this.state.vendedorSelecionado.metaVendedor}
                metaPorcentagem={this.state.vendedorSelecionado.metaPorcentagemVendedor}
                vendasAcumuladas={this.state.vendedorSelecionado.metaAcumuladaVendedor}
                ticketMedio={this.state.vendedorSelecionado.ticketMedio}
                numeroAtendimentos={this.state.vendedorSelecionado.numeroAtendimentos}
                produtosPorAtendimento={this.state.vendedorSelecionado.produtosPorAtendimento}
                fuga={this.state.vendedorSelecionado.fuga}
                premioAcumulado={this.state.vendedorSelecionado.premioAcumulado}
              />
            </div>
          )}

        </BluveLayoyt>
      </div>
    );
  }
}

class VendedorMeta extends React.Component {
  state = {
    vendedorId: '',
    ticketMedio: {
      vendedor: '',
      empresa: '',
      color: '#000000'
    },
    numeroAtendimentos: {
      vendedor: '',
      empresa: '',
      color: '#000000'
    },
    produtosPorAtendimento: {
      vendedor: '',
      empresa: '',
      color: '#000000'
    },
    fuga: {
      vendedor: '',
      empresa: '',
      color: '#000000'
    }
  }

  constructor(props) {
    super(props);
    this.prepararInfoComponente = this.prepararInfoComponente.bind(this);
    this.getInfoColor = this.getInfoColor.bind(this);
    this.getArrow = this.getArrow.bind(this);
    this.formatarNumero = this.formatarNumero.bind(this);
  }

  componentDidMount() {
    this.setState({ vendedorId: this.props.vendedor.id });
  }

  prepararInfoComponente(value, field) {
    const values = value.split(' / ');

    this.setState({
      [field]: {
        vendedor: values[0],
        empresa: values.length === 2 ? values[1] : `${values[1]} / ${values[2]}`,
        color: this.getInfoColor(values[0], values[1])
      }
    });
  }

  getInfoColor(valor, valorComparativo) {
    let color = '#000000A6';

    const valorF = this.formatarNumero(valor);
    const valorComparativoF = this.formatarNumero(valorComparativo);

    if (valorF > valorComparativoF) {
      color = 'green';
    } else if (valorF < valorComparativoF) {
      color = 'red';
    }

    return color;
  }

  formatarNumero(numero) {
    const novoNumero = Number(
      numero
        .replace('R$ ', '')
        .replace(',', '.')
        .replace('%', '')
    );

    return novoNumero;
  }

  getArrow(color, inverter = false) {
    if (color === 'green') {
      return (inverter ? <Icon type='arrow-down' /> : <Icon type='arrow-up' />);
    } else if (color === 'red') {
      return (inverter ? <Icon type='arrow-up' /> : <Icon type='arrow-down' />);
    }
  }

  render() {
    if (this.props.vendedor.id !== this.state.vendedorId) {
      let {
        ticketMedio,
        numeroAtendimentos,
        produtosPorAtendimento,
        fuga
      } = this.props;

      if (!isNaN(numeroAtendimentos)) {
        numeroAtendimentos = numeroAtendimentos + ' / 0';
      }

      const [ticketMedioVendedor, ticketMedioEmpresa, ticketMedioMeta] = ticketMedio.split(' / ');
      const [numeroAtendimentosVendedor, numeroAtendimentosEmpresa] = numeroAtendimentos.split(' / ');
      const [produtosPorAtendimentoVendedor, produtosPorAtendimentoEmpresa] = produtosPorAtendimento.split(' / ');
      const [fugaVendedor, fugaEmpresa] = fuga.split(' / ');

      this.setState({
        vendedorId: this.props.vendedor.id,
        ticketMedio: {
          vendedor: ticketMedioVendedor,
          empresa: `${ticketMedioEmpresa} / ${ticketMedioMeta}`,
          color: this.getInfoColor(ticketMedioVendedor, ticketMedioEmpresa)
        },
        numeroAtendimentos: {
          vendedor: numeroAtendimentosVendedor,
          empresa: numeroAtendimentosEmpresa,
          color: this.getInfoColor(numeroAtendimentosVendedor, numeroAtendimentosEmpresa)
        },
        produtosPorAtendimento: {
          vendedor: produtosPorAtendimentoVendedor,
          empresa: produtosPorAtendimentoEmpresa,
          color: this.getInfoColor(produtosPorAtendimentoVendedor, produtosPorAtendimentoEmpresa)
        },
        fuga: {
          vendedor: fugaVendedor,
          empresa: fugaEmpresa,
          color: this.getInfoColor(fugaEmpresa, fugaVendedor)
        }
      })
    }

    return (
      <div className='vendedorMeta'>
        <div className='vendedorContainer'>
          <Avatar
            size={250}
            src={this.props.vendedor.avatarUrl}
            style={{ marginBottom: '1rem' }}
          />

          <div className='vendedorInfo'>
            <div>
              <label>Nome:</label>
              {this.props.vendedor.nome}
            </div>

            <div>
              <label>Ativo:</label>
              {this.props.vendedor.ativo ? 'Vendedor(a) Ativo' : 'Vendedor(a) Inativo'}
            </div>

            <div>
              <label>ID Externo:</label>
              {this.props.vendedor.pdvId ?? '-'}
            </div>
          </div>
        </div>

        <Divider className='dividerContainer' type='vertical' />

        <div className='progressContainer'>
          <Progress
            type='circle'
            strokeColor='#1890ff'
            width='25rem'
            percent={Number(this.props.metaPorcentagem.replace('%', ''))}
          />

          <div>
            <div className='metaInfo'>
              <label>Meta</label>
              {this.props.meta}
            </div>

            <div className='metaInfo'>
              <label>Vendas Acumuladas</label>
              {this.props.vendasAcumuladas}
            </div>

            <div className='metaInfo'>
              <label>Prêmio</label>
              {this.props.premioAcumulado}
            </div>
          </div>
        </div>

        <Divider className='dividerContainer' type='vertical' />

        <div className='metaInfoContainer containerBorder'>
          <div className='containerTitle'>
            Vendedor
          </div>

          <div className='metaInfo'>
            <label>Ticket Médio</label>

            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                color: this.state.ticketMedio.color
              }}
            >
              {this.state.ticketMedio.vendedor}
              {this.getArrow(this.state.ticketMedio.color)}
            </span>
          </div>

          <div className='metaInfo'>
            <label>Nº de Atendimentos</label>

            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                color: this.state.numeroAtendimentos.color
              }}
            >
              {this.state.numeroAtendimentos.vendedor}
              {this.getArrow(this.state.numeroAtendimentos.color)}
            </span>
          </div>

          <div className='metaInfo'>
            <label>Produtos por Atendimento</label>

            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                color: this.state.produtosPorAtendimento.color
              }}
            >
              {this.state.produtosPorAtendimento.vendedor}
              {this.getArrow(this.state.produtosPorAtendimento.color)}
            </span>
          </div>

          <div className='metaInfo'>
            <label>Fuga</label>

            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                color: this.state.fuga.color
              }}
            >
              {this.state.fuga.vendedor}
              {this.getArrow(this.state.fuga.color, true)}
            </span>
          </div>
        </div>

        <Divider className='dividerContainer' type='vertical' />

        <div className='metaInfoContainer'>
          <div className='containerTitle'>
            Loja/Meta
          </div>

          <div className='metaInfo'>
            <label>Ticket Médio</label>
            {this.state.ticketMedio.empresa}
          </div>

          <div className='metaInfo'>
            <label>Nº de Atendimentos</label>
            {this.state.numeroAtendimentos.empresa}
          </div>

          <div className='metaInfo'>
            <label>Produtos por Atendimento</label>
            {this.state.produtosPorAtendimento.empresa}
          </div>

          <div className='metaInfo'>
            <label>Fuga</label>
            {this.state.fuga.empresa}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Metas)
