import React, { Component } from "react";
import { Modal, notification, message, Checkbox } from "antd";
const { warning } = Modal;
const { confirm } = Modal;

class Message extends Component {
  async avisar(message) {
    return new Promise((resolve, reject) => {
      warning({
        title: "Atenção",
        content: message,
        async onOk() {
          resolve(true);
        },
      });
    }).catch((error) => {
      console.log("Oops errors!");
      return false;
    });
  }

  async confirmar(message) {
    return new Promise((resolve, reject) => {
      confirm({
        title: "Confirmação",
        content: message,
        okText: "Sim",
        okType: "danger",
        cancelText: "Não",
        async onOk() {
          resolve(true);
        },
        onCancel() {
          reject(false);
        }
      });
    }).catch((error) => {
      console.log("Oops errors!");
      return false;
    });
  }

  async confirmarCustom(message, title, okText, cancelText) {
    return new Promise((resolve, reject) => {
      confirm({
        title,
        okText,
        cancelText,
        content: message,
        okType: "danger",
        async onOk() {
          resolve(true);
        },
        onCancel() {
          reject(false);
        },
      });
    }).catch((error) => {
      console.log("Oops errors!");
      return false;
    });
  }

  async avisarCheckbox(message) {
    return new Promise((resolve, reject) => {
      if (cookieStorage.getItem('alert-checkbox') === 'true') return;

      warning({
        title: 'Atenção',
        content: (
          <div>
            <div>
              {message}
            </div>

            <div>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    cookieStorage.setItem('alert-checkbox', true);
                  } else {
                    cookieStorage.setItem('alert-checkbox', false)
                  }
                }}
                style={{ marginTop: '1rem' }}
              />
              &nbsp;Não exibir essa mensagem novamente
            </div>
          </div>
        ),
        async onOk() {
          resolve(true);
        },
      });
    }).catch((error) => {
      console.log("Oops errors!");
      return false;
    });
  }

  openNotificationWithIcon = (type, title, description, duration) => {
    if (!duration) {
      duration = 4; // this is the default time
    }
    notification[type]({
      message: [title],
      description: [description],
      duration,
    });
  };

  printFormErrors(errors, max, callback) {
    if (!max) max = 3;

    let counter = 1;

    const list = [];
    for (let key in errors) {
      list.push(key);
      if (counter > max) continue;
      //const msg = errors[key].errors[0].message;
      // message.error(msg);
      counter++;
    }

    const msg = list.length === 1 ? '1 campo obrigatório não preenchido.' : `${list.length} campos obrigatórios não preenchidos.`
    message.error(msg);
    if (callback) callback(list);
  }
}

const mensagem = new Message();
export default mensagem;

const cookieStorage = {
  getItem: (key) => {
    const cookies = document.cookie
      .split(';')
      .map(cookie => cookie.split('='))
      .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
    return cookies[key];
  },
  setItem: (key, value) => {
    document.cookie = `${key}=${value}`;
  }
}
