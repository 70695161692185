import React, { useState } from 'react';
import { Input, Modal, message } from 'antd';

function ImportarPercentuais(props) {

    const [abrirModal, setAbrirModal] = useState(true);
    const [texto, setTexto] = useState('');
    const [validDecimals, setValidDecimals] = useState([]);

    const onOk = () => {
        if (validDecimals.length !== props.dias) {
            message.error(`Quantidade de percentuais inválida. O período contém ${props.dias} dias e foram informados ${validDecimals.length} percentuais.`);
            return;
        }
        if (props.onPercentuaisImportados) {
            props.onPercentuaisImportados(validDecimals);
        }
        setAbrirModal(false);
    }

    const onCancel = () => {
        setAbrirModal(false);
    }

    const handleTextChange = (e) => {
        const text = e.target.value;
        const lines = text.split('\n');
        const validDecimals = [];
    
        lines.forEach((line) => {
            const decimalMatch = line.match(/[0-9]+(?:,[0-9]*)?/);
    
            if (decimalMatch && decimalMatch.length > 0) {
                const decimalValue = parseFloat(decimalMatch[0].replace(',', '.'));
    
                if (!isNaN(decimalValue) && decimalValue > 0) {
                    validDecimals.push(decimalValue);
                } else {
                    message.error(`Valor inválido encontrado na linha: "${line}"`);
                    return;
                }
            } else {
                message.error(`Valor decimal não encontrado na linha: "${line}"`);
                return;
            }
        });
    
        console.log('Quantidade de linhas:', lines.length);
        console.log('dias a dias:', props.dias);
        console.log('Valores válidos:', validDecimals);
    
        setTexto(e.target.value);
        setValidDecimals(validDecimals);
    }
    

    return (
        <Modal
            title="Importar Percentuais"
            visible={abrirModal}
            onCancel={onCancel}
            onOk={onOk}
            width={800}
            okText="Importar"
            cancelText="Cancelar"
        >
            <p>O período contém {props.dias} dias. Importe um percentual para dia. Cada percentual deve ser mantido em uma linha.</p>

            <Input.TextArea
                value={texto}
                onChange={handleTextChange}
                placeholder="Digite o texto aqui"
                autoSize={{ minRows: 4, maxRows: 10 }}
            />

        </Modal>
    );
}

export default ImportarPercentuais;