import React from 'react';
import { withRouter } from 'react-router'
import { Table, Icon, Button, Modal, Input, Tooltip, Checkbox, message } from 'antd';
import { getGestorId, getUserType } from '../../components/auth/auth-provider';
import './Motivo.css';
import "antd/dist/antd.css";
import MotivoCRUD from "../../components/cruds/motivos/MotivoCRUD";
import BluveLayout from "../../components/layout/BluveLayout";
import history from "../../history";
import listObj from "../../components/listSearch/listSearch";
import mensagem from "components/message/Message";
import API from 'services/api/api';

const { warning } = Modal;
const { Search } = Input;
const api = new API();


class Motivo extends React.Component {
  state = {
    motivos: [],
    motivosOriginal: [],
    motivosLista: [],
    loading: true,
    checked: undefined
  };

  constructor(props) {
    super(props);
    this.applyFilter = this.applyFilter.bind(this);
    this.updateList = this.updateList.bind(this);
    this.getCheckboxState = this.getCheckboxState.bind(this);
    this.checkboxUpdate = this.checkboxUpdate.bind(this);
    this.headerMotivoElement = React.createRef();
  }

  componentDidMount() {
    if (getUserType() !== "Gestor") {
      warning({
        title: "Atenção",
        content: "Você não tem permissão para acessar esta área",
        async onOk() {
          history.push("/dashboard");
          history.go();
        },
        async onCancel() {
          history.push("/dashboard");
          history.go();
        },
      });
    } else {
      document.addEventListener('tokenAvailable', () => this.getMotivos());
    }
  }

  async applyFilter(text) {
    if (text === "") {
      this.setState({ motivos: this.state.motivosOriginal });
      return;
    }

    const keys = ["nome", "dificuldade", "ativoStr"];

    let motivos = [];
    if (!this.state.checked && this.state.motivosLista && this.state.motivosLista.length > 0) {
      motivos = listObj.search(this.state.motivosLista, text, keys);
    } else {
      motivos = listObj.search(this.state.motivosOriginal, text, keys);
    }

    this.setState({ motivos });
  }

  updateList(record) {
    record["ativoStr"] = record["ativo"] ? "Sim" : "Não";
    let registroEncontrado = false;
    let listaAtivos = [];
    let listaInativos = [];

    if (this.state.checked) {
      listaInativos = this.state.motivos;
      listaAtivos = this.state.motivosLista;
    } else if (this.state.motivosLista.length) {
      listaAtivos = this.state.motivos;
      listaInativos = this.state.motivosLista;
    } else {
      listaAtivos = this.state.motivos;
    }

    if (listaAtivos) {
      listaAtivos.forEach((item, index) => {
        if (item.key === record.key) {
          if (!record['ativo']) {
            listaAtivos.splice(index, 1);
          } else {
            listaAtivos[index] = record;
          }
          registroEncontrado = true;
        }
      });
    }

    if (listaInativos.length) {
      listaInativos.forEach((item, index) => {
        if (item.key === record.key) {
          if (record['ativo']) {
            listaAtivos.push(record);
          }

          listaInativos[index] = record;
          registroEncontrado = true;
        }
      });
    }

    if (!registroEncontrado && record['ativo']) {
      listaAtivos.unshift(record);
      if (listaInativos.length) listaInativos.unshift(record);
    } else if (!registroEncontrado && !record['ativo'] && listaInativos.length) {
      listaInativos.push(record)
    }

    if (this.state.checked) {
      this.setState({ motivos: listaInativos, motivosOriginal: listaInativos, motivosLista: listaAtivos });
    } else {
      this.setState({ motivos: listaAtivos, motivosOriginal: listaAtivos, motivosLista: listaInativos });
    }
  }

  getCheckboxState(checked) {
    this.setState({ checked }, () => {
      this.checkboxUpdate();
    });
  }

  async checkboxUpdate() {
    if (this.state.motivosLista.length || !this.state.checked && this.state.motivos) {
      this.setState({ loading: true });
      const motivosLista = this.state.motivosLista;
      const motivos = this.state.motivos;

      this.setState({ motivos: motivosLista, motivosOriginal: motivosLista, motivosLista: motivos, loading: false })
    } else if (this.state.checked) {
      this.setState({ loading: true });
      let motivos = this.state.motivos;
      const gestorId = getGestorId();

      api.get({ gestorId, ativo: false }, '/motivos')
        .then((novaLista) => {
          if (motivos && novaLista) {
            this.setState({ motivosLista: motivos });
            motivos = motivos.concat(novaLista);
          } else if (novaLista) {
            motivos = novaLista;
          } else {
            this.setState({ motivosLista: motivos });
          }

          this.setState({ motivos: motivos, motivosOriginal: motivos, loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
          message.error("Erro ao buscar motivos");
        });
    }
  }

  async getMotivos() {
    const gestorId = getGestorId();

    api.get({ gestorId, ativo: true }, '/motivos')
      .then((motivos) => {
        this.setState({ motivos, motivosOriginal: motivos, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
        message.error("Erro ao buscar motivos");
      });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  editarMotivosElement(record) {
    this.headerMotivoElement.current.editarMotivo(record);
  }

  render() {
    const columns = [
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
      },

      {
        title: "Dificuldade de Solução",
        dataIndex: "dificuldade",
        key: "dificuldade",
      },
      {
        title: "Ativo?",
        dataIndex: "ativoStr",
        key: "ativo",
      },
      {
        title: "",
        width: "20px",
        key: "action",
        render: (text, record) => (
          <span>
            <Tooltip placement="topLeft" title="Editar Cadastro">
              <a onClick={() => this.editarMotivosElement(record)}>
                <Icon type="edit" />
              </a>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <div className="divTable">
        <BluveLayout selectItem={"motivos"}>
          <div className="noteMessageBox">
            <p>Os motivos são públicos para todas as empresas</p>{" "}
          </div>

          <Table
            pagination={{
              defaultPageSize: 100,
              position: "both",
              pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
            }}
            title={() => (
              <HeaderMotivo
                ref={this.headerMotivoElement}
                applyFilter={this.applyFilter}
                updateList={this.updateList}
                getCheckboxState={this.getCheckboxState}
              />
            )}
            columns={columns}
            dataSource={this.state.motivos}
            loading={this.state.loading}
            bordered
          />
        </BluveLayout>
      </div>
    );
  }
}

class HeaderMotivo extends React.Component {
  constructor(props) {
    super(props);
    this.novoMotivoElement = React.createRef();

    this.state = {
      visible: false,
      checked: false,
      loading: false
    };

    this.novoMotivo = this.novoMotivo.bind(this);
    this.filterTable = this.filterTable.bind(this);
    this.onCheck = this.onCheck.bind(this);

    let timer;
  }

  novoMotivo() {
    this.novoMotivoElement.current.show(false);
  }

  editarMotivo(record) {
    this.novoMotivoElement.current.show(true, record);
  }

  filterTable(value) {
    const { applyFilter } = this.props;

    const text = typeof value === "object" ? value.target.value : value;
    if (text && text.length < 3) return;

    let time = 1000;


    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.setState({ loading: true });
      applyFilter(text);
      this.setState({ loading: false });
    }, time);
  }

  onCheck(e) {
    const checked = e.target.checked;
    this.setState({ checked }, () => {
      this.props.getCheckboxState(checked);
    });
  }
  
  render() {
    return (
      <div>
        <NovoMotivo
          ref={this.novoMotivoElement}
          updateList={this.props.updateList}
        ></NovoMotivo>
        <Button type="primary" onClick={this.novoMotivo}
        ><Icon className="icon" type="plus" /> Novo Motivo
        </Button>

        <Search
          placeholder="Procurar"
          onSearch={this.filterTable}
          onChange={this.filterTable}
          loading={this.state.loading}
          style={{ width: 200, marginLeft: "10px", marginRight: '1rem' }}
        />

        <Checkbox checked={this.state.checked} onChange={this.onCheck}>
          Listar Inativos
        </Checkbox>

      </div>
    );
  }
}

class NovoMotivo extends React.Component {
  state = {
    closable: true,
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onCloseHandle = this.onCloseHandle.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record,
    });
  }

  handleOk(record) {
    this.props.updateList(record);
    this.setState({ visible: false, closable: true });
  }

  onCloseHandle(canClose) {
    this.setState({ closable: canClose });
  }

  async handleCancel() {
    if (!this.state.closable) return;
    if (!(await mensagem.confirmar("Cancelar alterações?"))) return;
    this.setState({ visible: false, closable: true });
  }

  render() {
    return (
      <Modal
        title="Motivo"
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        closable={this.state.closable}
      >
        <MotivoCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          editMode={this.state.editMode}
          record={this.state.record}
          onCloseHandle={this.onCloseHandle}
        ></MotivoCRUD>
      </Modal>
    );
  }
}

export default withRouter(Motivo);
