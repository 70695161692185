import {
  getGestorId,
  getUserType,
  getGerenteId,
} from "../../../components/auth/auth-provider";
import empresaDB from "../../../dataManager/dtmEmpresa";
import { GERENTE } from "../../../components/funcs/constants";
import gerenteDB from "../../../dataManager/dtmGerente";
import metaDB from "../../../dataManager/dtmMeta";
import rankingDB from "../../../dataManager/dtmRanking";
import moment from 'moment';
import vendedorDB from '../../../dataManager/dtmVendedor';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';


export function getNextColor(color) {
  if (color === "#fff") {
    color = "#eee";
  } else {
    color = "#fff";
  }
  return color;
}

export async function getEmpresas() {
  const gestorId = getGestorId();
  const empresas = await empresaDB.getByGestorIdCustom(gestorId, 'login.login');
  const newEmpresas = [];
  const userType = getUserType();

  if (userType === GERENTE) {
    const gerenteId = getGerenteId();
    const empresasGerente = await gerenteDB.getEmpresasGerente(gerenteId, 'razaoSocial');

    for (let i = 0; i < empresasGerente.length; i++) {
      const empresa = empresas.filter((item) => {
        return item.key === empresasGerente[i].id;
      });

      if (empresa && empresa.length > 0) {
        newEmpresas.push(empresa[0]);
      }
    }
  }
  return newEmpresas.length > 0 ? newEmpresas : empresas;
}

export async function getVendedores(empresaId) {
  const vendedores = await vendedorDB.getByEmpresaIdAndAtivo(empresaId, true);
  return vendedores;
}

async function getVendas(empresaId, dataIni, dataFim) {

  const vendas = await rankingDB.getByEmpresaIdAndPeriodo(empresaId, dataIni, dataFim);

  let sumTotal = 0;

  const sumPorVendedor = vendas.reduce((result, venda) => {

    const id = venda.vendedorId;
    result[id] = result[id] || [];
    result['total'] = result['total'] || [];

    result[id] = {
      id: id,
      empresaId: venda.empresaId,
      valor: result[id].valor ? result[id].valor + venda.atendimentos.valor : venda.atendimentos.valor,
      qtdVendas: result[id].qtdVendas ? result[id].qtdVendas + venda.atendimentos.conversoes : venda.atendimentos.conversoes,
      qtdAtendimentos: result[id].qtdAtendimentos ? result[id].qtdAtendimentos + venda.atendimentos.quantidade : venda.atendimentos.quantidade,
      qtdPecas: result[id].qtdPecas ? result[id].qtdPecas + venda.atendimentos.pecas : venda.atendimentos.pecas,
      qtdFugas: result[id].qtdFugas ? result[id].qtdFugas + venda.atendimentos.fugas ?? 0 : venda.atendimentos.fugas ?? 0,
    }

    result['total'] = {
      valor: result['total'].valor ? result['total'].valor + venda.atendimentos.valor : venda.atendimentos.valor,
      qtdVendas: result['total'].qtdVendas ? result['total'].qtdVendas + venda.atendimentos.conversoes : venda.atendimentos.conversoes,
      qtdAtendimentos: result['total'].qtdAtendimentos ? result['total'].qtdAtendimentos + venda.atendimentos.quantidade : venda.atendimentos.quantidade,
      qtdPecas: result['total'].qtdPecas ? result['total'].qtdPecas + venda.atendimentos.pecas : venda.atendimentos.pecas,
      qtdFugas: result['total'].qtdFugas ? result['total'].qtdFugas + venda.atendimentos.fugas ?? 0 : venda.atendimentos.fugas ?? 0,
    }

    return result
  }, {});

  await vendas.map((venda) => {
    sumTotal += venda.atendimentos.valor;
  })

  const item = {
    sumPorVendedor: sumPorVendedor,
    sumTotal: sumTotal
  }

  return item;
}

export async function buildReport(empresaId, mes, periodo, separaEmpresas) {
  const ano = mes.year();
  mes = mes.month() + 1;

  const meta = await metaDB.getByData(mes, ano, empresaId);

  const currentDate = new Date();

  if (!meta) {
    return [];
  }

  let dataIni = '';
  let dataFim = '';

  let metaTotal = 0;
  let metaPorcentagem = 0;
  let metaAcumulada = 0;
  let vendas = '';
  let vendasTotal = '';

  let element = {};

  let metaVendedores = {};

  const ticketMedio = meta.objetivo.ticketMedio ? formatarMoeda(meta.objetivo.ticketMedio) : 0;
  const numeroAtendimentos = meta.objetivo.numeroAtendimentos ? meta.objetivo.numeroAtendimentos : 0;
  const percentualDeFuga = meta.objetivo.percentualDeFuga ?? 0;

  if (periodo !== 'todos') {
    dataIni = new Date(meta.periodos[periodo - 1].inicio.seconds * 1000);
    dataFim = new Date(meta.periodos[periodo - 1].fim.seconds * 1000);

    vendas = await getVendas(empresaId, dataIni, dataFim);
    vendasTotal = { ...vendas.sumPorVendedor.total };
    delete vendas.sumPorVendedor.total;


    metaTotal = meta.periodos[periodo - 1].valorTotal;
    metaAcumulada = vendas.sumTotal;
  } else {
    dataIni = (moment([ano, mes - 1])).toDate();
    dataFim = (moment(dataIni).endOf('month')).toDate();

    vendas = await getVendas(empresaId, dataIni, dataFim);
    vendasTotal = { ...vendas.sumPorVendedor.total };
    delete vendas.sumPorVendedor.total;

    metaTotal = meta.objetivo.principal;
    metaAcumulada = vendas.sumTotal;
  }

  metaVendedores = await Promise.all(Object.values(meta.vendedores).map(async (vendedor) => {
    const metaTotalVendedor = periodo !== 'todos' ? vendedor.meta.periodos[periodo - 1] : vendedor.meta.total;
    const infoVendedor = await vendedorDB.getByIdOnCache(vendedor.id);
    const ticketLoja = vendasTotal.qtdVendas > 0 ? formatarMoeda(vendasTotal.valor / vendasTotal.qtdVendas) : formatarMoeda(0);
    const produtosPorAtendimentoLoja = vendasTotal.qtdAtendimentos > 0 ? Number(vendasTotal.qtdPecas / vendasTotal.qtdAtendimentos).toFixed(2).replace('.', ',') : Number(0).toFixed(2).replace('.', ',');

    element = {
      empresaId: meta.empresaId,
      vendedor: {
        id: vendedor.id,
        ativo: infoVendedor.ativo,
        pdvId: infoVendedor.pdvId,
        apelido: infoVendedor.apelido,
        nome: infoVendedor.nome,
        avatarUrl: infoVendedor.avatar
      },
      metaVendedor: formatarMoeda(metaTotalVendedor),
      metaAcumuladaVendedor: formatarMoeda(0),
      metaPorcentagemVendedor: metaTotalVendedor === 0 ? 'A meta deste vendedor ainda não foi definida' : '0%',
      ticketMedio: ticketMedio === 0 ? formatarMoeda(0) : `${formatarMoeda(0)} / ${ticketLoja} /  ${ticketMedio}`,
      numeroAtendimentos: numeroAtendimentos === 0 ? 0 : 0 + ' / ' + numeroAtendimentos,
      produtosPorAtendimento: `0 / ${produtosPorAtendimentoLoja}`,
      premioAcumulado: (currentDate < dataFim) ? 'O período ainda não foi finalizado.' : formatarMoeda(0),
      fuga: `0% / ${percentualDeFuga}%`
    }

    if (vendas) {
      for (let i of Object.values(vendas.sumPorVendedor)) {
        if (vendedor.id === i.id) {
          if (i.valor) {
            const ticketVendedor = formatarMoeda(i.valor / i.qtdVendas);

            element.metaAcumuladaVendedor = formatarMoeda(i.valor);
            element.metaPorcentagemVendedor = metaTotalVendedor === 0 ? 'A meta deste vendedor ainda não foi definida' : calcularPorcentagem(metaTotalVendedor, i.valor)
            element.ticketMedio = `${ticketVendedor} / ${ticketLoja} / ${ticketMedio}`;
            element.numeroAtendimentos = numeroAtendimentos === 0 ? i.qtdAtendimentos : i.qtdAtendimentos + ' / ' + numeroAtendimentos;
            element.produtosPorAtendimento = `${Number(i.qtdPecas / i.qtdAtendimentos).toFixed(2).replace('.', ',')} / ${produtosPorAtendimentoLoja}`;
            element.fuga = `${Number(((isNaN(i.qtdFugas) ? 0 : i.qtdFugas) * 100) / i.qtdAtendimentos).toFixed(2).replace('.', ',')}% / ${percentualDeFuga}%`;

            if (meta.premios) {
              if (metaTotalVendedor === 0) {
                element.premioAcumulado = 'A meta deste vendedor ainda não foi definida'
              } else {

                element.premioAcumulado = (currentDate < dataFim) ? 'O período ainda não foi finalizado.' : calcularPremio(meta, i, metaTotalVendedor);
              }
            }
          }
        }
      }
    }

    return element;
  }));

  const metaEmpresa = {
    metaTotal: formatarMoeda(metaTotal),
    metaAcumulada: formatarMoeda(metaAcumulada),
    metaPorcentagem: calcularPorcentagem(metaTotal, metaAcumulada)
  }

  metaVendedores.sort((a, b) => (a.vendedor.nome > b.vendedor.nome ? 1 : -1));

  let novaMetaVendedores = [];
  let counter = 1;
  let color = '#fff';

  metaVendedores.forEach((item) => {
    item["key"] = counter;
    counter++;
    item["color"] = color;
    color = getNextColor(color);
    novaMetaVendedores.push(item);
  });

  const lista = [novaMetaVendedores, metaEmpresa];

  return lista;
}

export async function exportarExcel(data, nomeEmpresa, cardsData, mes, periodo) {
  const fileName = `Relatório de Meta ${periodo !== 'todos' ? `Período ${periodo}` : ''}, ${mes.format('MM-YYYY')} - ${nomeEmpresa}`;
  const infoExcel = [];

  if (data.length > 1) {
    const {
      metaTotal,
      metaAcumulada,
      metaPorcentagem
    } = cardsData;

    infoExcel.push(['Empresa', 'Meta', 'Meta Acumulada', '% Meta']);
    infoExcel.push([nomeEmpresa, metaTotal, metaAcumulada, metaPorcentagem]);
    infoExcel.push(['']);
  }

  infoExcel.push(['Vendedor', 'Meta', 'Vendas Acumuladas', '% Meta', 'Ticket Médio', 'Nº de Atendimentos', 'Fuga', 'Prêmio']);

  data.forEach((item) => {
    const {
      vendedor,
      metaVendedor,
      metaAcumuladaVendedor,
      metaPorcentagemVendedor,
      ticketMedio,
      numeroAtendimentos,
      fuga,
      premioAcumulado
    } = item;

    infoExcel.push([
      vendedor.nome,
      metaVendedor,
      metaAcumuladaVendedor,
      metaPorcentagemVendedor,
      ticketMedio,
      numeroAtendimentos,
      fuga,
      premioAcumulado
    ]);
  });

  await salvarXlsx(infoExcel, fileName);
}

export async function salvarXlsx(infoExcel, fileName) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const worksheet = XLSX.utils.aoa_to_sheet(infoExcel);
  worksheet['!cols'] = new Array(8).fill({ width: 20 });

  const woorkbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

  const excelBuffer = XLSX.write(woorkbook, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, fileName);
}

function calcularPremio(meta, vendedor, metaTotalVendedor) {
  const numeroAtendimentos = meta.objetivo.numeroAtendimentos;
  const metaPrincipal = meta.objetivo.principal;
  const metaAdicional = meta.objetivo.adicional;
  const ticketMedio = meta.objetivo.ticketMedio;
  const premios = meta.premios;

  const atendimentosVendedor = vendedor.qtdVendas;
  const valorVendedor = vendedor.valor;
  const ticketVendedor = (vendedor.valor / vendedor.qtdVendas);
  let premioVendedor = 0;

  if (numeroAtendimentos && numeroAtendimentos !== 0 && premios.numeroAtendimentos > 0 && atendimentosVendedor >= numeroAtendimentos) {
    premioVendedor += premios.numeroAtendimentos;
  }

  if (premios.metaPrincipal && valorVendedor >= metaTotalVendedor) {
    premioVendedor += premios.metaPrincipal;
  }

  if (premios.ticketMedio && ticketVendedor >= ticketMedio) {
    premioVendedor += premios.ticketMedio;
  }

  if (metaAdicional && !metaAdicional.proporcional) {
    const valorMetaAd = metaAdicional.valores - metaPrincipal;
    if (vendedor.valor >= (valorMetaAd + metaTotalVendedor)) {
      const vezesMetaBatida = Math.floor((vendedor.valor - metaTotalVendedor) / valorMetaAd);
      const premio = premios.metasAdicionais * vezesMetaBatida;
      premioVendedor += premio;
    }
  } else if (metaAdicional && metaAdicional.proporcional) {
    if (vendedor.valor > metaTotalVendedor) {
      const diferenca = (vendedor.valor - metaTotalVendedor) * 100 / metaTotalVendedor;
      if (diferenca >= metaAdicional.porcentagem) {
        const vezesMetaBatida = Math.floor((diferenca / metaAdicional.porcentagem));
        const premio = premios.metasAdicionais * vezesMetaBatida;
        premioVendedor += premio;
      }
    }
  }

  return formatarMoeda(premioVendedor);

}

function calcularPorcentagem(valorTotal, valor) {
  return ((valor * 100) / valorTotal).toFixed(0) + '%'
}

function formatarMoeda(valor) {
  return valor !== undefined ? valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0;
}
