import React from "react";
import BluveLayout from "../../../components/layout/BluveLayout";
import { withRouter } from "react-router";
import { Table, Button, Input, Modal, message } from "antd";
import './HistoricoLogin.css';
import { buildReport } from './HistoricoLoginF';
import { getUserType } from "components/auth/auth-provider";
import history from '../../../history';

const { Search } = Input;
const { warning } = Modal;


class HistoricoLogin extends React.Component {
    state = {
        tableData: [],
        loading: false,
        columns: [
            {
                title: 'Conta',
                dataIndex: 'tipo',
                key: 'tipo',
                sorter: true,
                sortDirections: ["descend", "ascend"],
            },
            {
                title: 'Nome ou Razão Social',
                dataIndex: 'nome',
                key: 'nome',
                sorter: true,
                sortDirections: ["descend", "ascend"],
            },
            {
                title: 'email',
                dataIndex: 'email',
                key: 'email',
                sorter: true,
                sortDirections: ["descend", "ascend"],
            },
            {
                title: 'Último Login',
                dataIndex: 'ultimoLogin',
                key: 'ultimoLogin',
                sorter: true,
                sortDirections: ["descend", "ascend"],
            },
        ]
    }

    options = [];

    constructor(props) {
        super(props);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.filterTable = this.filterTable.bind(this);
    }

    componentDidMount() {

        if (getUserType() !== 'Gestor') {

            warning({
                title: 'Atenção',
                content: 'Você não tem permissão para acessar esta área',
                async onOk() {
                    history.push('/dashboard');
                    history.go();
                },
                async onCancel() {
                    history.push('/dashboard');
                    history.go();
                },
            });
        }
    }

    async onFilterClick() {
        this.setState({ loading: true });
        message.info('Gerando relatório, aguarde...');
        const tableData = await buildReport();
        const originalTableData = tableData;
        this.setState({ tableData, originalTableData, loading: false });
    }

    onTableHandleChange = (pagination, filters, sorter) => {
        if (!sorter.order) {
            return;
        }

        const tableData = this.state.tableData;
        if (sorter.order == 'ascend') {

            if (sorter.columnKey === 'tipo') {
                tableData.sort(function (a, b) {
                    return a.tipo < b.tipo ? -1 : 1;
                });
            }

            if (sorter.columnKey === 'nome') {
                tableData.sort(function (a, b) {
                    return a.nome < b.nome ? -1 : 1;
                });
            }

            if (sorter.columnKey === 'email') {
                tableData.sort(function (a, b) {
                    return a.email < b.email ? -1 : 1;
                });
            }

            if (sorter.columnKey === 'ultimoLogin') {
                tableData.sort(function (a, b) {
                    return a.ultimoLogin < b.ultimoLogin ? -1 : 1;
                });
            }
        } else {

            if (sorter.columnKey === 'tipo') {
                tableData.sort(function (a, b) {
                    return a.tipo < b.tipo ? 1 : -1;
                });
            }

            if (sorter.columnKey === 'nome') {
                tableData.sort(function (a, b) {
                    return a.nome < b.nome ? 1 : -1;
                });
            }

            if (sorter.columnKey === 'email') {
                tableData.sort(function (a, b) {
                    return a.email < b.email ? 1 : -1;
                });
            }

            if (sorter.columnKey === 'ultimoLogin') {
                tableData.sort(function (a, b) {
                    return a.ultimoLogin < b.ultimoLogin ? 1 : -1;
                });
            }
        }
    }

    filterTable(value) {
        const text = typeof value === "object" ? value.target.value : value;
        const tableData = this.state.originalTableData.filter((item) =>
            Object.values(item).some(
                (val) => typeof val === "string" && val.toLowerCase().includes(text.toLowerCase())
            )
        );
        this.setState({ tableData });
    }

    render() {

        return (
            <div>
                <BluveLayout selectItem={"adm/historico-logins"}>

                    <div className="containerForaEstoque">
                        <Button type='primary' disabled={this.state.loading} onClick={this.onFilterClick}>Filtrar</Button>
                    </div>

                    <div className="divTableGrafico">

                        <Search
                            placeholder="Procurar"
                            onSearch={this.filterTable}
                            onChange={this.filterTable}
                            style={{ width: 400, marginLeft: "10px" }}
                        />
                        <br></br>
                        <br></br>

                        <Table
                            loading={this.state.loading}
                            columns={this.state.columns}
                            rowClassName={(record) => record.color.replace('#', '')}
                            pagination={{ hideOnSinglePage: true }}
                            dataSource={this.state.tableData}
                            bordered
                            rowKey={record => record.key}
                            onChange={this.onTableHandleChange}
                        />
                        <br></br>
                        <p><strong>Total: </strong>{this.state.tableData.length} registros</p>
                    </div>

                </BluveLayout>
            </div>
        );
    }
}


export default withRouter(HistoricoLogin);
