import React from 'react';
import { Button } from 'antd';

import './cookiesAlert.css'


const cookieStorage = {
  getItem: (key) => {
    const cookies = document.cookie
      .split(';')
      .map(cookie => cookie.split('='))
      .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
    return cookies[key];
  },
  setItem: (key, value) => {
    let time = new Date();
    time.setTime(time.getTime() + 365 * 86400 * 1000);
    document.cookie = `${key}=${value};expires=${time.toUTCString()}`;
  }
}

class CookiesAlert extends React.Component {
  state = {
    visible: !cookieStorage.getItem('privacy-policy-message'),
    loading: false
  }

  static defaultProps = {
    title: "Aviso sobre Cookies",
    color: "#1890FF",
    text: "Este site usa cookies para melhorar sua experiência de navegação através de ferramentas de terceiros.",
    href: "/privacidade",
    hrefTitle: "Política de Privacidade",
    buttonTitle: "Aceitar e Fechar"
  }

  constructor(props) {
    super(props);
    this.buttonClick = this.buttonClick.bind(this);
    this.saveToStorage = this.saveToStorage.bind(this);

  }

  buttonClick() {
    this.setState({ loading: true });
    setTimeout(() => {
      this.saveToStorage();
      this.setState({ loading: false, visible: false });
    }, 1500);
  }

  saveToStorage() {
    cookieStorage.setItem('privacy-policy-message', true);
  }

  render() {
    return (
      <div>
        {this.state.visible ?
          <div className="cookieAlertBox">
            <div
              className="cookieAlertTitle"
              style={{ color: this.props.color }}>

              {this.props.title}
            </div>

            <div className="cookieAlertText">
              <p>{this.props.text}</p>

              <p>Saiba mais em <a href={this.props.href}><b style={{ color: this.props.color }}>
                {this.props.hrefTitle}
              </b></a>.</p>
            </div>

            <Button
              className="cookieAlertButton"
              type="primary"
              onClick={this.buttonClick}
              loading={this.state.loading}>

              {this.props.buttonTitle}
            </Button>
          </div>
          : null}
      </div>
    )
  }
}

export default CookiesAlert;
