import React from 'react';
import { withRouter } from 'react-router';
import { Layout, Menu, Dropdown, Divider, Avatar, Button, Badge, Icon } from 'antd';
import history from '../../history';
import './BluveLayout.css';
import {
  currentUser,
  logout,
  getUserType,
} from '../../components/auth/auth-provider';
import { VERSAO } from '../../components/funcs/constants';
import { DEFAULT_ATATAR } from 'components/funcs/constants';
import WhatsNew from './WhatsNew';
import bluveStorage from 'components/localstorage/localstorage';
import CookiesAlert from 'components/cookiesAlert/cookiesAlert';
import ReleaseeNotes from './ReleaseeNotes';
import { fbDatabase, fireBase } from "../../firebase/firebase";


const { Header, Content } = Layout;
const { SubMenu } = Menu;

const cookieStorage = {
  getItem: (key) => {
    const cookies = document.cookie
      .split(';')
      .map(cookie => cookie.split('='))
      .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
    return cookies[key];
  },
  setItem: (key, value) => {
    let time = new Date();
    time.setTime(time.getTime() + 365 * 86400 * 1000);
    document.cookie = `${key}=${value};expires=${time.toUTCString()}`;
  }
}

class BluveLayout extends React.Component {
  state = {
    collapsed: false,
    friendlyName: '',
    classLogo: 'labelLogoShow',
    classLabelImage: 'logoLayoutMaster',
    photoURL: '',
    userType: 'Gestor',
    whatsNew: {
      visible: false,
      cookieName: 'newsMaio2023'
    },
    showReleaseNotes: false,
    evaluation: false
  };

  constructor(props) {
    super(props);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.state.userType = getUserType();
    this.showWhatsNewsElement = React.createRef();
    this.showReleaseNotesElement = React.createRef();
    this.showWhatsNews = this.showWhatsNews.bind(this);
    this.showReleaseNotes = this.showReleaseNotes.bind(this);
    this.realTime = this.realTime.bind(this);
  }

  async componentDidMount() {
    const user = currentUser();

    this.setState({
      photoURL: user.photoURL ? user.photoURL : DEFAULT_ATATAR,
    });

    this.realTime(user.email);

    // Controle de acesso
    const { uid } = user;

    //   fbDatabase
    //     .collection('x-controle-sessao')
    //     .doc(uid)
    //     .onSnapshot(async (doc) => {
    //       const { refreshToken } = currentUser().stsTokenManager;
    //       const { lastRefreshToken } = doc.data();
    //       if (lastRefreshToken !== refreshToken) {
    //         logout();

    //         await fireBase.auth().signOut();
    //         history.push('/home/?sessionRevoked=true');
    //         history.go();
    //       }
    //     });
  }

  realTime(email) {
    fbDatabase
      .collection("clientes")
      .where('email', '==', email)
      .onSnapshot(async (doc) => {
        if (doc.docs[0]) {
          const evaluation = !doc.docs[0].data().ativo;
          this.setState({ evaluation });
        }
      });


  }

  showWhatsNews() {
    const whatsNew = this.state.whatsNew;
    whatsNew.visible = false;
    bluveStorage.set(this.state.whatsNew.cookieName, true);
    this.setState({ whatsNew });
  }

  showReleaseNotes(showReleaseNotes) {
    this.setState({ showReleaseNotes });
  }

  onMenuClick(item) {
    // Para que o usuário veja o item ficar azul antes do redirect.
    // Não remover
    setTimeout(() => {
      if (
        (item.item.props && item.item.props.children === 'Novidades') ||
        (item.item.props && item.item.props.children === 'Manual') ||
        (item.item.props && item.item.props.children === 'Notas da Versão')
      ) {
        return;
      }
      history.push(`/${item.key}`);
      history.go();
    }, 0);
  }

  handleMenuClick(e) {
    console.clear();
    if (e.key === 'menuSair') {
      logout();
      history.push('/home');
      history.go();
    }
  }

  render() {
    let showWhatsNew = bluveStorage.get(this.state.whatsNew.cookieName);

    const whatsNew = this.state.whatsNew;
    whatsNew.visible = !showWhatsNew;

    const profileMenu = <Profile userType={this.state.userType}></Profile>;

    const handleClickHelpMenu = (e) => {
      try {
        window.open('https://share.nuclino.com/p/Documentao-Bluve-h4MeSiSTISKSJW1FhkvlhK', '_blank');
      } catch (error) {
        debugger;
        console.log(error);
      }
      return false;
    };

    return (
      <React.Fragment>
        <Layout>
          <Layout style={{ height: '100vh' }}>
            <Header
              style={{
                padding: 0,
                display: 'flex',
                height: '5rem',
                backgroundColor: '#143361',
              }}
            >
              <div className="logo">
                <div className={this.state.classLabelImage}>
                  <img alt="Bluve"></img>
                </div>
              </div>

              {/* <WhatsNew
                ref={this.showWhatsNewsElement}
                visible={this.state.whatsNew.visible}
                onCancel={this.showWhatsNews}
              ></WhatsNew>
 */}
              <ReleaseeNotes
                ref={this.showReleaseNotesElement}
                visible={this.state.showReleaseNotes}
                onCancel={this.showReleaseNotes}
              ></ReleaseeNotes>

              <Menu
                defaultSelectedKeys={[this.props.selectItem]}
                defaultOpenKeys={[this.props.openMenu]}
                onClick={this.onMenuClick}
                theme="dark"
                mode="horizontal"
                className='bluvelayout-menu'
              >
                <Menu.Item key="dashboard">
                  <span>Dashboard</span>
                </Menu.Item>

                <SubMenu
                  key="crudMenu"
                  title={
                    <span>
                      <span>Cadastros</span>
                    </span>
                  }
                >

                  <Menu.Item key="empresas">Empresas</Menu.Item>

                  {this.state.userType === 'Gestor' ? (
                    <Menu.Item key="gerentes">Gerentes</Menu.Item>
                  ) : (
                    ''
                  )}
                  <Menu.Item key="vendedores">Vendedores</Menu.Item>

                  {this.state.userType === 'Gestor' ? (
                    <Menu.Item key="produtos">Produtos</Menu.Item>
                  ) : (
                    ''
                  )}

                  {this.state.userType === 'Gestor' ? (
                    <Menu.Item key="motivos">Motivos</Menu.Item>
                  ) : (
                    ''
                  )}

                  <Menu.Item key="fora-de-mix">Fora de Mix</Menu.Item>
                </SubMenu>

                <SubMenu
                  key="relatorios"
                  title={
                    <span>
                      <span>Relatórios</span>
                    </span>
                  }
                >

                  <Menu.Item key="relatorio/vendas-perdidas">
                    Vendas Perdidas
                  </Menu.Item>

                  <Menu.Item key="relatorio/taxa-conversao">
                    Taxa de Conversão
                  </Menu.Item>

                  <Menu.Item key="relatorio/taxa-conversao-vendedor">
                    Conversão Individual
                  </Menu.Item>

                  <Menu.Item key="relatorio/desempenho-individual">
                    Desempenho Individual
                  </Menu.Item>

                  <Menu.Item key='relatorio/rotina-vendedor'>
                    Rotina do Vendedor
                  </Menu.Item>

                  <Menu.Item key="relatorio/produtos-motivos">
                    Produtos x Motivos
                  </Menu.Item>

                  <Menu.Item key="relatorio/motivo-produto">
                    Motivos x Produtos
                  </Menu.Item>

                  <Menu.Item key="relatorio/extrato-atendimentos">
                    Extrato de Atendimentos
                  </Menu.Item>


                  <Menu.Item key="relatorio/fora-mix">
                    Fora do Mix
                  </Menu.Item>

                  {/*<Menu.Item key="relatorio/evolucao-vendedores">Evolução dos Vendedores</Menu.Item>*/}
                  {/*<Menu.Item key="relatorio/historico-atendimento">Histórico de Atendimentos</Menu.Item>*/}
                </SubMenu>

                <SubMenu
                  key="crudParceiros"
                  title={
                    <span>
                      <span>Parceiros</span>
                    </span>
                  }
                >
                  <Menu.Item key="parceiros/lojas">Lojas</Menu.Item>
                  <Menu.Item key="parceiros/indicadores">Indicadores</Menu.Item>
                  <Menu.Item key="parceiros/pagamentos">Pagamentos</Menu.Item>
                  <Menu.Item key="parceiros/indicacoes-totais">
                    Indicações Totais
                  </Menu.Item>
                  <Menu.Item key="parceiros/indicacao-loja">
                    Venda por Indicação
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="crudMetas"
                  title={
                    <span>
                      <span>Metas</span>
                    </span>
                  }
                >
                  <Menu.Item key="metas/cadastro">Cadastro</Menu.Item>
                  <Menu.Item key="metas/relatorio">
                    Relatório de Metas
                  </Menu.Item>
                </SubMenu>


                {this.state.userType === 'Gestor' ? (

                  <SubMenu
                    key="ferramentas"
                    title={
                      <span>
                        <span>Ferramentas</span>
                      </span>
                    }
                  >
                    <Menu.Item key="adm/historico-logins">Histórico de Logins</Menu.Item>

                  </SubMenu>
                ) : (
                  ''
                )}

                {this.state.userType === 'Gestor' ? (
                  <Menu.Item key="configuracao">Configurações</Menu.Item>
                ) : (
                  ''
                )}

              </Menu>
              <Button
                className="bluvelayout-help-button"
                onClick={handleClickHelpMenu}
              >
                <Icon type="question-circle" />
              </Button>

              <Dropdown overlay={profileMenu} trigger={['click']}>
                <Avatar
                  className="profileAvatar"
                  size={44}
                  src={this.state.photoURL}
                />
              </Dropdown>
            </Header>

            <Content style={{ margin: '1rem' }}>
              {this.state.evaluation ? (
                <div className="alertaCompra">
                  <p>Sua conta está inativa para uso, mas você ainda pode acessar o <strong>Bluve</strong> para analisar seus dados gerados anteriormente e, até mesmo, fazer cadastros. Porém, suas empresas não conseguirão fazer login para utilizar a Fila da Vez e a integração de vendas não permanecerá ativa. Volte a utilizar o <strong>Bluve</strong> agora mesmo! <strong><a href="https://api.whatsapp.com/send?phone=+5521983813710&text=Olá! Como faço para assinar o Bluve?" target="_blank">Clique aqui</a></strong> para conversar com a gente no Whats! 😉</p>
                </div>
              ) : (
                ''
              )}

              <div
                style={{
                  background: '#fff',
                  minHeight: '100%',
                  padding: '2rem',
                  borderRadius: '1.2rem',
                }}
              >
                {this.props.children}
              </div>
            </Content>
          </Layout>
        </Layout>

        <CookiesAlert></CookiesAlert>
      </React.Fragment>
    );
  }
}

class Profile extends React.Component {
  state = {
    photoUrl: '',
    userType: '',
    userName: '',
    userEmail: '',
  };

  UNSAFE_componentWillMount() {
    const user = currentUser();
    this.setState({
      photoURL: user.photoURL ? user.photoURL : DEFAULT_ATATAR,
      userType: this.props.userType,
      userName: user.displayName ? user.displayName : 'Desconhecido',
      userEmail: user.email,
      versao: VERSAO,
    });
  }

  async btnSairClick() {
    logout();
    await fireBase.auth().signOut();
    history.push('/home');
    history.go();
  }

  btnPerfilClick() {
    history.push('/perfil');
    history.go();
  }

  render() {
    return (
      <div className="card-profile">
        <img
          className="img-profile"
          src={this.state.photoURL}
          alt={this.state.userName}
        />
        <h3>{this.state.userName}</h3>
        <p className="title-profile">{this.state.userType}</p>
        <p>{this.state.userEmail}</p>

        <Divider></Divider>

        <p>
          <Button
            type="primary"
            className="buttonProfile"
            onClick={this.btnPerfilClick}
          >
            Perfil
          </Button>
        </p>
        <p>
          <Button
            type="danger"
            className="buttonProfileExit"
            onClick={this.btnSairClick}
          >
            Sair
          </Button>
        </p>
      </div>
    );
  }
}

export default withRouter(BluveLayout);
