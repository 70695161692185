import React from "react";
import { withRouter } from "react-router";
import { Table, Button, Icon, Modal, Avatar, Input, Tooltip, Checkbox } from "antd";
import "antd/dist/antd.css";
import "./Indicador.css";
import IndicadorCRUD from "../../../components/cruds/parceiros/indicadores/IndicadorCRUD";
import {
  getGestorId,
  getUserType,
} from "../../../components/auth/auth-provider";
import BluveLayout from "../../../components/layout/BluveLayout";
import lojaDB from "../../../dataManager/dtmLoja";
import { GESTOR } from "../../../components/funcs/constants";
import indicadorDB from "../../../dataManager/dtmIndicador";
import listObj from "../../../components/listSearch/listSearch";
import mensagem from "components/message/Message";
const { Search } = Input;

class Indicador extends React.Component {
  state = {
    indicadores: [],
    indicadoresOriginal: [],
    indicadoresLista: [],
    lojas: [],
    canUpdate: false,
    loading: true,
    checked: undefined
  };

  constructor(props) {
    super(props);
    this.headerElement = React.createRef();
    this.applyFilter = this.applyFilter.bind(this);
    this.updateList = this.updateList.bind(this);
    this.loadLojas = this.loadLojas.bind(this);
    this.getCheckboxState = this.getCheckboxState.bind(this);
    this.checkboxUpdate = this.checkboxUpdate.bind(this);
  }

  async loadLojas() {
    let lojas = await lojaDB.load();
    this.setState({ lojas });
  }

  async componentDidMount() {
    await this.loadLojas();
    this.getIndicadores();
  }

  async applyFilter(text) {
    if (text === "") {
      this.setState({ indicadores: this.state.indicadoresOriginal });
      return;
    }

    const keys = ["nome", "loja.nome", "ativoStr"];

    let indicadores = [];
    if(!this.state.checked && this.state.indicadoresLista) {
      indicadores = listObj.search(this.state.indicadoresLista, text, keys);
    } else {
      indicadores = listObj.search(this.state.indicadoresOriginal, text, keys);
    } 

    this.setState({ indicadores });
  }

  updateList(record) {
    record['ativoStr'] = record['ativo'] ? 'Sim' : 'Não';
    let registroEncontrado = false;
    let listaAtivos = [];
    let listaInativos = [];

    if(this.state.checked) {
      listaInativos = this.state.indicadores;
      listaAtivos = this.state.indicadoresLista;
    } else if(this.state.indicadoresLista.length) {
      listaAtivos = this.state.indicadores;
      listaInativos= this.state.indicadoresLista;
    } else {
      listaAtivos = this.state.indicadores;
    }
    
    if(listaAtivos) {
      listaAtivos.forEach((item, index) => {
        if (item.key === record.key) {
          if(!record['ativo']) {
            listaAtivos.splice(index, 1);
          } else {
            listaAtivos[index] = record;
          }
            registroEncontrado = true;
          }
      });
    }

    if(listaInativos.length) {
      listaInativos.forEach((item, index) => {
        if (item.key === record.key) {
          if(record['ativo']) {
            listaAtivos.push(record);
          }

          listaInativos[index] = record;
          registroEncontrado = true;
        }
      });
    }

    if (!registroEncontrado && record['ativo']) {
      listaAtivos.unshift(record);
      if(listaInativos.length) listaInativos.unshift(record);
    } else if(!registroEncontrado && !record['ativo'] && listaInativos.length) {
      listaInativos.push(record)
    }

    if(this.state.checked) {
      this.setState({ indicadores: listaInativos, indicadoresOriginal: listaInativos, indicadoresLista: listaAtivos });
    } else {
      this.setState({ indicadores: listaAtivos, indicadoresOriginal: listaAtivos, indicadoresLista: listaInativos });
    }
  }

  async getIndicadores() {
    const gestorId = getGestorId();
    const userType = getUserType();

    let indicadores = [];
    if (userType === GESTOR) {
      indicadores = await indicadorDB.getByGestorIdCustom(gestorId, false);
    } else {
      indicadores = await indicadorDB.loadByLojas(this.state.lojas, false);
    }

    this.setState({
      indicadores,
      indicadoresOriginal: indicadores,
      loading: false,
    });
  }

  editarIndicadorElement(record) {
    record["lojas"] = this.state.lojas;
    this.headerElement.current.editar(record);
  }

  getCheckboxState(checked) {
    this.setState({ checked })
    this.checkboxUpdate();
  }

  async checkboxUpdate() {
    this.setState({ loading: true });
    if(this.state.indicadoresLista.length || !this.state.checked && this.state.indicadores) {
        const indicadoresLista = this.state.indicadoresLista;
        const indicadores = this.state.indicadores;

        this.setState({ indicadores: indicadoresLista, indicadoresOriginal: indicadoresLista, indicadoresLista: indicadores })
    } else if(this.state.checked) {
        const userType = getUserType();
        let novaLista = [];

        if(userType === GESTOR) {
          novaLista = await indicadorDB.getByGestorIdCustom(getGestorId(), true);
        } else {
          novaLista = await indicadorDB.loadByLojas(this.state.lojas, true);
        }

        let indicadores = this.state.indicadores;

        if(indicadores && novaLista) {
          this.setState({ indicadoresLista: indicadores });
          indicadores = indicadores.concat(novaLista);
        } else if(novaLista) {
          indicadores = novaLista;
        } else {
          this.setState({ indicadoresLista: indicadores });
        }

        this.setState({ indicadores: indicadores, indicadoresOriginal: indicadores });
    }
    this.setState({ loading: false });
  }

  render() {
    const columns = [
      {
        title: "Foto",
        dataIndex: "avatar",
        key: "avatar",
        width: 90,
        render: (avatar) => (
          <span>
            <Avatar size={52} src={avatar} />
          </span>
        ),
      },
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
      },
      {
        title: "Loja",
        dataIndex: "loja.nome",
        key: "loja",
      },
      {
        title: "Ativo?",
        dataIndex: "ativoStr",
        key: "ativo",
      },
      {
        title: "",
        width: "20px",
        key: "action",
        render: (text, record) => (
          <span>
            <Tooltip placement="topLeft" title="Editar Cadastro">
              <a onClick={() => this.editarIndicadorElement(record)}>
                <Icon type="edit" />
              </a>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <div>
        <BluveLayout selectItem={"parceiros/indicadores"}>
          <Table
            pagination={{
              defaultPageSize: 100,
              position: "both",
              pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
            }}
            title={() => (
              <Header
                ref={this.headerElement}
                lojas={this.state.lojas}
                applyFilter={this.applyFilter}
                updateList={this.updateList}
                getCheckboxState={this.getCheckboxState}
              />
            )}
            columns={columns}
            dataSource={this.state.indicadores}
            bordered
            loading={this.state.loading}
          />
        </BluveLayout>
      </div>
    );
  }
}

class Header extends React.Component {
  state = {
    visible: false,
    checked: false,
    loading: false
  };

  constructor(props) {
    super(props);
    this.novoIndicadorElement = React.createRef();

    this.novo = this.novo.bind(this);
    this.filterTable = this.filterTable.bind(this);
    this.onCheck = this.onCheck.bind(this);

    let timer;
  }

  novo() {
    const record = [];
    record["lojas"] = this.props.lojas;
    this.novoIndicadorElement.current.show(false, record);
  }

  editar(record) {
    this.novoIndicadorElement.current.show(true, record);
  }

  filterTable(value) {
    this.setState({ loading: true });
    const { applyFilter } = this.props;

    const text = typeof value === "object" ? value.target.value : value;
    let time = text ? 3000 : 500;

    if(this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      applyFilter(text);
      this.setState({ loading: false });
    }, time);
  }

  async onCheck(e) {
    await this.setState({ checked: e.target.checked });
    this.props.getCheckboxState(this.state.checked);
  }

  render() {
    return (
      <div>
        <NovoIndicador
          ref={this.novoIndicadorElement}
          updateList={this.props.updateList}
          record={[]}
        ></NovoIndicador>
        <Button type="primary" onClick={this.novo}>
          <Icon className="icon" type="plus" /> Novo Indicador
        </Button>

        <Search
          placeholder="Procurar"
          onSearch={this.filterTable}
          onChange={this.filterTable}
          loading={this.state.loading}
          style={{ width: 200, marginLeft: "10px", marginRight: '1rem' }}
        />

        <Checkbox checked={this.state.checked} onChange={this.onCheck}>
          Listar Inativos
        </Checkbox>

      </div>
    );
  }
}

class NovoIndicador extends React.Component {
  state = {
    closable: true,
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
    lojas: [],
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onCloseHandle = this.onCloseHandle.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record,
    });
  }

  handleOk(record) {
    this.props.updateList(record);
    this.setState({ visible: false, closable: true });
  }

  onCloseHandle(canClose) {
    this.setState({ closable: canClose });
  }

  async handleCancel() {
    if (!this.state.closable) return;
    if (!(await mensagem.confirmar("Cancelar alterações?"))) return;
    this.setState({ visible: false, closable: true });
  }

  render() {
    return (
      <Modal
        title="Indicador"
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        closable={this.state.closable}
      >
        <IndicadorCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          editMode={this.state.editMode}
          record={this.state.record}
          lojas={this.state.lojas}
          onCloseHandle={this.onCloseHandle}
        ></IndicadorCRUD>
      </Modal>
    );
  }
}

export default withRouter(Indicador);
