import React, { Component } from "react";
import "./VendasPerdidas.css";
import {
  getEmpresas,
  buildReport,
  getNextColor,
  exportToExcel,
} from "./VendasPerdidasF.jsx";
import { getUserType, getGestorId } from "../../../components/auth/auth-provider";
import { withRouter } from "react-router";
import BluveLayout from "../../../components/layout/BluveLayout";
import {
  Select,
  DatePicker,
  Button,
  Table,
  message,
  Modal,
  Row,
  Col,
  Statistic,
  Card,
  Divider,
  Skeleton,
  Empty,
} from "antd";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "chart.piecelabel.js";
import moment from "moment";
import Text from "antd/lib/typography/Text";
import bluveStorage from "../../../components/localstorage/localstorage";
import { GERENTE } from "../../../components/funcs/constants";
import locale from 'antd/es/date-picker/locale/pt_BR';
import { H_48_HORAS } from '../../../components/funcs/constants';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { warning } = Modal;
const dateFormat = "DD/MM/YYYY";

class VendasPerdidas extends Component {
  state = {
    empresaId: "",
    empresaComparacaoId: "",
    empresas: [],
    periodo: {},
    alterouConfiguracaoRelatorio: false,
    alterouEmpresaComparacao: false,
    loading: false,
    reportData: [],
    chartData: {},
    nomeLoja: "",
    nomeComparacao: "",
    totalOportunidadesLoja: 0,
    totalOportunidadesComparativo: 0,
    comparativo: 0,
    maiorOcorrencia: { percentual: "-%", nome: "-" },
  };

  options = [];
  optionsComparacao = [];

  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onSelectChangeComparar = this.onSelectChangeComparar.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
  }

  async componentDidMount() {
    const gestorId = await getGestorId();

    const storage = bluveStorage.getEmpresasGestor('@gestorEmpresas');
    let empresas = [];
    if (storage && storage.gestorId === gestorId) {
      empresas = storage.empresas;
    } else {
      empresas = await getEmpresas();

      const data = {
        gestorId: gestorId,
        empresas: empresas
      };

      bluveStorage.setEmpresasGestor(data);
    }

    if (getUserType() !== "Gerente") {
      this.optionsComparacao.push(
        <Option key={"rede"} value={"rede"}>
          {"Rede de Lojas"}
        </Option>
      );
    }
    else {
      const texto = empresas.length > 1 ? 'Minhas lojas' : 'Você mesmo';
      this.optionsComparacao.push(
        <Option key={"rede"} value={"rede"}>
          {texto}
        </Option>
      );
    }

    for (const item of empresas) {
      this.options.push(
        <Option key={item.key} value={item.key}>
          {item.login.login}
        </Option>
      );

      if (empresas.length > 1) {
        this.optionsComparacao.push(
          <Option key={item.key} value={item.key}>
            {item.login.login}
          </Option>
        );
      }
    }

    if (!empresas.length) {
      empresas.push({
        id: "Nenhuma empresa cadastrada",
        razaoSocial: "Nenhuma empresa cadastrada",
      });
    }

    let empresaId = bluveStorage.getReportEmpresaId();
    if (!empresaId) {
      empresaId = empresas[0].key
    }

    let periodo = bluveStorage.getReportPeriodo();
    if (!periodo) {
      periodo = {
        inicial: moment().subtract(7, "days"),
        final: moment(),
      };
    }
    else {
      periodo.inicial = moment(periodo.inicial);
      periodo.final = moment(periodo.final);
    }

    let empresaComparacaoId = bluveStorage.getReportEmpresaComparacaoId();
    if (!empresaComparacaoId) {
      empresaComparacaoId = 'rede'
    }

    this.setState({
      empresaId,
      empresaComparacaoId,
      empresas: empresas,
      periodo,
    });

    const report = bluveStorage.getReportVendasPerdidas();
    if (report.loja || report.comparacao) {
      this.mountChart(report.loja, report.comparacao);
    }
  }

  onSelectChange(value) {
    this.setState({ empresaId: value, alterouConfiguracaoRelatorio: true });
  }

  onSelectChangeComparar(value) {
    this.setState({ empresaComparacaoId: value, alterouEmpresaComparacao: true });
  }

  onDateChange(value) {
    const periodo = {
      inicial: value[0],
      final: value[1]
    }
    this.setState({ periodo, alterouConfiguracaoRelatorio: true });
  }

  onExportClick() {
    const empresaFiltrada = this.state.empresas.filter(item => item.key === this.state.empresaId)[0];
    exportToExcel(
      this.state.reportData,
      empresaFiltrada,
      this.state.periodo,
      this.state.nomeComparacao,
      this.state.maiorOcorrencia,
    );
  }



  async onFilterClick() {
    if (!this.state.periodo.inicial || !this.state.periodo.final) {
      message.warning("Selecione o período para gerar o gráfico", 2);
      return;
    }

    if (this.state.empresaId === this.state.empresaComparacaoId) {
      warning({
        title: "Atenção",
        content:
          "A empresa selecionada e a de comparação não podem ser as mesmas.",
      });
      return;
    }

    if (this.state.empresaId === undefined) {
      warning({
        title: "Atenção",
        content:
          "Nenhuma empresa selecionada.",
      });

      return;
    }

    this.setState({ loading: true });

    if (this.state.alterouConfiguracaoRelatorio) {
      bluveStorage.clearAllReportsData();
    } else if (this.state.alterouEmpresaComparacao) {
      bluveStorage.clearReportsDataEmpresaComparacao();
    }

    bluveStorage.setReportEmpresaId(this.state.empresaId);
    bluveStorage.setReportEmpresaCompracaoId(this.state.empresaComparacaoId);
    bluveStorage.setReportPeriodo(this.state.periodo);

    setTimeout(async () => {
      const loja = await buildReport(
        this.state.empresaId,
        this.state.periodo.inicial,
        this.state.periodo.final
      );

      const rede = this.state.empresaComparacaoId === "rede";
      const id = rede ? this.state.empresaId : this.state.empresaComparacaoId;

      const comparacao = await buildReport(
        id,
        this.state.periodo.inicial,
        this.state.periodo.final,
        rede,
        getUserType() === GERENTE,
        this.state.empresas
      );

      bluveStorage.setReportVendasPerdidas(loja, comparacao);
      this.mountChart(loja, comparacao);
    }, 500);
  }
  alinharLista(lista1, lista2) {
    const listaMestre = lista1.length > lista2.length ? lista1 : lista2;
    const listaSlave = lista1.length > lista2.length ? lista2 : lista1;
    let index = 0;

    listaMestre.forEach((item) => {
      let achouLabel = false;
      listaSlave.forEach((slave) => {
        if (slave.id === item.id) {
          achouLabel = true;
        }
      });

      if (!achouLabel) {
        const dt = {
          id: index,
          nome: item.nome,
          total: 0,
          percentual: "0.00%",
          color: item.color,
        };
        listaSlave.splice(index, 0, dt);
      }

      index++;
    });

    if (listaMestre.length !== listaSlave.length) {
      this.alinharLista(listaMestre, listaSlave);
    }
  }

  ordenarLista(lista) {
    // Ordena do maior valor para o menor
    lista = lista.sort((a, b) => b.total - a.total);


    const totalObject = lista.find(obj => obj.id === 'total');
    if (totalObject) {
      const index = lista.indexOf(totalObject);
      lista.splice(index, 1);
      lista.push(totalObject);
    }

    let total = 0;
    let color = "#fff";
    lista.forEach((item) => {
      item.color = color;
      color = getNextColor(color);
      if (item.id !== "total") {
        total += item.total;
      } else {
        item.total = total;
      }
    });

    return lista;
  }

  mountChart(loja, lojaComparacao) {
    let maiorOcorrencia = loja.reduce((anterior, atual) => {
      const ocorrencia = atual.id === "total" || anterior.total > atual.total ? anterior : atual
      return ocorrencia
    });

    let totalLoja = loja.find((item) => item.id === "total").total;
    let totalComparativo = lojaComparacao.find((item) => item.id === "total")
      .total;

    let lojaSelecionada = this.state.empresas.find(
      (item) => item.key === this.state.empresaId
    );

    if (lojaSelecionada.id === 'Nenhuma empresa cadastrada') {
      return;
    }

    let comparacaoSelecionada = this.state.empresas.find(
      (item) => item.key === this.state.empresaComparacaoId
    );

    this.setState({
      nomeLoja: lojaSelecionada.login.login,
      nomeComparacao: comparacaoSelecionada
        ? comparacaoSelecionada.login.login
        : "Rede",
      totalOportunidadesLoja: totalLoja,
      totalOportunidadesComparativo: totalComparativo,
      comparativo:
        totalComparativo > 0
          ? Math.round((totalLoja / totalComparativo) * 100)
          : "--",
      maiorOcorrencia: {
        percentual: maiorOcorrencia.percentual,
        nome:
          maiorOcorrencia.nome.length <= 18
            ? maiorOcorrencia.nome
            : maiorOcorrencia.nome.substr(0, 18) + "...",
      },
    });

    if (loja.length < 2 && lojaComparacao.length < 2) {
      message.info("Sem movimentos nesse período");

      const emptyChart = {
        labels: [],
        datasets: [],
      };

      this.setState({ loading: false, reportData: [], chartData: emptyChart });
      return;
    }

    if (loja === undefined) {
      loja = [];
    }

    if (lojaComparacao === undefined) {
      lojaComparacao = [];
    }

    // Antes de alimentar os itens que formarão o gráfico, harmonizo as duas
    // listas
    this.alinharLista(loja, lojaComparacao);

    loja = this.ordenarLista(loja);
    lojaComparacao = this.ordenarLista(lojaComparacao);

    const labels = [];
    let matrixDataSet = [
      {
        label: "Loja",
        data: [],
        backgroundColor: "#4285F5",
      },
      {
        label: "Comparação",
        data: [],
        backgroundColor: "#db4437",
      },
    ];

    if (getUserType() === GERENTE && this.state.empresas.length <= 1) {
      matrixDataSet = [
        {
          label: "Loja",
          data: [],
          backgroundColor: "#4285F5",
        }
      ];
    }

    loja.forEach((item) => {
      if (item.nome !== "Total") {
        labels.push(item.nome);
        matrixDataSet[0].data.push(Number(item.percentual.replace("%", "")));
      }
    });

    if (getUserType() !== GERENTE || (getUserType() === GERENTE && this.state.empresas.length > 1)) {
      lojaComparacao.forEach((item) => {
        if (item.nome !== "Total") {
          matrixDataSet[1].data.push(Number(item.percentual.replace("%", "")));
        }
      });
    }

    const data = {
      labels: labels,
      datasets: matrixDataSet,
    };

    this.setState({ loading: false, reportData: loja, chartData: data });
  }

  render() {
    const columns = [
      {
        title: "Motivo",
        dataIndex: "nome",
        key: "motivo",
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
      },
      {
        title: "Percentual",
        dataIndex: "percentual",
      },
    ];

    let empty = <Empty description={false} />;
    let graficoEsquerda = (
      <div className="left">
        <Bar
          data={this.state.chartData}
          width={100}
          height={280}
          options={{
            responsive: true,
            maintainAspectRatio: false,

            plugins: {
              datalabels: {
                display: false,
              },
            },

            scales: {
              xAxes: [
                {
                  stacked: true,
                  beginAtZero: true,
                  min: 0,
                  //.. Other settings
                  stepSize: 20 /* total/4 shows 0, 25%, 50%, 75%, 100% */,
                  callback: function (value, index, values) {
                    return value + "%";
                  },
                },
              ],
              yAxes: [
                {
                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    stepSize: 20,

                    callback: function (value) {
                      return value + "%";
                    },
                  },
                },
              ],
            },

            legend: {
              display: true,
            },
            title: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  var dataset = data.datasets[tooltipItem.datasetIndex];
                  var currentValue = dataset.data[tooltipItem.index];
                  return currentValue.toString().replace(".", ",") + " %";
                },
                title: function (tooltipItem, data) {
                  return data.labels[tooltipItem[0].index];
                },
              },
            },
          }}
        />
      </div>
    );

    let graficoDireita = (
      <div className="right">
        <Table
          columns={columns}
          rowClassName={(record) => record.color.replace("#", "")}
          pagination={{ hideOnSinglePage: true }}
          dataSource={this.state.reportData}
          bordered
          rowKey={(record) => record.id}
        />
      </div>
    );

    let graficos =
      this.state.reportData.length === 0 ? (
        empty
      ) : (
        <div>
          {graficoDireita}
          {graficoEsquerda}
          <Divider dashed />
        </div>
      );

    return (
      <div>
        <BluveLayout selectItem={"relatorio/vendas-perdidas"}>
          <div className="containerExtratoAtendimentos">
            <div className="child">
              <label>Empresa:</label>
              <br></br>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "200px" }}
                placeholder="Selecione uma empresa"
                onChange={this.onSelectChange}
                value={this.state.empresaId}
              >
                {this.options}
              </Select>
            </div>

            <div className="child">
              <label>Período:</label>
              <br></br>
              <div className="rangePickerDuplo">
                <RangePicker
                  locale={locale}
                  format={dateFormat}
                  placeholder={["Data Inicial", "Data Final"]}
                  onChange={this.onDateChange}
                  value={[
                    this.state.periodo.inicial,
                    this.state.periodo.final,
                  ]}
                />
              </div>
            </div>

            <div className="child">
              <label>Comparar:</label>
              <br></br>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "200px" }}
                placeholder="Selecione uma empresa"
                onChange={this.onSelectChangeComparar}
                value={this.state.empresaComparacaoId}
              >
                {this.optionsComparacao}
              </Select>
            </div>
            <div className="child">
              <Button
                type="primary"
                loading={this.state.loading}
                onClick={this.onFilterClick}
              >
                Filtrar
              </Button>
              <br></br>
              <br></br>
              <Button
                className='excel-button-v2'
                disabled={this.state.loading || this.state.reportData.length === 0}
                onClick={this.onExportClick}
                style={{ marginLeft: '1rem' }}
              >
                Exportar para Excel
              </Button>


            </div>
          </div>

          <div>
            <Row gutter={16}>
              <Col span={5}>
                <Skeleton
                  title={false}
                  loading={
                    this.state.reportData.length === 0 || this.state.loading
                  }
                  active={this.state.loading}
                  paragraph={{ width: ["70%", "40%"] }}
                >
                  <Statistic
                    title="Vendas Perdidas"
                    value={this.state.totalOportunidadesLoja}
                  />
                </Skeleton>
              </Col>

              <Col span={7}>
                <Skeleton
                  title={false}
                  loading={
                    this.state.reportData.length === 0 || this.state.loading
                  }
                  active={this.state.loading}
                  paragraph={{ width: ["70%", "40%"] }}
                >
                  <Statistic
                    title="Maior Ocorrência"
                    value={this.state.maiorOcorrencia.percentual}
                    suffix={"/ " + this.state.maiorOcorrencia.nome}
                  />
                </Skeleton>
              </Col>

              <Col span={6}>
                <Card>
                  <Skeleton
                    title={false}
                    loading={
                      this.state.reportData.length === 0 || this.state.loading
                    }
                    active={this.state.loading}
                    paragraph={{ width: ["90%", "50%"] }}
                  >
                    <Statistic
                      title={
                        <div>
                          {this.state.nomeLoja.length <= 14
                            ? this.state.nomeLoja
                            : this.state.nomeLoja.substr(0, 14) + "..."}
                          <Text strong> X </Text>
                          {this.state.nomeComparacao.length <= 12
                            ? this.state.nomeComparacao
                            : this.state.nomeComparacao.substr(0, 12) + "..."}
                        </div>
                      }
                      // prefix="<"
                      value={this.state.comparativo}
                      // precision={2}
                      valueStyle={
                        this.state.comparativo <= 100
                          ? { color: "#3f8600" }
                          : { color: "#cf1322" }
                      }
                      // prefix={<Icon type="arrow-up" />}
                      suffix="%"
                    />
                  </Skeleton>
                </Card>
              </Col>

              <Col span={6}>
                <Card>
                  <Skeleton
                    title={false}
                    loading={
                      this.state.reportData.length === 0 || this.state.loading
                    }
                    active={this.state.loading}
                    paragraph={{ width: ["90%", "50%"] }}
                  >
                    <Statistic
                      title={this.state.nomeComparacao}
                      value={this.state.totalOportunidadesComparativo}
                      suffix="vendas perdidas"
                    />
                  </Skeleton>
                </Card>
              </Col>
            </Row>
            <Divider dashed />
          </div>

          {graficos}
        </BluveLayout>
      </div>
    );
  }
}

export default withRouter(VendasPerdidas);
