import React, { useState, useEffect } from "react";
import { Avatar, Table } from "antd";
import { useFormikContext } from "formik";
import { InputNumber, FormItem } from "formik-antd";

import CurrencyInput from 'react-currency-input';
import Text from "antd/lib/typography/Text";


const EscalaStep = () => {
  const { values, setFieldValue } = useFormikContext();
  const [ valorInconsistente, setValorInconsistente ] = useState('');

  const validarMetas = () => {
    const vendedoresCopy = [...values.vendedores];
    const valorTotalOriginal = vendedoresCopy.reduce((soma, item) => soma + item.metaTotal, 0);
    const valorTotalArredondado = Math.round(valorTotalOriginal * 100) / 100;
    const error = !(valorTotalArredondado >= values.principal);    
    setValorInconsistente(error ? 'Valores das metas inconsistentes' : '');
    return error;
  };  

  const handleMetaTotalChange = (newValue, index) => {  
    const numericValue = parseFloat(newValue.replace(/[^\d,]/g, '').replace(',', '.'));
    const vendedoresCopy = [...values.vendedores];
    const valorTotalOriginal = vendedoresCopy.reduce((soma, item) => soma + item.metaTotal, 0);
    const vendedoresAtualizados = vendedoresCopy.map((item, vendedorIndex) => {
      if (vendedorIndex === index) {
        const metasPeriodos = new Array(values.periodos.length).fill(numericValue / values.periodos.length);
        return {
          ...item,
          metaTotal: numericValue,
          metasPeriodos,
        };
      } else {
        const metaAjustada = (valorTotalOriginal - numericValue) / (values.vendedores.length - 1);
        const metasPeriodos = item.metasPeriodos;
        const totalPeriodos = item.metasPeriodos.reduce((soma, item) => soma + item, 0);
        for (let i = 0; i < metasPeriodos.length; i++) {
          const percentualOriginal = (item.metasPeriodos[i] / totalPeriodos) * 100;
          const valorAjustado = (percentualOriginal * metaAjustada) / 100;
          //item.metasPeriodos[i] = valorAjustado;
        }

        return {
          ...item,
          //metaTotal: metaAjustada,
          metasPeriodos,
        };
      }
    });

    setFieldValue("vendedores", vendedoresAtualizados);
  };

  const handleVendedorDias = (value, vendedorIndex, periodo) => {
    const vendedores = values.vendedores;
    vendedores[vendedorIndex].dias[periodo] = value;

    const periodos = values.periodos.map((item, index) => {
      return {
        ...item,
        // Calcula o valor da meta diária dos vendendores de acordo com o período,
        // dividindo a meta do período pela soma de dias disponíveis (vendedores x dias)
        metaVendedorDia: item.meta > 0 ? item.meta / vendedores.reduce((soma, item) => soma + item.dias[index], 0) : 0
      };
    });

    setFieldValue("periodos", periodos);
  };

  useEffect(() => {
    const vendedores = values.vendedores.map((item, index) => {
      return {
        ...item,
        metasPeriodos: item.dias.map((item, index) => item * values.periodos[index].metaVendedorDia),
        metaTotal: item.dias.map((item, index) => item * values.periodos[index].metaVendedorDia).reduce((soma, valor) => soma + valor, 0)
      };
    });

    setFieldValue("vendedores", vendedores);
  }, [values.periodos]);

  const columns = [
    {
      dataIndex: 'avatar',
      key: 'avatar',
      render: (src) => <Avatar src={src} />,
    },
    {
      key: 'nome',
      render: (text, row) => (
        <>
          <div>
            <Text>{row.nome}</Text>
          </div>
          <div>
            <Text type="secondary">{row.apelido}</Text>
          </div>
        </>
      ),
    },
    ...values.periodos.map((item, index) => {
      return {
        title: `Período ${index + 1}`,
        dataIndex: `dias[${index}]`,
        render: (text, row, indexVendedor) => {
          return (
            <InputNumber
              className="periodo-dias"
              key={index}
              name={`vendedores.${indexVendedor}.dias.${index}`}
              min={0}
              max={values.periodos[index].dias}
              onChange={(value) => handleVendedorDias(value, indexVendedor, index)}>
            </InputNumber>
          );
        }
      }
    }),

    {
      title: "Meta Original",
      dataIndex: "metaTotal",
      render: (value, record, index) => (
        <CurrencyInput
          className="wrapValorMeta"
          prefix="R$ "
          decimalSeparator=","
          thousandSeparator="."
          value={value}
          onChange={(newValue) => handleMetaTotalChange(newValue, index)}
        />
      ),
    },];

  return (
    <FormItem name="escala" validate={validarMetas}>
      <Table
        columns={columns}
        dataSource={values.vendedores}
        rowKey="id"
        pagination={false}
      />
      <label>{valorInconsistente}</label>
    </FormItem>
  );
};

export default EscalaStep;
