import React from "react";
import { withRouter } from "react-router";
import { Table, Icon, Modal, Input, Tooltip, Checkbox, message } from "antd";
import { currentUser, getGestorId, getUserType } from "../../components/auth/auth-provider";
import "./Empresa.css";
import "antd/dist/antd.css";
import EmpresaCRUD from "../../components/cruds/empresas/EmpresaCRUD";
import BluveLayout from "../../components/layout/BluveLayout";
import listObj from "../../components/listSearch/listSearch";
import mensagem from "components/message/Message";
import API from 'services/api/api';

const { Search } = Input;
const api = new API();

class Empresa extends React.Component {
  state = {
    empresas: [],
    empresasOriginal: [],
    loading: true,
    userType: "Gerente",
  };

  constructor(props) {
    super(props);

    this.headerEmpresaElement = React.createRef();
    this.state.userType = getUserType();

    this.applyFilter = this.applyFilter.bind(this);
    this.updateList = this.updateList.bind(this);
    this.getCheckboxState = this.getCheckboxState.bind(this);
  }

  componentDidMount() {
    document.addEventListener('tokenAvailable', () => this.getEmpresas());
  }

  async applyFilter(text) {
    if (text === "") {
      this.setState({ empresas: this.state.empresasOriginal });
      return;
    }

    const keys = [
      "cnpj",
      "razaoSocial",
      "nomeFantasia",
      "login.login",
      "email",
      "endereco.telefone",
      "integracao.key",
      "ativoStr",
    ];

    const empresas = listObj.search(this.state.empresasOriginal, text, keys);
    this.setState({ empresas });
  }

  updateList(record) {
    let lista = this.state.empresas;
    let registroEncontrado = false;

    if (lista) {
      this.state.empresas.forEach((item, index) => {
        if (item.key === record.key) {
          lista[index] = record;
          registroEncontrado = true;
        }
      });
    } else {
      lista = [];
    }

    if (!registroEncontrado) {
      lista.push(record);
    }
    this.setState({ empresas: lista, empresasOriginal: lista });
  }

  async getEmpresas() {
    const gestorId = await getGestorId();
    const user = currentUser();

    api.get({ gestorId, email: user.email }, '/empresa')
      .then((empresas) => {
        empresas.forEach(empresa => {
          empresa.ativoStr = empresa.ativo === true || empresa.ativo === undefined ? 'Sim' : 'Não'
        });

        const empresasAtivas = empresas.filter((item) => {
          return item.ativo === true || item.ativo === undefined;
        });

        this.setState({ empresas: empresasAtivas, empresasOriginal: empresasAtivas, todasEmpresas: empresas, loading: false });
      })
      .catch((error) => {
        message.error("Erro ao buscar empresas.");
        console.log(error);
        this.setState({ loading: false });
      }
      );
  }

  getCheckboxState(checked) {
    const todasEmpresas = this.state.todasEmpresas;
    if (checked) {
      this.setState({ empresas: todasEmpresas, empresasOriginal: todasEmpresas });
    } else {
      const empresasAtivas = todasEmpresas === undefined ? [] : todasEmpresas.filter((item) => {
        return item.ativo === true || item.ativo === undefined;
      });
      this.setState({ empresas: empresasAtivas, empresasOriginal: empresasAtivas });
    }
  }


  handleCancel = () => {
    this.setState({ visible: false });
  };

  editarEmpresaElement(record) {
    this.headerEmpresaElement.current.editarEmpresa(record);
  }

  render() {
    const columns = [
      {
        title: "CNPJ",
        dataIndex: "cnpj",
        key: "cnpj",
      },
      {
        title: "Razão Social",
        dataIndex: "razaoSocial",
        key: "razaoSocial",
        editable: false,
      },
      {
        title: "Nome Fantasia",
        dataIndex: "nomeFantasia",
        key: "nomeFantasia",
      },
      {
        title: "Login",
        dataIndex: "login.login",
        key: "login",
      },
      {
        title: "E-mail",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Telefone",
        dataIndex: "endereco.telefone",
        key: "telefone",
      },
      {
        title: "Chave API",
        dataIndex: "integracao.key",
        key: "api",
      },
      {
        title: "Ativo",
        dataIndex: "ativoStr",
        key: "ativoStr",
      },
      {
        title: "",
        width: "auto",
        key: "action",
        render: (text, record) => (
          <span>
            <Tooltip placement="topLeft" title="Editar Cadastro">
              <a onClick={() => this.editarEmpresaElement(record)}>
                <Icon type="edit" />
              </a>
            </Tooltip>
          </span>
        ),
      },
    ];

    return (
      <div className="divTable">
        <BluveLayout selectItem={"empresas"}>
          <Table
            className="divTableEmpresas"
            pagination={{
              defaultPageSize: 100,
              position: "both",
              pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
            }}
            title={() => (
              <HeaderEmpresa
                ref={this.headerEmpresaElement}
                updateList={this.updateList}
                applyFilter={this.applyFilter}
                userType={this.state.userType}
                getCheckboxState={this.getCheckboxState}
              />
            )}
            columns={columns}
            dataSource={this.state.empresas}
            loading={this.state.loading}
            bordered
          />
        </BluveLayout>
      </div>
    );
  }
}

class HeaderEmpresa extends React.Component {
  constructor(props) {
    super(props);
    this.novaEmpresaElement = React.createRef();

    this.state = {
      visible: false,
      checked: false,
      listarInativos: true,
    };

    this.novaEmpresa = this.novaEmpresa.bind(this);
    this.filterTable = this.filterTable.bind(this);
    this.onCheck = this.onCheck.bind(this);
  }

  componentDidMount() {
    const listarInativos = (getUserType() === 'Gestor') ? true : false;
    this.setState({ listarInativos });
  }



  novaEmpresa() {
    this.novaEmpresaElement.current.show(false);
  }

  editarEmpresa(record) {
    this.novaEmpresaElement.current.show(true, record);
  }

  onCheck(e) {
    const checked = e.target.checked;
    this.setState({ checked }, () => {
      this.props.getCheckboxState(checked);
    });
  }


  filterTable(value) {
    const text = typeof value === "object" ? value.target.value : value;
    this.props.applyFilter(text);
  }

  render() {
    return (
      <div>
        <NovaEmpresa
          ref={this.novaEmpresaElement}
          updateList={this.props.updateList}
        ></NovaEmpresa>

        <Search
          placeholder="Procurar"
          onSearch={this.filterTable}
          onChange={this.filterTable}
          style={{ width: 200, marginLeft: "10px" }}
        />

        &nbsp;&nbsp;

        {this.state.listarInativos ? (
          <Checkbox checked={this.state.checked} onChange={this.onCheck}>
            Listar Inativas
          </Checkbox>
        ) : null}

      </div>
    );
  }
}

class NovaEmpresa extends React.Component {
  state = {
    closable: true,
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onCloseHandle = this.onCloseHandle.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record,
    });
  }

  handleOk(record) {
    this.props.updateList(record);
    this.setState({ visible: false, closable: true });
  }

  onCloseHandle(canClose) {
    this.setState({ closable: canClose });
  }

  async handleCancel() {
    if (!this.state.closable) return;
    if (!(await mensagem.confirmar("Cancelar alterações?"))) return;

    this.setState({ visible: false, closable: true });
  }

  render() {
    return (
      <Modal
        title="Empresa"
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        closable={this.state.closable}
      >
        <EmpresaCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          editMode={this.state.editMode}
          record={this.state.record}
          onCloseHandle={this.onCloseHandle}
        ></EmpresaCRUD>
      </Modal>
    );
  }
}

export default withRouter(Empresa);
