import { fbDatabase, fbFirestore } from '../firebase/firebase';
import { getUserType, getGestorId, getGerenteId } from '../components/auth/auth-provider';
import { GESTOR } from '../components/funcs/constants';
import DataManager from './dtm';


class LojaDB extends DataManager {

    state = {
        collection: 'lojas',
        orderBy: ['razaoSocial', 'nomeFantasia'], // usado na classe pai
    }


    constructor(props) {
        super(props);
        this.getByGerenteId = this.getByGerenteId.bind(this);
    }

    async getByGerenteId(id) {
        let snapshot = await fbDatabase.collection('lojas')
            .where('gerente.id', '==', id)
            .orderBy("razaoSocial")
            .orderBy("nomeFantasia")
            .get();

        /** X-TUDO */
        let xRef = fbDatabase.collection('x-tudo').doc('Bluve-dtmLojaJSX-LojaDB-getByGerenteId');
        xRef.set({
            collection: 'lojas',
            lastUpdate: fbFirestore.FieldValue.serverTimestamp(),
            count: fbFirestore.FieldValue.increment(snapshot.size),
            lastIncrement: snapshot.size
        }, { merge: true }).catch(error => console.error(error));

        if (snapshot.empty) {
            return [];
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data()
        }));

        return data;
    }

    async getByCNPJ(cnpj) {
        const data = await this.getByParamValue('cnpj', cnpj);
        return data;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        return data;
    }

    async load() {
        // Este método já executa o load de acordo com a regra de negócios 
        // nos logins Gerente /Gestor
        const userType = getUserType();
        let data = undefined;
        if (userType === GESTOR) {
            const gestorId = getGestorId();
            data = await this.getByGestorId(gestorId);
        }
        else {
            const gerenteId = getGerenteId();
            data = await this.getByGerenteId(gerenteId);
        }
        if (data === undefined) {
            return [];
        }
        for (let index = 0; index < data.length; index++) {
            const loja = data[index];
            loja.comissao['comissaoFixoStr'] = this.formatarMoeda(loja.comissao.fixo);
        }
        return data;
    }
}

const lojaDB = new LojaDB();
export default lojaDB;