import currency from "currency.js";

const locale = "pt-br";

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const toLocale = (number) => {
    return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export const currencyFormatter = () => value => {
    // return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: "BRL"
    }).format(value);
};

export const currencyParser = val => {
    try {
        // for when the input gets clears
        if (typeof val === "string" && !val.length) {
            val = "0.0";
        }

        // detecting and parsing between comma and dot
        var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
        var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
        var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");
        reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");
        //  => 1232.21 €

        // removing everything except the digits and dot
        reversedVal = reversedVal.replace(/[^0-9.]/g, "");
        //  => 1232.21

        // Adicionei o uso do componente currency.js, 
        // dessa forma não precisa do código abaixo comentado
        const valCurrency = currency(Number(reversedVal.replace(".", "")), { fromCents: true, precision: 2 });
        return Number.isNaN(valCurrency) ? 0 : valCurrency;

        // appending digits properly
        // const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
        // const needsDigitsAppended = digitsAfterDecimalCount > 2 || digitsAfterDecimalCount == 1;

        // if (needsDigitsAppended) {
        //     reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
        // }

        // return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
        console.error(error);
    }
};