import React from "react";
import { withRouter } from "react-router";
import { Avatar, Table, Button, Select, Modal, DatePicker, Icon, Tooltip, message } from "antd";
import "./Metas.css";
import {
  getGestorId,
  getUserType,
  getGerenteId,
} from "../../../components/auth/auth-provider";
import BluveLayout from "../../../components/layout/BluveLayout";
import { GESTOR } from "../../../components/funcs/constants";
import empresaDB from "../../../dataManager/dtmEmpresa";
import gerenteDB from "../../../dataManager/dtmGerente";
import vendedorDB from "../../../dataManager/dtmVendedor"
import moment from "moment";
import "moment/locale/pt-br";
import EscalaCRUD from "components/cruds/metas/escalas/EscalaCRUD";
import Text from "antd/lib/typography/Text";

import Meta from "../components/Meta";
import { toLocale } from "../components/helperFunctions";
import metaDB from "dataManager/dtmMeta";

const { Option } = Select;
const dateFormat = "MMMM \\d\\e\\ YYYY"; // ha ha, consegui!

const { confirm } = Modal;
async function confirmar(message) {
  return new Promise((resolve, reject) => {
    confirm({
      title: "Confirmação",
      content: message,
      okText: "Sim",
      cancelText: "Não",
      async onOk() {
        resolve(true);
      },
      onCancel() {
        reject(false);
      },
    });
  }).catch((error) => {
    console.log("Oops errors!");
    return false;
  });
}

// function isDateGreaterThanOrEqualToday(date) {
//   date._d.setHours(0, 0, 0, 0);
//   const today = new Date();
//   today.setHours(0, 0, 0, 0);
//   return date._d >= today;
// }

class Escala extends React.Component {
  state = {
    escalaId: "",
    empresaId: "",
    escalas: [],
    canUpdate: false,
    loading: true,
    date: moment(),
    columns: [],
    metas: [],
    showModal: false,
    empresaSelecionada: {},
    dateIsOpen: false,
    mesPadrao: 0
  };

  options = [];

  constructor(props) {
    super(props);
    this.headerElement = React.createRef();
    this.updateList = this.updateList.bind(this);
    this.loadEmpresas = this.loadEmpresas.bind(this);
    this.loadData = this.loadData.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.excluirMeta = this.excluirMeta.bind(this);
  }

  async componentDidMount() {
    await this.loadEmpresas();
    this.buildColumns();
    this.setState({ loading: false });
    this.loadData();
  }

  async loadEmpresas() {
    const gestorId = getGestorId();
    let empresas = [];

    if (getUserType() === GESTOR) {
      empresas = await empresaDB.getByGestorIdCustom(gestorId, "login.login");
    } else {
      const gerenteId = getGerenteId();
      empresas = await gerenteDB.getEmpresas(gerenteId, "razaoSocial");
    }

    for (const item of empresas) {
      this.options.push(
        <Option key={item.key} value={item.key}>
          {
            item.login ?
              <>
                <span> {item.login.login}</span>{" "}
                <Text style={{ fontSize: "x-small" }} type="secondary">({item.razaoSocial})</Text>
              </> :
              item.razaoSocial
          }
        </Option>
      );
    }

    let empresaId = "";
    if (!empresas.length) {
      empresas.push({
        id: "Nenhuma empresa cadastrada",
        razaoSocial: "Nenhuma empresa cadastrada",
      });
    } else {
      empresaId = empresas[0].key;
    }

    this.setState({ empresaId, empresas });
  }

  onSelectChange(value) {
    this.setState({ empresaId: value });
  }

  async loadData() {
    this.setState({ loading: true });

    const gestorId = getGestorId();

    let mes = this.state.date.month() + 1;
    const ano = this.state.date.year();

    let metas = await metaDB.getByAno(ano, this.state.empresaId);

    if (!metas) {
      metas = [];
    } else {
      metas.sort((a, b) => b.mes - a.mes);

      const ultimaMeta = await metaDB.getUltimaMeta(this.state.empresaId);
      mes = (ultimaMeta && ultimaMeta.mes < 12) ? ultimaMeta.mes + 1 : mes;
    }

    this.setState({
      metas: metas,
      mesPadrao: mes,
      loading: false
    });
  }

  async onFilterClick() {
    await this.loadData();
  }

  onDateChange(value) {
    this.setState({ date: value });
  }

  updateList(list) {
    this.setState({ metas: list });
  }

  editarMetaElement(record) {
    record["empresas"] = this.state.empresas;
    record["date"] = this.state.date;
    this.headerElement.current.editar(record);
  }

  buildColumns(rede) {
    // colunas default
    let columns = [
      {
        title: "Foto",
        dataIndex: "avatar",
        key: "avatar",
        width: 90,
        render: (avatar) => (
          <span>
            <Avatar size={52} src={avatar} />
          </span>
        ),
      },
      {
        title: "Vendedor",
        dataIndex: "apelido",
        key: "apelido",
      },
      {
        title: "Dias trab Semana 1",
        dataIndex: "diasTrabalhadosSemana1",
        key: "diasTrabalhadosSemana1",
      },
      {
        title: "Dias trab Semana 2",
        dataIndex: "diasTrabalhadosSemana2",
        key: "diasTrabalhadosSemana2",
      },
      {
        title: "Dias trab Semana 3",
        dataIndex: "diasTrabalhadosSemana3",
        key: "diasTrabalhadosSemana3",
      },
      {
        title: "Dias trab Semana 4",
        dataIndex: "diasTrabalhadosSemana4",
        key: "diasTrabalhadosSemana4",
      },
    ];

    if (this.state.empresaId === "rede") {
      const column = {
        title: "Empresa",
        dataIndex: "empresa.razaoSocial",
        key: "empresa.razaoSocial",
      };

      columns = [column].concat(columns);
    }

    this.setState({ columns });
  }

  async openModal(empresaId, row) {
    let empresa = this.state.empresas.find((item) => {
      return item.key === empresaId;
    });

    if (row) {
      let listaNovosVendedores = [];
      const vendedores = await vendedorDB.getByEmpresaId(empresa.key);

      let diasExpediente = []
      for (let j of row.periodos) {
        diasExpediente.push(j.dias);
      }

      vendedores.map(vendedor => {
        let existe = false;
        for (let i of row.vendedores) {
          if (vendedor.key === i.id) {
            if (vendedor.ativo) {
              listaNovosVendedores.push(i)
            }
            existe = true;
          }
        }
        if (!existe && vendedor.ativo) {
          const item = {
            diasExpediente: diasExpediente,
            id: vendedor.key,
            meta: {
              periodos: [0, 0, 0, 0],
              total: 0
            }
          }
          listaNovosVendedores.push(item)
        }
      })
      row.vendedores = listaNovosVendedores;

      empresa = { ...empresa, values: row };
    }
    this.setState({ empresaSelecionada: empresa, showModal: true });
  }

  async excluirMeta(empresaId, row) {
    const isOk = await confirmar(`Deseja realmente excluir a meta do mês ${row.mes} e ano ${row.ano}? Após confirmar, não será possível reverter esta operação.`);
    if (!isOk) {
      return;
    }
    this.setState({ loading: true });
    await metaDB.excluirMeta(row.key);
    await this.loadData();
    message.warning('Meta excluída!');
  }



  render() {
    const { showModal, empresas, empresaSelecionada, empresaId, dateIsOpen, mesPadrao } = this.state;

    const handleOk = e => {
      this.setState({ showModal: false });
      this.loadData();
    };

    const handleCancel = e => {
      this.setState({ showModal: false });
    };

    const renderDate = (date) => {
      const jsDate = date.toDate();
      const viewDate = moment(jsDate).format("DD/MM/YYYY");

      return (<span>{viewDate}</span>);
    };

    const columns = [
      {
        title: 'Mês',
        dataIndex: 'mes',
        key: 'mes',
      },
      {
        title: 'Ano',
        dataIndex: 'ano',
        key: 'ano',
      },
      {
        title: "Início",
        dataIndex: "inicio",
        render: renderDate,
      },
      {
        title: "Fim",
        dataIndex: "fim",
        render: renderDate,
      },
      {
        title: "Meta Principal",
        dataIndex: "objetivo.principal",
        // valueType: "money",
        render: (value) => {
          return toLocale(value);
        }
      },
      {
        // title: "",
        dataIndex: 'operation',
        render: (text, row, index, action) => (
          <div>
            <Tooltip placement="topLeft" title="Editar Meta">
              <a onClick={() => this.openModal(empresaId, row)}>
                <Icon type="edit" />
              </a>
            </Tooltip>
            &nbsp;
            &nbsp;
            <Tooltip placement="topLeft" title="Excluir Meta">
              <a onClick={() => this.excluirMeta(empresaId, row)}>
                <Icon type="delete" />
              </a>
            </Tooltip>

          </div>
        )
      },
    ];

    const handlePanelChange = (value) => {
      this.setState({
        date: value,
        dateIsOpen: false
      });
    }

    const handleOpenChange = (status) => {
      if (status) {
        this.setState({ dateIsOpen: true });
      } else {
        this.setState({ dateIsOpen: false });
      }
    }

    const clearValue = () => {
      this.setState({
        date: null
      });
    }

    return (
      <div>
        <BluveLayout selectItem={"metas/cadastro"}>
          <label>Empresa:</label>
          <Select
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Selecione uma empresa"
            onChange={this.onSelectChange}
            value={this.state.empresaId}
            style={{ marginTop: '50px', marginBottom: '15px' }}
          >
            {this.options}
          </Select>

          <DatePicker
            className="monthPickerMetas"
            open={dateIsOpen}
            //format={dateFormat}
            onChange={this.onDateChange}
            allowClear={false}
            value={this.state.date}
            disabled={this.state.loading}
            mode="year"
            format="YYYY"
            onOpenChange={handleOpenChange}
            onPanelChange={handlePanelChange}
          //onChange={clearValue}
          />

          <Button
            type="primary"
            disabled={this.state.loading}
            onClick={this.onFilterClick}
          >
            Filtrar
          </Button>


          <Table
            title={() => (
              <div>
                <Button type="primary" onClick={() => this.openModal(this.state.empresaId)}>
                  <Icon className="icon" type="plus" />Nova Meta
                </Button>
              </div>
            )}
            dataSource={this.state.metas}
            columns={columns}
            loading={this.state.loading}
            bordered />

          <Meta
            title={`Metas de ${this.props.dateStr}`}
            visible={showModal}
            empresas={empresas}
            empresaSelecionada={empresaSelecionada}
            mesPadrao={mesPadrao}
            onOk={handleOk}
            onCancel={handleCancel} />
        </BluveLayout>
      </div>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.crudEscala = React.createRef();
    this.novaEscala = this.novaEscala.bind(this);
    this.novaEscalaRefatorado = this.novaEscalaRefatorado.bind(this);
    this.editar = this.editar.bind(this);

    this.state = {
      visible: false,
      showModal: false,
      empresas: [],
      empresaSelecionada: ""
    };
  }

  novaEscala() {
    const record = [];

    const empresas = this.props.empresas.filter((item) => {
      return item.key !== "rede";
    });

    const empresa = this.props.empresas.filter((item) => {
      return item.key === this.props.empresaId;
    })[0];

    record["empresa"] = empresa;
    record["empresas"] = empresas;
    record["periodo"] = this.props.date;
    this.crudEscala.current.show(false, record);
  }

  novaEscalaRefatorado() {
    const empresas = this.props.empresas.filter((item) => {
      return item.key !== "rede";
    });

    const empresa = this.props.empresas.filter((item) => {
      return item.key === this.props.empresaId;
    })[0];

    this.setState({ empresas: empresas, empresaSelecionada: empresa, showModal: true });
  }

  editar() {
    const record = [];
    const empresas = this.props.empresas.filter((item) => {
      return item.key === this.props.empresaId;
    });

    record["escalaId"] = this.props.escalaId;
    record["empresas"] = empresas;
    record["periodo"] = this.props.date;
    record["vendedores"] = this.props.metas;

    this.crudEscala.current.show(true, record);
  }

  render() {
    const { showModal, empresas, empresaSelecionada } = this.state;

    const handleOk = e => {
      this.setState({ showModal: false });
    };

    const handleCancel = e => {
      this.setState({ showModal: false });
    };

    return (
      <div>
        {/* <MetaMensal
          title={`Metas de ${this.props.dateStr}`}
          visible={showModal}
          empresas={empresas}
          empresaSelecionada={empresaSelecionada}
          onOk={handleOk}
          onCancel={handleCancel} /> */}
        <Meta
          title={`Metas de ${this.props.dateStr}`}
          visible={showModal}
          empresas={empresas}
          empresaSelecionada={empresaSelecionada}
          onOk={handleOk}
          onCancel={handleCancel} />
        <NovaEscala
          ref={this.crudEscala}
          updateList={this.props.updateList}
          record={[]}
          dateStr={this.props.dateStr}
        ></NovaEscala>
        <Button className="btnSuccess" onClick={this.novaEscala}>
          <Icon className="icon" type="plus" /> Nova Escala
        </Button>
        <Button className="btnSuccess" onClick={this.novaEscalaRefatorado}>
          <Icon className="icon" type="plus" /> Nova Escala Plus
        </Button>
        <Button
          disabled={this.props.metas.length === 0}
          className="btnEdit"
          onClick={this.editar}
        >
          Editar
        </Button>
      </div>
    );
  }
}

class NovaEscala extends React.Component {
  state = {
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
    metas: [],
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }
    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record,
    });
  }

  handleOk(record) {
    this.setState({ visible: false });
    this.props.updateList(record);
  }

  async handleCancel() {
    const isOk = await confirmar("Deseja realmente fechar a tela de metas?");
    if (!isOk) {
      return;
    }
    this.setState({ visible: false });
  }

  render() {
    return (
      <Modal
        title={`Metas de ${this.props.dateStr}`}
        visible={this.state.visible}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        footer={null}
        closable={true}
        destroyOnClose={true}
        width={800}
        onCancel={this.handleCancel}
      >
        <EscalaCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          editMode={this.state.editMode}
          record={this.state.record}
        ></EscalaCRUD>
      </Modal>
    );
  }
}

export default withRouter(Escala);
