import React from "react";
import { withRouter } from "react-router";
import { Table, Icon, Button, Modal, Input, Tooltip } from "antd";
import { getUserType } from "../../../components/auth/auth-provider";
import "./Loja.css";
import "antd/dist/antd.css";
import LojaCRUD from "../../../components/cruds/parceiros/lojas/LojaCRUD";
import BluveLayout from "../../../components/layout/BluveLayout";
import { GESTOR } from "../../../components/funcs/constants";
import { COLUMNS_WITH_MANAGER, COLUMNS_WITHOUT_MANAGER } from "./LojaConsts";
import lojaDB from "../../../dataManager/dtmLoja";
import listObj from "../../../components/listSearch/listSearch";
import mensagem from "components/message/Message";
const { Search } = Input;

class Loja extends React.Component {
  state = {
    lojas: [],
    lojasOriginal: [],
    loading: true,
    userType: "Gerente",
    columns: [],
  };

  constructor(props) {
    super(props);
    this.headerLojaElement = React.createRef();
    this.state.userType = getUserType();

    this.applyFilter = this.applyFilter.bind(this);
    this.updateList = this.updateList.bind(this);

    this.loadColumns = this.loadColumns.bind(this);
    this.getLojas();
  }

  async componentDidMount() {
    await this.loadColumns();
  }

  async loadColumns() {
    let columns = [];
    if (this.state.userType === GESTOR) {
      columns = COLUMNS_WITH_MANAGER;
    } else {
      columns = COLUMNS_WITHOUT_MANAGER;
    }

    columns.push({
      title: "",
      width: "10px",
      key: "action",
      render: (text, record) => (
        <span>
          <Tooltip placement="topLeft" title="Editar Cadastro">
            <a onClick={() => this.editarLojaElement(record)}>
              <Icon type="edit" />
            </a>
          </Tooltip>
        </span>
      ),
    });
    this.setState({ columns });
  }

  async applyFilter(text) {
    if (text === "") {
      this.setState({ lojas: this.state.lojasOriginal });
      return;
    }

    const keys = [
      "cnpj",
      "razaoSocial",
      "nomeFantasia",
      "gerente.nome",
      "comissao.comissaoFixoStr",
      "comissao.percentual",
    ];

    const lojas = listObj.search(this.state.lojasOriginal, text, keys);
    this.setState({ lojas });
  }

  updateList(record) {
    const lojas = this.state.lojas;
    let registroEncontrado = false;
    lojas.forEach((item, index) => {
      if (item.key === record.key) {
        lojas[index] = record;
        lojas[index].comissao.comissaoFixoStr = lojaDB.formatarMoeda(
          record.comissao.fixo
        );
        registroEncontrado = true;
      }
    });

    if (!registroEncontrado) {
      record.comissao.comissaoFixoStr = lojaDB.formatarMoeda(
        record.comissao.fixo
      );
      lojas.push(record);
    }
    this.setState({ lojas, lojasOriginal: lojas });
  }

  async getLojas() {
    const lojas = await lojaDB.load();
    this.setState({ lojas, lojasOriginal: lojas, loading: false });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  };

  editarLojaElement(record) {
    this.headerLojaElement.current.editarLoja(record);
  }

  render() {
    return (
      <div className="divTable">
        <BluveLayout selectItem={"parceiros/lojas"}>
          <Table
            pagination={{
              defaultPageSize: 100,
              position: "both",
              pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
              showSizeChanger: true,
              locale: { items_per_page: "" },
            }}
            title={() => (
              <HeaderLoja
                ref={this.headerLojaElement}
                applyFilter={this.applyFilter}
                updateList={this.updateList}
                userType={this.state.userType}
              />
            )}
            columns={this.state.columns}
            dataSource={this.state.lojas}
            loading={this.state.loading}
            bordered
          />
        </BluveLayout>
      </div>
    );
  }
}

class HeaderLoja extends React.Component {
  state = {
    visible: false,
  };

  constructor(props) {
    super(props);
    this.novaLojaElement = React.createRef();
    this.novaLoja = this.novaLoja.bind(this);
    this.filterTable = this.filterTable.bind(this);
  }

  novaLoja() {
    this.novaLojaElement.current.show(false);
  }

  editarLoja(record) {
    this.novaLojaElement.current.show(true, record);
  }

  filterTable(value) {
    const text = typeof value === "object" ? value.target.value : value;
    this.props.applyFilter(text);
  }

  render() {
    return (
      <div>
        <NovaLoja
          ref={this.novaLojaElement}
          updateList={this.props.updateList}
        ></NovaLoja>
        <Button type="primary" onClick={this.novaLoja}>
          <Icon className="icon" type="plus" />
          Nova Loja
        </Button>

        <Search
          placeholder="Procurar"
          onSearch={this.filterTable}
          onChange={this.filterTable}
          style={{ width: 200, marginLeft: "10px" }}
        />
      </div>
    );
  }
}

class NovaLoja extends React.Component {
  state = {
    closable: true,
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onCloseHandle = this.onCloseHandle.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record,
    });
  }

  async handleOk(record) {
    this.props.updateList(record);
    this.setState({ visible: false, closable: true });
  }

  onCloseHandle(canClose) {
    this.setState({ closable: canClose });
  }

  async handleCancel() {
    if (!this.state.closable) return;
    if (!(await mensagem.confirmar("Cancelar alterações?"))) return;
    this.setState({ visible: false, closable: true });
  }

  render() {
    return (
      <Modal
        title="Loja"
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        closable={this.state.closable}
      >
        <LojaCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          editMode={this.state.editMode}
          record={this.state.record}
          onCloseHandle={this.onCloseHandle}
        ></LojaCRUD>
      </Modal>
    );
  }
}

export default withRouter(Loja);
