import { Component } from "react";
import { getGestorId } from "../auth/auth-provider";

import {
    fireBase,
    API_URL
} from "../../firebase/firebase";


class ApiFirebase extends Component {

    async getToken() {
        const token = await fireBase
            .auth()
            .currentUser.getIdToken(true)
            .then(function (idToken) {
                return "bearer " + idToken;
            })
            .catch(function (error) {
                console.log(error.message);
                return;
            });

        return token;
    }

    async retornarUltimosLogin(token) {
        return new Promise(async (resolve, reject) => {
            const url = `${API_URL}/admin/users/lastlogin`;
            const gestorId = getGestorId();

            const item = {
                headers: {
                    "content-type": "application/json",
                    Authorization: token,
                    authUserToken: true,
                    gestorId
                },
                method: "GET",
            };

            fetch(url, item)
                .then((response) => response.json())
                .then((responseData) => {
                    resolve(responseData.historico);
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
}

const apiFirebase = new ApiFirebase();
export default apiFirebase;