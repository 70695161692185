import React from 'react';
import './Configuracao.css';
import BluveLayout from '../../components/layout/BluveLayout';
import {
  Form,
  Tooltip,
  Icon,
  Button,
  Divider,
  Select,
  Modal,
  notification,
  message,
  Tabs,
  List,
  Switch,
  InputNumber,
  Input
} from 'antd';
import history from '../../history';
import { getGestorId, getUserType } from '../../components/auth/auth-provider';
import { fireBase } from '../../firebase/firebase';
import configuracaoDB from '../../dataManager/dtmConfiguracao';
import { currentUser } from 'components/auth/auth-provider';

const { TabPane } = Tabs;
const { warning } = Modal;
const { Option } = Select;
const key = 'updatemessage';


const openNotificationWithIcon = (type, title, description, duration) => {
  if (!duration) {
    duration = 4; // this is the default time
  }
  notification[type]({
    message: [title],
    description: [description],
    duration: duration,
  });
};


class Configuracao extends React.Component {

  state = {
    loading: true,
    bloquearVendedorForaAtendimentoStr: 'Sim',
    configuracao: {
      bloquearVendedorForaAtendimento: true,
    },
    options: [],
    config: [
      {
        titulo: "Exigir registro de produtos",
        descricao: "Exige que o vendedor informe os produtos que vendeu. ATENÇÃO: O não registro de produtos impactará alguns relatórios do Bluve.",
        valor: true
      },
      {
        titulo: 'Ação do botão Vendi: "Venda temporária"',
        descricao: 'Se a Loja possuir integração com PDV, o botão "Vendi" lançará a conversão do vendedor e o removerá da Fila. Quando o sistema integrado enviar a venda, os demais dados (produtos, valores) serão lançados para o vendedor.',
        valor: false
      },
      {
        titulo: 'Ação ao arrastar o vendedor para a Fila à partir da barra horizontal inferior',
        descricao: 'Exibir a tela de desempenho de vendas e metas. Essa opção não é válida para inclusão de vendedores em lote.',
        valor: false
      },
      {
        titulo: 'Exigir senha para a Tela de Desempenho de Vendas e metas',
        descricao: 'Solicitará que o vendedor digite a senha (seu CPF) para acessar a tela de desempenho de vendas e metas.',
        valor: false
      },
      {
        titulo: "Exibir Indicação de Cliente",
        descricao: "Se você trabalha com o programa de indicação de clientes, deixe esta opção marcada para que, na venda, seja possível informar os dados do cliente.",
        valor: false
      },
    ],
    enviarRelatorio: true,
    emailRelatorio: undefined,
    enviarAlerta: false,
    emailAlerta: undefined,
    taxaDeConversao: {
      min: 30,
      max: 60
    },
    diasNotificacao: [false, false, false, false, false, false, false],
    disableAlerta: false
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
    this.onNumberChange = this.onNumberChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onAlertaChange = this.onAlertaChange.bind(this);
    this.onRelatorioChange = this.onRelatorioChange.bind(this);
    this.onDayClick = this.onDayClick.bind(this);
  }

  async componentDidMount() {

    if (getUserType() !== 'Gestor') {

      warning({
        title: 'Atenção',
        content: 'Você não tem permissão para acessar esta área',
        async onOk() {
          history.push('/dashboard');
          history.go();
        },
        async onCancel() {
          history.push('/dashboard');
          history.go();
        },
      });
    }
    else {
      const options = [];
      options.push(<Option key={'Sim'}>{'Sim'}</Option>);
      options.push(<Option key={'Não'}>{'Não'}</Option>);

      const gestorId = getGestorId()
      let configuracao = await configuracaoDB.getByGestorId(gestorId);
      let bloquearVendedorForaAtendimentoStr = 'Sim';
      if (!configuracao) {
        configuracao = {
          bloquearVendedorForaAtendimento: true,
        }
      }
      else {
        configuracao = configuracao[0];
        if (!configuracao.bloquearVendedorForaAtendimento) {
          bloquearVendedorForaAtendimentoStr = 'Não';
        }

        const config = this.state.config;
        const taxaDeConversao = this.state.taxaDeConversao;
        const diasNotificacao = this.state.diasNotificacao;
        const enviarRelatorio = this.state.enviarRelatorio;
        const enviarAlerta = this.state.enviarAlerta;

        this.setState({
          config: [
            {
              ...config[0],
              valor: configuracao.fila ?
                configuracao.fila.atendimento.registroProdutos :
                true
            },
            {
              ...config[1],
              valor: configuracao.fila ?
                configuracao.fila.atendimento.vendaTemporaria :
                false
            },
            {
              ...config[2],
              valor: configuracao.fila ?
                configuracao.fila.atendimento.exibirDesempenhoVendas :
                false
            },
            {
              ...config[3],
              valor: configuracao.fila ?
                configuracao.fila.atendimento.exigirSenhaDesempenhoVendas :
                false
            },
            {
              ...config[4],
              valor: configuracao.fila ?
                configuracao.fila.atendimento.exibirIndicador :
                false
            }
          ],
          taxaDeConversao: configuracao.taxaDeConversao ?? taxaDeConversao,
          diasNotificacao: configuracao.diasNotificacao ?? diasNotificacao,
          emailRelatorio: configuracao.emailRelatorio ?? currentUser().email,
          emailAlerta: configuracao.emailAlerta ?? currentUser().email,
          enviarRelatorio: configuracao.enviarRelatorio ?? enviarRelatorio,
          enviarAlerta: configuracao.enviarAlerta ?? enviarAlerta
        });
      };

      this.setState({ options, configuracao, bloquearVendedorForaAtendimentoStr, loading: false });
    }
  }

  cancelClick() {
    history.push('/home');
    history.go();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }
      this.setState({ loading: true });

      const {
        taxaDeConversao,
        diasNotificacao,
        enviarRelatorio,
        enviarAlerta,
        emailRelatorio,
        emailAlerta,
        configuracao
      } = this.state;

      if (taxaDeConversao.min > taxaDeConversao.max) {
        openNotificationWithIcon('warning', 'A Taxa de Conversão Mínima não pode ser maior que a máxima');
        this.setState({ loading: false });
        return;
      } else if (taxaDeConversao.min === taxaDeConversao.max) {
        openNotificationWithIcon('warning', 'A Taxa de Conversão Mínima não pode ser igual a máxima');
        this.setState({ loading: false });
        return;
      }

      const config = {
        gestorId: getGestorId(),
        bloquearVendedorForaAtendimento: configuracao.bloquearVendedorForaAtendimento,
        enviarRelatorio,
        enviarAlerta,
        taxaDeConversao,
        diasNotificacao
      }


      if (emailRelatorio) {
        config.emailRelatorio = emailRelatorio;
      }

      if (emailAlerta) {
        config.emailAlerta = emailAlerta;
      }

      if (!configuracao.key) {
        configuracaoDB.add(config);
      }
      else {
        configuracaoDB.update(configuracao.key, config);
      }

      message.success({ content: `Configuração salva com sucesso.`, key });
      setTimeout(() => {
        history.push('/home');
        history.go();
      }, 1500);
    });
  }

  handleChange(value) {
    const configuracao = this.state.configuracao;
    configuracao.bloquearVendedorForaAtendimento = value === 'Sim';
    const bloquearVendedorForaAtendimentoStr = value;

    this.setState({ configuracao, bloquearVendedorForaAtendimentoStr });
  }

  onNumberChange(value, input) {
    let taxaDeConversao = this.state.taxaDeConversao;

    switch (input) {
      case 'max':
        taxaDeConversao.max = value;
        break;

      case 'min':
        taxaDeConversao.min = value;
        break;
    }

    this.setState({ taxaDeConversao });
  }

  onEmailChange(obj, tipo) {
    const { value } = obj.target;
    if (tipo === 'emailRelatorio') {
      this.setState({ emailRelatorio: value });
    } else {
      this.setState({ emailAlerta: value });
    }
  }

  onAlertaChange(value) {
    let enviarAlerta;
    let diasNotificacao;

    if (value === 'Sim') {
      diasNotificacao = [true, true, true, true, true, true, true];
      enviarAlerta = true;
    } else {
      diasNotificacao = [false, false, false, false, false, false, false];
      enviarAlerta = false;
    }

    this.setState({
      enviarAlerta,
      diasNotificacao,
      taxaDeConversao: {
        min: 30,
        max: 60
      }
    })
  }

  onRelatorioChange(value) {
    const enviarRelatorio = value === 'Sim' ? true : false;
    this.setState({ enviarRelatorio });
  }

  onDayClick(day) {
    let diasNotificacao = this.state.diasNotificacao;
    diasNotificacao[day] = !diasNotificacao[day];
    this.setState({ diasNotificacao });
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const { getFieldDecorator } = this.props.form;

    const handleSwitchChange = async (value, index) => {
      this.setState({ loading: true });
      const { configuracao, config: configState } = this.state;

      if (this.state.config[index]) {
        configState[index].valor = value;
        this.setState({ config: configState });

        let config = {
          gestorId: getGestorId(),
        }

        if (!configuracao.key) {
          config.fila = {
            atendimento: {
              registroProdutos: index === 0 ? value : configState[0].valor,
              vendaTemporaria: index === 1 ? value : configState[1].valor
            }

          };

          const doc = await configuracaoDB.add(config);
          this.setState({ configuracao: { ...configuracao, key: doc.id } });
        } else {
          config.fila = {
            atendimento: {
              registroProdutos: index === 0 ? value : configState[0].valor !== undefined ? configState[0].valor : true ,
              vendaTemporaria: index === 1 ? value : configState[1].valor !== undefined ? configState[1].valor : false ,
              exibirDesempenhoVendas: index === 2 ? value : configState[2].valor !== undefined ? configState[2].valor : false ,
              exigirSenhaDesempenhoVendas: index === 3 ? value : configState[3].valor !== undefined ? configState[3].valor : false ,
              exibirIndicador : index === 4 ? value : configState[4].valor !== undefined ? configState[4].valor : false ,
            },
          };

          await configuracaoDB.update(configuracao.key, config);
        }

        message.success({ content: `Configuração salva com sucesso.`, key });
      }

      this.setState({ loading: false });
    };

    return (
      <div>
        <BluveLayout selectItem={'configuracao'}>
          <Tabs defaultActiveKey="1">

            {/* <TabPane tab="Geral" key="0">

            </TabPane> */}

            <TabPane tab="Integração PDV" key="1">
              <Form {...formItemLayout} onSubmit={this.handleSubmit}>

                <Form.Item
                  label={
                    <span>
                      Bloquear venda de vendedor fora de atendimento&nbsp;
                      <Tooltip title="Venda do vendedor deve ser bloqueada na integração do PDV caso o mesmo não esteja atendendo no Bluve?">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator("integracao", {
                    initialValue: this.state.bloquearVendedorForaAtendimentoStr,
                    rules: [
                      { required: true, message: "Informe se o vendedor deve ter sua venda bloqueada na integração caso o mesmo não esteja na fila de atendimento" },
                    ],
                  })(
                    <Select
                      style={{ width: 120 }}
                      onChange={this.handleChange}
                      disabled={this.state.loading}
                    >
                      {this.state.options}
                    </Select>
                  )}
                </Form.Item>

                <Divider></Divider>

                <Form.Item {...tailFormItemLayout}>
                  <Button type="primary" htmlType="submit" loading={this.state.loading}>
                    Salvar
                  </Button>
                  <Divider type="vertical" />
                  <Button onClick={this.cancelClick}>
                    Cancelar
                  </Button>
                </Form.Item>
              </Form>
            </TabPane>

            <TabPane tab='Relatório Semanal' key='2'>
              <div className='containerOptions'>
                <div className='option'>
                  <span className='optionTitle'>
                    Deseja receber o relatório semanal?&nbsp;
                    <Tooltip title='O relatório semanal possui informações sobre suas empresas na última semana.'>
                      <Icon type='question-circle-o' />
                    </Tooltip> :
                  </span>

                  <Select
                    style={{ width: 120 }}
                    value={this.state.enviarRelatorio ? 'Sim' : 'Não'}
                    onChange={this.onRelatorioChange}
                    disabled={this.state.loading}
                  >
                    {this.state.options}
                  </Select>
                </div>

                <div className='option'>
                  <span className='optionTitle'>
                    Email de recebimento do alerta&nbsp;
                    <Tooltip title='Por padrão o email que irá receber o relatório é o que está cadastrado para essa conta.'>
                      <Icon type='question-circle-o' />
                    </Tooltip> :
                  </span>

                  <div className='inputEmail'>
                    <Input
                      value={this.state.emailRelatorio}
                      onChange={obj => this.onEmailChange(obj, 'emailRelatorio')}
                      disabled={this.state.loading}
                    />
                  </div>
                </div>
              </div>

              <Divider></Divider>

              <div className='containerButtons'>
                <div>
                  <Button type='primary' onClick={this.handleSubmit} loading={this.state.loading}>
                    Salvar
                  </Button>

                  <Divider type='vertical' />

                  <Button onClick={this.cancelClick}>
                    Cancelar
                  </Button>
                </div>
              </div>
            </TabPane>

            <TabPane tab='Alerta' key='3'>
              <div className='containerOptions'>
                <div className='option'>
                  <span className='optionTitle'>
                    Deseja receber o alerta de taxa de conversão?&nbsp;
                    <Tooltip title='O alerta é enviado nos dias configurados caso uma de suas empresas, no dia anterior, tenha ficado abaixo ou acima da faixa de taxa de conversão configurada.'>
                      <Icon type='question-circle-o' />
                    </Tooltip> :
                  </span>

                  <Select
                    style={{ width: 120 }}
                    value={this.state.enviarAlerta ? 'Sim' : 'Não'}
                    onChange={this.onAlertaChange}
                    disabled={this.state.loading}
                  >
                    {this.state.options}
                  </Select>
                </div>

                <div className='option'>
                  <span className='optionTitle'>
                    Faixa de conversão aceitável&nbsp;
                    <Tooltip title='Faixa de valores no qual você não será notificado. Nós enviamos alertas em casos de taxa de conversão muito alta, porque pode ser que a equipe da loja não esteja preenchendo corretamente as não vendas na fila da vez.'>
                      <Icon type='question-circle-o' />
                    </Tooltip> :
                  </span>

                  <div className='textInputNumber'>
                    Você será notificado caso uma de suas empresas fique abaixo de &nbsp;

                    <InputNumber
                      disabled={this.state.loading || !this.state.enviarAlerta}
                      min={1}
                      max={100}
                      value={this.state.taxaDeConversao.min}
                      formatter={value => `${value}%`}
                      onChange={(value) => this.onNumberChange(value, 'min')}
                      style={{ width: '10rem' }}
                    />&nbsp;

                    ou acima de &nbsp;

                    <InputNumber
                      disabled={this.state.loading || !this.state.enviarAlerta}
                      min={1}
                      max={100}
                      value={this.state.taxaDeConversao.max}
                      formatter={value => `${value}%`}
                      onChange={(value) => this.onNumberChange(value, 'max')}
                      style={{ width: '10rem' }}
                    />&nbsp;

                    na taxa de conversão diária.
                  </div>
                </div>

                <div className='option'>
                  <span className='optionTitle'>
                    Dias da semana para receber o alerta&nbsp;
                    <Tooltip title='Dias da semana que você deve ser notificado caso, no dia anterior, a empresa tenha saido da faixa de valores configurada'>
                      <Icon type='question-circle-o' />
                    </Tooltip> :
                  </span>

                  <div className='buttons'>
                    <Button
                      disabled={this.state.loading || !this.state.enviarAlerta}
                      onClick={() => this.onDayClick(0)}
                      type={this.state.diasNotificacao[0] ? 'primary' : 'default'}>
                      Domingo
                    </Button>

                    <Button
                      disabled={this.state.loading || !this.state.enviarAlerta}
                      onClick={() => this.onDayClick(1)}
                      type={this.state.diasNotificacao[1] ? 'primary' : 'default'}>
                      Segunda
                    </Button>

                    <Button
                      disabled={this.state.loading || !this.state.enviarAlerta}
                      onClick={() => this.onDayClick(2)}
                      type={this.state.diasNotificacao[2] ? 'primary' : 'default'}>
                      Terça
                    </Button>

                    <Button
                      disabled={this.state.loading || !this.state.enviarAlerta}
                      onClick={() => this.onDayClick(3)}
                      type={this.state.diasNotificacao[3] ? 'primary' : 'default'}>
                      Quarta
                    </Button>

                    <Button
                      disabled={this.state.loading || !this.state.enviarAlerta}
                      onClick={() => this.onDayClick(4)}
                      type={this.state.diasNotificacao[4] ? 'primary' : 'default'}>
                      Quinta
                    </Button>

                    <Button
                      disabled={this.state.loading || !this.state.enviarAlerta}
                      onClick={() => this.onDayClick(5)}
                      type={this.state.diasNotificacao[5] ? 'primary' : 'default'}>
                      Sexta
                    </Button>

                    <Button
                      disabled={this.state.loading || !this.state.enviarAlerta}
                      onClick={() => this.onDayClick(6)}
                      type={this.state.diasNotificacao[6] ? 'primary' : 'default'}>
                      Sábado
                    </Button>
                  </div>
                </div>

                <div className='option'>
                  <span className='optionTitle'>
                    Email de recebimento do alerta&nbsp;
                    <Tooltip title='Por padrão o email que irá receber o alerta é o que está cadastrado para essa conta.'>
                      <Icon type='question-circle-o' />
                    </Tooltip> :
                  </span>

                  <div className='inputEmail'>
                    <Input
                      value={this.state.emailAlerta}
                      onChange={this.onEmailChange}
                      disabled={this.state.loading || !this.state.enviarAlerta}
                    />
                  </div>
                </div>
              </div>

              <Divider></Divider>

              <div className='containerButtons'>
                <div>
                  <Button type='primary' onClick={this.handleSubmit} loading={this.state.loading}>
                    Salvar
                  </Button>

                  <Divider type='vertical' />

                  <Button onClick={this.cancelClick}>
                    Cancelar
                  </Button>
                </div>
              </div>
            </TabPane>

            <TabPane tab='Atendimento' key='4'>
              <List itemLayout="horizontal"
                dataSource={this.state.config}
                renderItem={(item, index) => (
                  <List.Item actions={[<Switch defaultChecked={item.valor} onChange={(value) => handleSwitchChange(value, index)} />]}>
                    <List.Item.Meta
                      title={item.titulo}
                      description={item.descricao}
                    />
                  </List.Item>
                )}
              />

            </TabPane>
          </Tabs>

        </BluveLayout>
      </div>
    );
  }
}

export default Form.create()(Configuracao);
