import { JWT_KEY_API_BLUVE, EXPIRATION_TIME } from 'components/funcs/constants';
import { logout } from 'components/auth/auth-provider';
import { fireBase } from 'firebase/firebase';

import axios from './axios';
import jwt from 'jsonwebtoken';
import history from '../../history';


export default class API {
  // Funções Antigas
  async post(params, route) {
    const response = await request('post', route, params);
    return response;
  }

  async put(params, route) {
    const response = await request('put', route, params);
    return response;
  }

  async get(params, route) {
    return new Promise(async (resolve, reject) => {
      request('get', route, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        }
        )
    });

  }

  // Funções Novas
  async atualizarVendedor(updateList, key) {
    const route = '/vendedor';

    const params = {
      updateList,
      key
    };

    const response = await request(
      'put',
      route,
      params
    );

    return response;
  }

  async cadastrarVendedor(item) {
    const route = '/vendedor';
    const params = { ...item };

    const response = await request(
      'post',
      route,
      params
    );

    return response;
  }

  async getEmpresas(gestorId, limite, lastDocId) {
    const route = '/empresa';

    const params = {
      gestorId,
      limite,
      lastDocId
    };

    const response = await request(
      'get',
      route,
      params
    );

    return response;
  }

  async getEmpresasGerente(gerenteId) {
    const route = '/gerente/empresas';
    const params = { gerenteId };

    const response = await request(
      'get',
      route,
      params
    );

    return response;
  }

  async getVendedores(gestorId, empresaId, ativo, limite, lastDocId) {
    const route = '/vendedor';

    const params = {
      gestorId,
      empresaId,
      ativo,
      limite,
      lastDocId
    };

    const response = await request(
      'get',
      route,
      params
    );

    return response;
  }

  async getVendedorById(vendedorId) {
    const route = '/vendedor/getById';
    const params = { vendedorId };

    const response = await request(
      'get',
      route,
      params
    );

    return response;
  }

  async getProdutos(gestorId, limite, ativo, lastDocId) {
    return new Promise(async (resolve, reject) => {
      const params = {
        gestorId,
        limite,
        ativo,
        lastDocId
      };

      const route = '/produtos';
      request('get', route, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async getProdutosByLetra(gestorId, letra, limite, ativo, lastDocId) {
    return new Promise(async (resolve, reject) => {
      const params = {
        gestorId,
        letra,
        limite,
        ativo,
        lastDocId
      };

      const route = '/produtos/getByLetra';

      request('get', route, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  async getProdutosByPalavraChave(gestorId, palavras, codigos, limite) {
    const params = {
      gestorId,
      palavras,
      codigos,
      limite
    };

    const route = '/produtos/getByPalavraChave';

    const response = await request('get', route, params);
    return response;
  }

  async validateReCaptcha(token) {
    try {
      const route = `/validateReCaptcha/${token}`;

      const response = await request('post', route, {});
      return response.success;
    } catch (error) {
      console.log(error);
    }
  }

  async accessControl(uid, refreshToken) {
    try {
      const route = `/accessControl/${uid}/${refreshToken}`;
      await request('post', route, {});
    } catch (error) {
      console.log(error);
    }
  }

  async checkRefreshToken(uid, refreshToken) {
    try {
      const route = `/accessControl/check/${uid}/${refreshToken}`;

      const response = await request('post', route, {});
      return response.revoked;
    } catch (error) {
      console.log(error);
    }
  }
}

function request(method, route, params) {
  return new Promise(async (resolve, reject) => {
    let response;

    try {
      response = method === 'get' ?
        await axios[method](route, { params: { ...params } }) :
        await axios[method](route, { ...params });

      const res = method === 'get' && response.data.result ?
        response.data.result : response.data;

      resolve(res);
    } catch (error) {
      response = errorHandler(error);

      if (response) {
        resolve(response);
      } else {
        reject(error);
      }
    }
  })
}

async function errorHandler(error) {
  const { code, sessionRevoked } = error.response.data

  if (code === 400) {
    return error.response.data;
  }

  if (code === 401 && sessionRevoked) {
    logout();

    await fireBase.auth().signOut();

    history.push('/home/?sessionRevoked=true');
    history.go();
  }

  return undefined;
}

async function createToken(obj) {
  const token = jwt.sign(obj, JWT_KEY_API_BLUVE, {
    expiresIn: EXPIRATION_TIME
  });

  return token;
}
